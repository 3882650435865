import React, { useState ,useRef, useEffect } from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";
import { useStep } from 'react-hooks-helper'

import {Form, useFormik}  from 'formik'
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import FormLabel from '@material-ui/core/FormLabel'

import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CssBaseline from "@mui/material/CssBaseline";
import {Input} from 'reactstrap'
import Layout from '../core/Layout';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { isAuthenticated, register ,registerApplication, updateApplicantData, getApplicantData,
         updateApplicantationData, appsubmit, getApplication, getApplicant,fechData, 
         sendconfirmation, uploadFiles ,downloadFile, applicationreport, getLookUp, downloadFileAdmin, downloadSupportingDoc
        } from '../util';

import * as dump  from '../dump'

import * as Yup from 'yup';

import axios from 'axios';


const Signup = () => {
    var applicantDetails = JSON.parse(localStorage.getItem('applicant'))
    const FILE_SIZE = 2097152;
    const SUPPORTED_FORMATS = [
      "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/png"
    ];
    var fileURL
    const forward = "SAVE AND CONTINUE" 
    const backward = "BACK"
    const  validationSchema = Yup.object({
        tcchecked: Yup.string().required('Check Ts&Cs to continue'),
        tc: Yup.string().required('Check Ts&Cs to continue'),
        first_name: Yup.string().required('Enter first name'),
        last_name: Yup.string().required('Enter last name'),
        email: Yup.string().email('Invalid email').required('Enter email'),
        re_email: Yup.string().required('Confirm your email').oneOf([Yup.ref('email'), null], 'Emails must match'),
        password: Yup.string().required('Enter password').matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!&@#$]{8,}$/, 'Use 8 or more characters with a mix of letters and numbers'),
        re_password: Yup.string().required('Confirm password').oneOf([Yup.ref('password'), null], 'Passwords must match'),
        alternative_email:  Yup.string().email('Invalid email').required('Enter alternative email'),
        cell_phone: Yup.string().required("Enter cell phone").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,'invalid phone number'),
        alternative_phone: Yup.string().required("Enter alternative phone number").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,'invalid phone number'),
        dobyear: Yup.number().required('Select year for date of birth'),
        dobmonth: Yup.number().required('Select month for date of birth'),
        dobday: Yup.number().required('Select day for date of birth'),   
        first_language: Yup.string().required('Select first language'),
        second_language: Yup.string().required('Select second language'),
        id_type: Yup.number().required('Select ID type'),
        id_number: Yup.string().required('Enter ID number'), 
        address: Yup.string().required('Enter physical address'),
        country : Yup.string().required('Select nationality'),
        city: Yup.string().required('Enter city'),
        province:Yup.string().required('Select province'),
        suburb: Yup.string().required('Enter area or suburb'),
        area_code: Yup.string().required("Enter area code").matches(/^\d{4}$/,'Invalid area code'),
        employment_status: Yup.string().required('Select employment status'),
        gender: Yup.string().required('Select gender'),
        race:Yup.string().required('Select race'),
        religion: Yup.string().required('Select religion'),
        marital_status : Yup.string().required('Select marital status'),
        //number_of_siblings: Yup.string().required("Enter number of sibling").matches(/^\d{2}$/ ,'Invalid number of sibling '),
        spouse: Yup.string().required('Enter spouse name'),
        spouse_contact: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,'Spouse phone number is invalid'),
        //spouse_employment: Yup.string().required('Select employment status for your spuse'),        
        //guardian: Yup.string(),
        //guardian_employment: Yup.number(),
        reference_relationship: Yup.string().required('Select reference type'),
        reference_name: Yup.string().required('Enter reference name'),
        reference_email:Yup.string().email('Reference email invalid'),
        reference_cell: Yup.string().required('Enter reference phone number').matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,'Reference phone number is invalid'),
           
       
    }) 
    const [applicant, setApplicant] = useState() 
    const [application, setApplication] = useState()  
    const [progressInfos, setProgressInfos] = useState({id_document: '', affidavit: '', recommendation: '', motivation_letter: ''});
    const [message, setMessage] = useState([]);
    const [uploadCompleted,setuploadCompleted] = useState(false)
    const progressInfosRef = useRef(null)
    const [lookUpData, setLookUpData] = useState()
    const [data, setData] = useState()
    const [blobFile,setBlobFile] = useState()
    const [fileDoc,setFileDoc] = useState()
    const [tabValue,setTabValue] = useState(0)
    //const [applicantDetails,setApplicantDetails] = useState()
    //const [fileToUpload,setFileToUpload] = useState({'id_document':'' , 'recommendation':true ,'motivation_letter':'','affidavit':'' })


    const history = useHistory()

  
    var applicantDetails = JSON.parse(localStorage.getItem('applicant'))

    const {handleSubmit, handleChange, handleBlur, setValues, setFieldValue, values, touched, errors } = useFormik({ 
        initialValues: {
            first_name: applicantDetails?.first_name  ,
            last_name: applicantDetails?.last_name ,
            email: JSON.parse(localStorage.getItem('log'))?.email,
            re_email: '',
            password: '',
            re_password:'',
            alternative_email: applicantDetails?.alternative_email ,
            cell_phone: applicantDetails?.cell_phone ,
            alternative_phone: applicantDetails?.alternative_phone ,
            date_of_birth: { year: applicantDetails?.date_of_birth.substring(0,4), month: applicantDetails?.date_of_birth.substring(5,7),  day: applicantDetails?.date_of_birth.substring(8,10)},          
            //date_of_birth : {year:'',month:'', day:''},
            address: applicantDetails?.address ,
            suburb: applicantDetails?.suburb,
            city: applicantDetails?.city || '',
            province: applicantDetails?.province ,
            country :  applicantDetails?.country || '',
            area_code: applicantDetails?.area_code || '',
            id_type: applicantDetails?.id_type || '',
            id_number: applicantDetails?.id_number || '',
            gender: applicantDetails?.gender || '',
            race: applicantDetails?.race || '',
            religion: applicantDetails?.religion || '',
            first_language: applicantDetails?.first_language || '',
            second_language: applicantDetails?.second_language || '',
            marital_status : applicantDetails?.marital_status || '',
            employment_status: applicantDetails?.employment_status || '',
            number_of_siblings: applicantDetails?.number_of_siblings || '',
            first_generation: applicantDetails?.first_generation || '',
            spouse_name: applicantDetails?.spouse_name || '',
            spouse_contact: applicantDetails?.spouse_contact || '',
            spouse_employment: applicantDetails?.spouse_employment || '',
            guardian: applicantDetails?.guardian || '',
            guardian_employment: applicantDetails?.guardian_employment || '',
            reference_name: applicantDetails?.reference_name || '',
            reference_email: applicantDetails?.reference_email || '',
            reference_cell: applicantDetails?.reference_cell_phone || '',
            reference_relationship: applicantDetails?.relationship_with_reference || '',
            email_conformed:'',
            email_conformed_on:'',
            created_on:'',
            tcchecked: '',      
            tc: '', 
            pl:'',           
            loading: false,
            error: '',
            success:''
        },
        validationSchema,
        validateOnChange: true
    });
    
    const { first_name , last_name , email, re_email, password, re_password, alternative_email, 
            cell_phone , alternative_phone, country, id_type, id_number, date_of_birth ,
            address, suburb,city, province , area_code, gender, race, religion, first_language, second_language, marital_status, employment_status, 
            number_of_siblings, first_generation, spouse_name, spouse_contact, spouse_employment, guardian, guardian_employment, reference_name, reference_email, 
            reference_cell, reference_relationship, email_conformed ,email_conformed_on, created_on,tcchecked ,tc,pl, loading, error, success  
    } = values;
    const {year, month, day} = date_of_birth 

            
    const validationSchema2 =  Yup.object().shape({
        application_type: Yup.string().required('Select application type'),
        university: Yup.string().required('Select university'),
        course:  Yup.string().required('Select course'),
        duration_of_study:  Yup.number().required('Enter course duration'),
        year_of_study: Yup.number().required('Enter year of study'),
        completion_year: Yup.number().required('Enter compeletion year'),
        amount_for_tuition: Yup.number().required('Enter amount for tuition fee '),
        amount_for_transport: Yup.number().required('Enter amount for tuition travelling '),        
        amount_for_books : Yup.number().required(' Enter amount for books'),
        amount__for_Accommodation: Yup.number().required('Enter amount for accommodation'),
        the_course_you_wish: Yup.string().required('Tell us about the course you wish to study'),
        academic_achievements:Yup.string().required('Tell us about your best academic achievements'),
        community_involvement:Yup.string().required('Tell us about your community involvement?'),
        competing:Yup.string().required('You are competing for a limited number of bursaries with 100’s of other applicants.Tell us why you should receive a SANZAF Zakah Bursary'),
        father_monthly_salary:  Yup.number().required('Enter amount for father monthly salary'),
        mother_monthly_salary: Yup.number().required('Enter amount for mother monthly salary'),
        spouse_monthly_salary: Yup.number().required('Enter amount for spouse monthly salary'),
        own_monthly_salary: Yup.number().required('Enter amount for own monthly salary'),
        investments: Yup.number().required('Enter amount for investments'),
        other_income: Yup.number().required('Enter amount for other income'),
        
       /* gold_silver_jewellery:0,
        cash_bank_savings: 0,
        investments: 0,
        investments_policies: 0,
        other_income: 0,
        total_income: 0, */
       
        rent_bond_expense: Yup.number().required('Enter amount for rent '),
        rates: Yup.number().required('Enter amount for rates '),
        water_electricity: Yup.number().required('Enter amount for water and electricity '),
        transport_petrol: Yup.number().required('Enter amount for transport'),
        telephone: Yup.number().required('Enter amount for telephone '),
        medical_aid: Yup.number().required('Enter amount for medical aid'),
        insurance: Yup.number().required('Enter amount for insurance'),        
        grocery_milk_bread: Yup.number().required('Enter amount for groceries '),
        other_expense: Yup.number().required('Enter amount for other expenses'),
        how_did_you_learn: Yup.string().required('Select how did you learn about SANZAF bursary'),
        //how_did_you_learn_specify,
        assisted_by_sanzaf :Yup.string().required('Select have any of your parents assisted by SANZAF'),
        //assisted_by_sanzaf_specify,
        was_the_application_form_easy : Yup.string().required('Select was the application form easy'),
        //was_the_application_easy_specify,
        opening_date : Yup.string().required('Select was the opping day suitable'),
        //opening_date_explain,
        closing_date :Yup.string().required('Select was the closing day suitable'),
        //closing_date_explain,
        instructions_clear :Yup.string().required('Select was the instructions clear'),

        assisted_by_vocational_programme: Yup.string().required('Select assisted by vocational programme'),
        mentor_participation: Yup.string().required('Select mentor participation'),
        volunteer_participation: Yup.string().required('Select volunteer participation'),
        seminars_participation: Yup.string().required('Select seminars  participation'),
        tutor_participation: Yup.string().required('Select tutor participation'),


        //instructions_clear_explain
        //liabilities: Yup.number().required('Enter amount for own monthly salary'),
        /* other_expense: 0,
        total_expense: 0,
        financial_declaration: '',
        */

        id_document: Yup.mixed().test("fileSize",`ID document file must be at least 2 MB. \nTry to compress the file and upload it again`, 
                                    value => value && value.size <= FILE_SIZE )
                                .test(
                                "fileFormat",
                                "ID Document file format unsupported. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),

        /*acceptance_letter: Yup.mixed().test("fileSize",`  Acceptance letter must be at least 2 MB. \n Try to compress the file and upload it again`, 
                                        value => value && value.size <= FILE_SIZE )
                                    .test(
                                    "fileFormat",
                                    "Acceptence Letter file format unsupported. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),*/
        affidavit:Yup.mixed().test("fileSize",`  Proof of income document file must be at least 2 MB. \n Try to compress the file and upload it again`,     
                                        value => value && value.size <= FILE_SIZE )
                                    .test(
                                    "fileFormat",
                                    "Proof of income file must be at least 2 MB. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),
        recommendation: Yup.mixed().test("fileSize",`  Testimonial letter file size too large. \n Try to compress the file and upload it again`, 
                                        value => value && value.size <= FILE_SIZE )
                                    .test(
                                    "fileFormat",
                                    " Testimonial letter file format unsupported. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),
        motivation_letter: Yup.mixed().test("fileSize",`  Motivational letter file must be at least 2 MB. \n Try to compress the file and upload it again`,
                                        value => value && value.size <= FILE_SIZE )
                                    .test(
                                    "fileFormat",
                                    "Motivational Letter file format unsupported. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),        
    })

          
 
   const {handleChange: handleChange2 , handleSubmit: handleSubmit2, handleBlur: handleBlur2, setValues: setValues2,  
        values: values2, touched: touched2, errors: errors2, validateForm: validateForm2 } = useFormik({
        initialValues: {
            applicant_id: '',
            application_id: '',
            application_year: '',
            application_date: '',
            application_type: applicantDetails?.application_type ? "Sponsored by SANZAF last year": '',
            student_number: applicantDetails?.student_number || '',
            university: applicantDetails?.university || '',
            uni: applicantDetails?.uni || '',           
            course: applicantDetails?.course || '',
            unilevel: '',
            cor: applicantDetails?.cor || '',
            duration_of_study: applicantDetails?.duration_of_study || '',
            year_of_study: '',
            completion_year: '',
            terms_and_conditions: '',
            amount_for_tuition: '',
            amount_for_books: '',
            amount_for_accommodation: '', 
            amount_for_transport:'',
            father_monthly_salary: '',
            mother_monthly_salary: '',
            spouse_monthly_salary: '',
            own_monthly_salary: '',
            gold_silver_jewellery: '',
            cash_bank_savings: '',
            investments: '',
            investments_policies:'',
            other_income: '',
            total_income: '',
            father_guardian_name: '',
            monthly_rent_income: '',         
            rent_bond_expense: '',
            rates:'',
            water_electricity: '',
            grocery_milk_bread: '',
            transport_petrol: '',
            telephone: '',
            medical_aid: '',
            insurance: '',
            liabilities: '', 
            other_expense: '',
            total_expense: '',
            financial_declaration: '',
            files: {'id_document':{'lable':'ID Document', 'blob':'', 'progress':'', 'path_list': applicantDetails?.id_document_list, 'path': applicantDetails?.id_document,  'url':'', 'uploaded':  false ,'name': 'id_document'} , 
                    'recommendation':{'lable':'Testimonial Letter', 'blob':'','progress':'', 'path_list': applicantDetails?.recommendation_list, 'path': applicantDetails?.recommendation,'url':'', 'uploaded': false, 'name': 'recommendation'},
                    'affidavit':{'lable':'Proof of Income', 'blob':'','progress':'','path_list': '', 'path': '', 'url':'','uploaded': false, 'name': 'affidavit'},
                    'motivation_letter':{'lable':'Motivational Letter', 'blob':'','progress':'','path_list': '', 'path': '','url':'', 'uploaded': false, 'name': 'motivation_letter'},
                    },
            pledge_by_Applicant:'' ,
            id_document: '',
            //acceptance_letter:'',
            affidavit:'',
            recommendation:'',
            motivation_letter:'',
            
            mentor_participation:'',
            volunteer_participation:'',
            seminars_participation:'',
            tutor_participation:'',           
            assisted_by_vocational_programme:'',
            assisted_by_vocational_programme_specify:'',
            assisted_by_sanzaf: applicantDetails?.assisted_by_sanzaf || '',
            assisted_by_sanzaf_specify: applicantDetails?.assisted_by_sanzaf_specify || '',

            the_course_you_wish: applicantDetails?.the_course_you_wish_to_study || '',
            academic_achievements: applicantDetails?.academic_achievements ||  '',
            community_involvement: applicantDetails?.community_involvement || '',
            competing: applicantDetails?.tell_us || '',
            how_did_you_learn: applicantDetails?.how_did_you_learn || '',
            how_did_you_learn_specify:  applicantDetails?.how_did_you_learn_specify || '',
         
            was_the_application_form_easy: applicantDetails?.was_the_application_form_easy || '',
            was_the_application_easy_specify: applicantDetails?.was_the_application_easy_specify || '',
            opening_date: applicantDetails?.opening_date || '',
            opening_date_explain: applicantDetails?.opening_date_explain || '',
            closing_date: applicantDetails?.closing_date || '',
            closing_date_explain: applicantDetails?.closing_date_explain || '',
            instructions_clear:  applicantDetails?.instructions_clear || '',
            instructions_clear_explain: applicantDetails?.instructions_clear_explain || '',
            accept_decline: applicantDetails?.accept_decline || 2,
            submitted: applicantDetails?.submitted,
        }, 
        validationSchema: validationSchema2
    })

    const {
        applicant_id,
        application_id,
        application_date,
        application_year,
        application_type,
        student_number,
        university,
        uni,
        course,
        cor,
        unilevel,
        duration_of_study,
        year_of_study,
        completion_year,
        terms_and_conditions,
        amount_for_tuition,
        amount_for_books,
        amount_for_accommodation, 
        amount_for_transport,
        father_monthly_salary,
        mother_monthly_salary,
        spouse_monthly_salary,
        own_monthly_salary,
        cash_bank_savings,
        other_income,
        gold_silver_jewellery,
        investments_policies,
        investments,
        total_income,
        rent_bond_expense,
        rates,
        water_electricity,
        grocery_milk_bread,
        transport_petrol,
        telephone,
        medical_aid,
        insurance,
        other_expense,
        total_expense,
        liabilities,
        financial_declaration,
        the_course_you_wish,
        academic_achievements,
        community_involvement,
        competing,
        pledge_by_Applicant,
        files,
        id_document, 
        //acceptance_letter,
        affidavit,
        recommendation,
        motivation_letter,
        how_did_you_learn,
        how_did_you_learn_specify,
        assisted_by_sanzaf,
        assisted_by_sanzaf_specify,
        assisted_by_vocational_programme,
        assisted_by_vocational_programme_specify,
        mentor_participation,
        volunteer_participation,
        seminars_participation,
        tutor_participation,
        was_the_application_form_easy,
        was_the_application_easy_specify,
        opening_date,
        opening_date_explain,
        closing_date,
        closing_date_explain,
        instructions_clear,
        instructions_clear_explain,
        accept_decline,
        submitted
    } = values2
    const stepLable  = [
        'REGISTRATION',
        'BASIC INFORMATION',
        'SOCIAL INFORMATION',
        'APPLICATION',
        'REVIEW & SUBMIT'
    ];
    const steps = [
            {'id': stepLable[0]},
            {'id': stepLable[1]},
            {'id': stepLable[2]},
            {'id': stepLable[3]},
            {'id': stepLable[4]}
    ];


    let init = 0
    let init2 = 0
    if (localStorage.getItem('log')) { init = 1}
    //if (localStorage.getItem('application_id')) { init2 = 4 }
    const {step, navigation } = useStep({ steps, initialStep: init});
    
    const steps2 = [
        {'id': 'UNIVERSITY'},
        {'id': 'INCOME'},
        {'id': 'EXPENSES'},
        {'id': 'Upload Documents'},
        {'id': 'SURVEY'}
    ];

    const {step: step2, navigation: navigation2 } = useStep({steps: steps2, initialStep: init2});
    const registeration = { re_password, password, re_email, email , last_name, first_name }
    const basicinfo = {  
            address, suburb, city , province,  area_code,country, second_language, first_language, 
            id_number, id_type, date_of_birth, cell_phone,alternative_phone, alternative_email
     }
    const socioinfo = { 
        gender, race, religion, marital_status, employment_status, 
        number_of_siblings, spouse_name, spouse_contact, spouse_employment,
        reference_name, reference_email, reference_cell, reference_relationship
     }
    const inistitution =  {  application_type, student_number, university, uni,  course,cor,unilevel, duration_of_study, year_of_study, completion_year,
                            amount_for_tuition, amount_for_books, amount_for_accommodation,amount_for_transport}
    const income = { father_monthly_salary, mother_monthly_salary, spouse_monthly_salary, own_monthly_salary, 
                     cash_bank_savings, gold_silver_jewellery, investments_policies, investments, other_income ,total_income}
    const expense = {rent_bond_expense, rates, water_electricity, grocery_milk_bread, transport_petrol, telephone, 
                    medical_aid, insurance, other_expense, total_expense} 
    const survey = { the_course_you_wish, academic_achievements, community_involvement, competing, 
                        how_did_you_learn , how_did_you_learn_specify , assisted_by_sanzaf, assisted_by_sanzaf_specify, was_the_application_form_easy ,
                        was_the_application_easy_specify, opening_date , opening_date_explain,
                        closing_date, closing_date_explain , instructions_clear, instructions_clear_explain,  assisted_by_vocational_programme,
                        assisted_by_vocational_programme_specify, mentor_participation, volunteer_participation,seminars_participation,tutor_participation}
    const upload =  {"id_document": id_document ,  "affidavit": affidavit,
                     "recommendation": recommendation, "motivation_letter": motivation_letter}



     
    
    var upload3  = { 
        "ID Document": 'id_document', 
        "Testimonial Letter": 'recommendation' ,"Motivational Letter": 'motivation_letter'   ,"Proof of Income": 'affidavit' 
    }
  

    if (lookUpData != undefined)
        var list_of_universities = lookUpData['universities']
 
    const Basicinfoform = () => {
        var d = new Date()
        var yearoption = []
        var monthoption = [] 
        var dayoption = []
        var date = 'fffff'
        yearoption.push("year")
        for (var i=1930; i<=d.getFullYear(); i++){
            yearoption[i] = i
        }
        monthoption.push("month")
        for (var i=1; i<=12; i++){
            monthoption[i] = i
        }
        dayoption.push("day")
        for (var i=1; i<=31; i++){
            dayoption[i] = i
        }

        const validatedob = ()=>{
            if (year == 'year') {
                alert("select year for date of birth")
                return false
            }
            if (month == 'month') {
                alert("select month for date of birth")
                return false
            } 
            if (day == 'day') {
                alert("select day for date of birth")
                return false
            } 
            return true 
        }

        const validateAlternatives = ()=> {
            let email = JSON.parse(localStorage.getItem('log'))?.email || ""

            if ( email.toLowerCase()  === alternative_email.toLowerCase()) {
                alert("Alternate Email must be different to your previous Email")
                return false
            }
            //let cell_phone = JSON.parse(localStorage.getItem('log'))?.cell_phone
            if (cell_phone) {
                if (alternative_phone === cell_phone.toString()) {
                    alert("Alternate Phone must be different to your previous Phone")
                    return false
                }
            }
           
            return true
        }
        const handleday = (event) =>{
             setValues({
                ...values,
                date_of_birth: {
                      ...values.date_of_birth,
                      day: event.target.value,
                },
              });
        } 
        const handlemonth = (event) =>{
            setValues({
                ...values,
                date_of_birth: {
                      ...values.date_of_birth,
                      month: event.target.value,
                },
              });
        }
        const handleyear = (event) =>{
            setValues({
                ...values,
                date_of_birth: {
                      ...values.date_of_birth,
                      year: event.target.value,
                },
              });
        }
        const err = {alternative_email: errors.alternative_email, cell_phone: errors.cell_phone,  
                     country: errors.country, id_type: errors.id_type,   id_number: errors.id_number, first_language: errors.first_language, second_language: errors.second_language,  address: errors.address, suburb: errors.suburb, 
                     city: errors.city, province: errors.province, area_code: errors.area_code,  area: errors.area  }
        
        return  (<Container> 
                    <div className="row">              
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">Alternative Email </label>  <label className="form-label required">*</label>
                        <div>
                           <input id="alternative_email" name="alternative_email" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={alternative_email}/>
                           {touched.alternative_email && errors.alternative_email ? <div  className="alert alert-danger"> { errors.alternative_email } </div>: null} 
                           
                        </div>
                     </div>
                     <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label" >Cell Phone Number</label> <label className="form-label required">*</label>
                        <div>   
                            <input id="cell_phone" name="cell_phone" maxLength="10" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={cell_phone}/>
                            {/*<div  className="alert alert-info"> 10 digits, eg. 0831234567  </div>*/}
                               {touched.cell_phone && errors.cell_phone ? <div  className="alert alert-danger"> { errors.cell_phone } </div>: null}  
                        </div>
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label" >Alternative Phone Number</label>  <label className="form-label required">*</label>
                        <div>   
                            <input id="alternative_phone" name="alternative_phone" maxLength="10" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={alternative_phone}/>
                            {/*<div  className="alert alert-info"> 10 digits, eg. 0831234567  </div>*/}
                                
                        </div>
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label  className="form-label">Date of Birth</label> <label className="form-label required"  style={{ marginRight: 30}} >*</label>
                       
                        <select id="year" name="year" onChange={handleyear}  type="option" className="form-control" value={year} style={{width:60, padding: 10}}>                      
                      
                            { yearoption.map( index => ( <option  value={index} > {index } </option> ))}
                         </select>

                        
                        <select id="month" name="month" onChange={handlemonth}  type="option" className="form-control" value={month} style={{width: 70, padding: 10}}>                      
                        
                            { monthoption.map( index => ( <option  value={index} > {index } </option> ))}  
                        </select>
                        <select id="day" name="day" onChange={handleday}  type="option" className="form-control" value={day} style={{width: 60, padding: 10}}>                      
                    
                        { dayoption.map( index => ( <option   value={index} > {index } </option> ))}  
                        </select>
           
                    </div>
                   
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">Nationality</label><label className="form-label required" >*</label>
                        <div  >
                           <select id="country" name="country"  onBlur={handleBlur}  onChange={handleChange} value={country} type="option" className="form-select" >
                               <option selected hidden >select country</option>
                               { dump.list_of_countries.map((name, index )=>( <option value={name}  > { name } </option>))}
                               </select>
                               { touched.country && errors.country ? <div  className="alert alert-danger"> { errors.country } </div>: null}
                        </div>
                    </div>
                    
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                         <label className="form-label">ID Type</label><label className="form-label required" >*</label>                    
                     <div className="form-group">
                     <div>
                        <select id="id_type" name="id_type"  onBlur={handleBlur}  onChange={handleChange} className="form-select" value={id_type}  >
                        
                            <option  selected hidden >select ID type</option>
                            <option value="1">South African ID</option>
                            <option value="2">Passport</option>
                            <option value="3">Refugee/Asylum Permit</option>
                        </select>
                        { touched.id_type && errors.id_type ? <div  className="alert alert-danger"> { errors.id_type } </div>: null}
                     </div>
                    </div> 
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label"> ID/PASSPORT/Refugee Number </label>  <label className="form-label required" >*</label>
                        <div> 
                            <input id="id_number" name="id_number"  onBlur={handleBlur}  onChange={handleChange} type="text"  value={id_number}   className="form-control"   />
                            { touched.id_number && errors.id_number ? <div  className="alert alert-danger"> { errors.id_number } </div>: null}
                        </div>  
                    </div>
        
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">First Language</label>
                        <div  >
                            <select id="first_language" name="first_language"  onBlur={handleBlur}  onChange={handleChange} value={first_language}  type="option" className="form-select"   > 
                            <option  selected hidden >select first language</option>
                            { dump.list_of_languages.map(  (name, index ) => ( <option value={name}  > { name } </option>))} 
                            </select>
                            
                        </div>
                    </div>    
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">Second Language </label>
                        <div  >
                            <select id="second_language" name="second_language"  onBlur={handleBlur}  onChange={handleChange}  value={second_language}  type="option" className="form-select" > 
                            <option  selected hidden >select second language</option>
                            { dump.list_of_languages.map(  (name, index ) => ( <option value={name}  > { name } </option>))} 
                             </select>
                            
                        </div>
                    </div> 
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                            <label className="form-label">Address</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <textarea  id="address" name="address" className="form-control"  onBlur={handleBlur}  onChange={handleChange} value={address} type="text" /> 
                                { touched.address && errors.address ? <div  className="alert alert-danger"> { errors.address } </div>: null}
                            </div>
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Area</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <input  id="suburb" name="suburb" className="form-control"  onBlur={handleBlur}  onChange={handleChange} value={suburb} type="text"   /> 
                                { touched.suburb && errors.suburb ? <div  className="alert alert-danger"> { errors.suburb } </div>: null}
                            </div>
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">City</label> <label className="form-label required" >*</label> 
                        <div   >                           
                            <input   id="city" name="city" className="form-control"  onBlur={handleBlur}  onChange={handleChange} type="text" value={city}  /> 
                            { touched.city && errors.city ? <div  className="alert alert-danger"> { errors.city } </div>: null}
                            
                        </div>
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                         <label className="form-label">Province</label><label className="form-label required" >*</label> 
                         <div   >                           
                            <select  id="province" name="province"  onBlur={handleBlur}   onChange={handleChange} type="option" value={province} className="form-select"> 
                            <option selected hidden>select province</option>
                            { dump.list_of_provinces.map( (name, index) => (<option value={name}  > { name } </option>))}
                            </select> 
                            { touched.province && errors.province ? <div  className="alert alert-danger"> { errors.province } </div>: null}
                         </div>
                    </div> 
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Area Code</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <input  id="area_code" name="area_code" maxLength="4" className="form-control" onBlur={handleBlur} onChange={handleChange} value={area_code} type="text"  /> 
                                { touched.area_code && errors.area_code ? <div  className="alert alert-danger"> { errors.area_code } </div>: null}
                            </div>
                    </div>
                                                       
                    <div>                   
                         <input id="basicinfobuttin" type='button' value={forward}  style={{marginTop: 10}} className="btn-success form-control" fullWidth     
                           onClick={() =>{  if (noError(basicinfo,err) && (validatedob()) && validateAlternatives()) {window.scrollTo(0, 0); setValues({...values, race: country && country !== "South Africa" ? "Foreign National": race});    updateApplicant(basicinfo,1); 
                            }}} />
              
                    </div>     
            </div>
            </Container>)      
             
    }
     const Socioinfoform =()=> {          
            const err = { employment_status: errors.employment_status, gender: errors.gender, race: errors.race, religion: errors.religion, number_of_siblings: errors.number_of_siblings, 
            marital_status: errors.marital_status, spouse_name: errors.spouse_name, spouse_contact: errors.spouse_contact, spouse_employment: errors.spouse_employment,
            reference_relationship: errors.reference_relationship, reference_name: errors.reference_name, reference_email: errors.reference_email, reference_cell: errors.reference_cell}
      
        return <Container> 
                <div className="row">              
                <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">Employment Status</label> <label className="form-label required" >*</label>
                        <div>                           
                                <select id="employment_status" name="employment_status" onBlur={handleBlur} onChange={handleChange} type="option" value={employment_status}  className="form-select">  
                                <option selected hidden>select employment status</option>
                                { dump.list_of_employments.map((name, index) => <option value={name}  > {name} </option>)}</select> 
                                { touched.employment_status && errors.employment_status ? <div  className="alert alert-danger"> { errors.employment_status } </div>: null} 
                        </div>
                    </div> 
                    
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">Gender</label><label className="form-label required" >*</label>
                                      
            
   
                        <select id="gender" name="gender" onBlur={handleBlur} onChange={handleChange} value={gender} type="option" className="form-select"> 
                         <option selected hidden >select gender</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        </select>
                        { touched.gender && errors.gender ? <div  className="alert alert-danger"> { errors.gender } </div>: null}
        
                    </div>
                    {country == "South Africa" && <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                     <div >
                        <label className="form-label">Race</label><label className="form-label required" >*</label> 
                            <div >                           
                                 <select  id="race" name="race" onBlur={handleBlur} onChange={handleChange} value={race} type="option" className="form-select"> 
                                 <option selected hidden >select race</option>
                                 { dump.list_of_races.map((name, index )=> <option value={name} > {name}</option>)} 
                                 </select> 
                                 {/*<div  className="alert alert-info"> This information is used for statistical purposes only </div>*/}
                                 {touched.race && errors.race ? <div  className="alert alert-danger">  { errors.race} </div> : null} 
                                 
                            </div>
                        </div> 
                    </div>}
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">Religion</label><label className="form-label required" >*</label>
                            <div>                           
                                 <select id="religion" name="religion" onBlur={handleBlur}   onChange={handleChange} value={religion} className="form-select" type="option"> 
                                 <option selected hidden>select religion</option>
                                 { dump.list_of_religion.map((name, index)  => <option value={name}  > {name}</option>) }
                                 </select>
                                 {/*<div  className="alert alert-info"> This information is used for statistical purposes only </div>*/}
                                 { touched.religion && errors.religion ? <div  className="alert alert-danger"> { errors.religion } </div>: null}  
                                  
                            </div>
                        </div>
                        
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                            <label className="form-label">Number of Siblings</label> 
                            <div   >                           
                                 <input  id="number_of_siblings" name="number_of_siblings" maxLength="2" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={number_of_siblings}/>
                                 { touched.number_of_siblings && errors.number_of_siblings ? <div  className="alert alert-danger"> { errors.number_of_siblings } </div>: null}  
                            </div>
                        </div> 
                                  
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">Marital Status</label> <label className="form-label required" >*</label>
                        <div>                           
                                <select id="marital_status" name="marital_status" value={marital_status}  onBlur={handleBlur} alue={marital_status}  onChange={handleChange} className="form-select" type="option"> 
                                   <option selected hidden >select marital status</option>
                                    {dump.list_of_maritals.map((name, index) => <option value={name}  > {name} </option> )}
                                   
                                </select> 
                                { touched.spouse_employment && errors.marital_status ? <div  className="alert alert-info"> { errors.marital_status } </div>: null} 
                        </div>
                        </div>
                        { marital_status && marital_status ==  "Married" ?
                        <div>
                            <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                                <label className="form-label" >Spouse Name</label> <label className="form-label required" >*</label>
                                <div   >                           
                                    <input id="spouse_name" name="spouse_name" className="form-control"  onBlur={handleBlur}  onChange={handleChange} type="text" value={spouse_name}  />                                     
                                </div>
                            </div> 
                            <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                                <label className="form-label">Spouse Cell Phone number</label> 
                                <div   >                           
                                    <input id="spouse_contact" name="spouse_contact" maxLength="10" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={spouse_contact}  />
                                    {/*<div  className="alert alert-info"> 10 digits, eg. 0831234567  </div>  */}                                 
                                </div>
                            </div>   
                            <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                                <label className="form-label">Spouse Employment Status</label> <label className="form-label required" >*</label>
                                 <div >                           
                                    <select   id="spouse_employment" name="spouse_employment"  onBlur={handleBlur}  onChange={handleChange} alue={spouse_employment} className="form-select" type="option" >
                                    <option  selected hidden>select employment status</option>
                                    { dump.list_of_employments.map((name, index) => <option value={name}  > {name} </option>)}</select>  
                                    { touched.spouse_employment && errors.spouse_employment ? <div  className="alert alert-info"> { errors.marital_status } </div>: null} 
                                </div>
                            </div>  
                        </div> : null}
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Reference Type</label>  <label className="form-label required" >*</label> 
                             <div>                            
                             <select id="reference_relationship" name="reference_relationship"   onChange={handleChange} value={reference_relationship} className="form-select" type="option">
                             <option selected hidden >select reference type </option>
                                  { dump.list_of_referenceRelationship.map((name, index)  => <option value={name}  > {name}</option>) }</select>
                            
                             </div>
                        </div> 
                   
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" > Reference Name</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="reference_name" name="reference_name" className="form-control"  onChange={handleChange} type="text" value={reference_name}  /> 
                                { touched.reference_name && errors.reference_name ? <div  className="alert alert-info"> { errors.reference_name } </div>: null} 
                             </div>
                        </div> 
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Reference Cell Phone</label> <label className="form-label required" >*</label>
                             <div>                           
                                <input   id="reference_cell" name="reference_cell" maxLength="10" className="form-control"  onChange={handleChange} type="text" value={reference_cell}  /> 
                                {/*<div  className="alert alert-info"> 10 digits, eg. 0831234567  </div>   */}
                             </div>
                        </div> 
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Reference Email</label> 
                             <div >                           
                                <input id="reference_email" name="reference_email" className="form-control"  onChange={handleChange} type="text" value={reference_email}  /> 
                                 
                             </div>
                        </div> 
                        <div className="form-group">
                       
                        <div style={{ marginTop: 10}}>

                <input id="socciinfobutton" type="button" value={forward}  className="btn-success form-control" fullWidth  onClick={() =>{ if (noError(socioinfo,err)) {window.scrollTo(0, 0); setValues({...values, loading: 'loading'}); updateApplicant(socioinfo,2) }}} />
                </div> 
                <div style={{ marginTop: 10}}>     
                <input id="socciinfobutton1" type="button" value={backward} className="btn-success form-control" fullWidth    onClick={() => setTabValue(0)}   style={{marginRight: 0 }}/>
            </div>
         </div>   
                
        </div>
        </Container>
    
    }
 
   
    const InstitutionForm = ( ) => {
        var list_of_universities 
        var list_of_courses
        if (lookUpData != undefined){
            
             list_of_universities = lookUpData['universities']
             list_of_courses = lookUpData['courses']
        }
        else {
            list_of_universities = dump.list_of_universities
            list_of_courses = dump.list_of_courses
        }
        const err =  {  application_type: errors2.application_type, university: errors2.university, uni: errors2.uni, student_number: errors2.student_number, course: errors2.course, unilevel: errors2.unilevel, duration_of_study: errors2.duration_of_study, 
            year_of_study: errors2.year_of_study, completion_year: errors2.completion_year, amount_for_tuition: errors2.amount_for_tuition, amount_for_books: errors2.amount_for_books, 
            amount_for_accommodation: errors2.amount_for_accommodation, amount_for_transport: errors2.amount_for_transport }
         
           return <div className='row'>  
                       {!applicantDetails?.application_type && <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                            <label className="form-label">Application Type</label> <label className="form-label required" >*</label>
                            <div>                         
                                <select   id="application_type" name="application_type" type="option" className="form-select"  onBlur={handleBlur2} onChange={handleChange2} value={application_type} >
                                <option selected>select application type</option>
                                {dump.list_of_application_types.map((name, index) => <option value={name}  > {name} </option>)}</select>
                            </div>
                        </div>}                    
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">University</label> <label className="form-label required" >*</label>
                             <div>                           
                                <select id="university" name="university" className="form-select"  type="option" onBlur={handleBlur2} onChange={handleChange2} value={university} >
                                    <option selected >select university</option>
                                    { dump.list_of_universities.map(( name,index )=> <option value={name}  > { name } </option>)} 
                                    </select>
                                    {touched2.university && errors2.university ? <div  className="alert alert-danger"> { errors2.university } </div>: null}
                             </div>
                        </div>
                        { university === 'Other' && <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                             <label className="form-label">Please Specify</label><label className="form-label required" >*</label>
                             <div>                           
                                <input id="uni" name="uni" className="form-control" onBlur={handleBlur2} onChange={handleChange2} type="text" value={uni}  /> 
                                { touched2.uni && errors2.uni ? <div  className="alert alert-danger"> { errors2.uni } </div>: null}
                             </div>
                        </div> }
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Course</label> <label className="form-label required" >*</label> 
                             <div>                           
                                <select   id="course" name="course" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={course} >
                                    <option selected>select course</option>
                                    { university === "IPSA (International Peace College South Africa)" ? 
                                       dump.list_of_IPSA_courses.map((name,index)=> <option value={name}> {name} </option>) : dump.list_of_courses.map((name,index)=> <option value={name}> {name} </option>)}
                                  
                                </select>
                                    { touched2.course && errors2.course ? <div  className="alert alert-danger"> { errors2.course } </div>: null}
                             </div>
                        </div>
                        { course === 'OTHER' && <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Please Specify</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <input id="cor" name="cor" className="form-control"  onChange={handleChange2} type="text" value={cor}  /> 
                                { touched2.cor &&  errors2.cor ? <div  className="alert alert-danger"> { errors2.cor } </div>: null}
                                 
                             </div>
                        </div> }
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Study Level</label> <label className="form-label required" >*</label>
                             <div>                         
                                 <select   id="unilevel" name="unilevel" type="option" className="form-select" onBlur={handleBlur2} onChange={handleChange2} value={unilevel}  >
                                    <option selected>select study level</option>
                                      { dump.list_of_study_levels.map((name, index) => <option value={name}> {name} </option>)}</select>
                                     
                            </div>
                        </div>     
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Year of Study</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <select id="year_of_study" name="year_of_study" className="form-select"  onChange={handleChange2} value={year_of_study}>
                                 <option selected hidden>select the year of study </option> 
                                 <option value="1">First Year</option>
                                 <option value="2">Second Year</option>
                                 <option value="3">Third Year</option>
                                 <option value="4">Fourth Year</option>
                                 <option value="5">Fifth Year</option>
                                 <option value="6">Sixth Year</option>
                                 <option value="7">Seventh Year</option>
                                </select>
                                { touched2.year_of_study && errors2.year_of_study ? <div  className="alert alert-info"> { errors2.year_of_study } </div>: null}
                             </div>
                        </div>
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Student Number</label> 
                             <div   >                           
                                <input id="student_number" name="student_number" className="form-control"  onChange={handleChange2} type="text" value={student_number}/> 
                             </div>
                        </div>
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Duration of Study</label> <label className="form-label required" >*</label> 
                             <div>                           
                                <select   id="duration_of_study" name="duration_of_study" className="form-select"  onChange={handleChange2} value={duration_of_study}>
                                    <option selected >select how long your Course will be </option> 
                                    <option value="1">1 year</option>
                                    <option value="2">2 years</option>
                                    <option value="3">3 years</option>
                                    <option value="4">4 years</option>
                                    <option value="5">5 years</option>
                                    <option value="6">6 years</option>
                                    <option value="7">7 years</option>
                                </select>
                                { touched2.duration_of_study && errors2.duration_of_study ? <div  className="alert alert-info"> { errors2.duration_of_study } </div>: null}
                             </div>
                        </div>
                       
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Completion Year</label> <label className="form-label required" >*</label>
                             <div>                           
                                <select   id="completion_year" name="completion_year" className="form-select"  onChange={handleChange2} value={completion_year}>
                                 <option selected hidden>select year in which your Course will be completed</option> 
                                 <option value="2023">2023</option>
                                 <option value="2024">2024</option>
                                 <option value="2025">2025</option>
                                 <option value="2026">2026</option>
                                 <option value="2027">2027</option>
                                 <option value="2028">2028</option>
                                 <option value="2029">2029</option>
                                </select>
                            { touched2.completion_year && errors2.completion_year ? <div  className="alert alert-info"> { errors2.completion_year } </div>: null}
                            </div>
                        </div>
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Required Amount for Tuition</label> <label className="form-label required" >*</label> 
                             <div>                           
                                <input   id="amount_for_tuition" name="amount_for_tuition" className="form-control" onBlur={handleBlur2}  onChange={handleChange2} type="text" value={amount_for_tuition}  /> 
                                {touched2.amount_for_tuition && errors2.amount_for_tuition ? <div  className="alert alert-denger">  { errors2.amount_for_tuition} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                            <label className="form-label">Required Amount for Books</label> <label className="form-label required" >*</label> 
                            <div>                           
                                <input  id="amount_for_books" name="amount_for_books" className="form-control" onBlur={handleBlur2}  onChange={handleChange2} type="text" value={amount_for_books}  /> 
                                {touched2.amount_for_books && errors.amount_for_books ?<div  className="alert alert-danger">{ errors2.amount_for_books} </div> : null}
                            </div>
                        </div> 
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                             <label className="form-label">Required Amount for Accommodation</label> <label className="form-label required" >*</label> 
                             <div>                           
                                <input   id="amount_for_accommodation" name="amount_for_accommodation" className="form-control" onBlur={handleBlur2}  onChange={handleChange2} type="text" value={amount_for_accommodation}  /> 
                                {touched2.amount_for_accommodation && errors.amount_for_accommodation ? <div  className="alert alert-danger">  { errors.amount_for_accommodation} </div> : null}
                                
                             </div>
                        </div>  
                        <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                             <label className="form-label">Required Amount for Travelling</label> <label className="form-label required" >*</label> 
                             <div>                           
                                <input   id="amount_for_transport" name="amount_for_transport" className="form-control" onBlur={handleBlur2}  onChange={handleChange2} type="text" value={amount_for_transport}  /> 
                                {touched2.amount_for_transport && errors.amount_for_transport ? <div  className="alert alert-danger">  { errors.amount_for_transport} </div> : null}
                             </div>
                        </div> 
                       <div style={{ marginTop: 10}}>  
                        <input id="uni3" type="button" value={forward}  className="btn-success form-control" fullWidth    onClick={() =>{ 
                                    if (noError(inistitution,err) && notEmptyField(inistitution)) {window.scrollTo(0, 0); setValues({...values, loading: 'loading'});
                                    if (localStorage.getItem('application_id')){
                                        updateApplication(inistitution,3)
                                    } else {
                                        submitApplication(inistitution,3)} }}}/>
                        </div>
                        <div style={{ marginTop: 10}}>  
                           <input id="uni2" type="button" value={backward}  className="btn-success form-control" fullWidth    onClick={() => setTabValue(1)}  />    
                     
                        </div>                         
                  </div>
    }
    const UploadForm = () => {            
        const err = { id_document: errors2.id_document , affidavit: errors2.affidavit,
             recommendation: errors2.recommendation, motivation_letter: errors2.motivation_letter}  
            
        return <div>
                        
                    <div className="form-group">
                            <div> 
                                <label className="form-label" >ID Document</label> <label className="form-label required" >*</label> 
                                <div> 
                                    <div className='rowC'>                          
                                    <Input  id="id_document" name="id_document" placeholder={id_document?.fileName} className="form-control"   onChange={(e) =>{
                                                                    //let _files = files
                                                                    //_files["id_document"].blob =  e.target.files[0]
                                                                    //_files["id_document"].uploaded =  true
                                                                
                                                                    setValues2({...values2, id_document: e.target.files[0] });
                                                                    
                                                                    }} type="file" /> 
                                       <input id="up2" type="button" value="Upload" style={{width: 250, marginLeft: 10}}  className="btn-success form-control" 
                                                                onClick={() =>{ if (! fileError('id_document')) { _uploadFile('id_document',0)}}}/>
                                </div> 
                                                                    {id_document?.fileName}
                                    { values2['id_document'] && progressInfos['id_document'].percentage > 0 && <div>  <ProgressBar progress={progressInfos['id_document']}/> </div>  }
                                    {touched2.id_document && errors2.id_document ? <div  className="alert alert-danger">  { errors2.id_document} </div> : null} 
                                </div>
                            </div>
                        </div> 
                     <div className="form-group">
                            <div> 
                                <label className="form-label" >Testimonial Letter</label> <label className="form-label required" >*</label> 
                                <div> 
                                    <div className='rowC'>                          
                                    <Input  id="recommendation" name="recommendation" placeholder={recommendation?.fileName} className="form-control"   onChange={(e) =>{
                                        //let _files = files
                                        //_files["id_document"].blob =  e.target.files[0]
                                        //_files["id_document"].uploaded =  true
                                        setValues2({...values2, recommendation: e.target.files[0] });
                                        }} type="file" /> 
                                    <input id="up2" type="button" value="Upload" style={{width: 250, marginLeft: 10}}  className="btn-success form-control" 
                                                                onClick={() =>{  if (!fileError('recommendation')) { _uploadFile('recommendation',1)}}  }/>
                                </div> 
                                    {id_document?.fileName}
                                    {progressInfos['recommendation'].percentage > 0 && <div>  <ProgressBar progress={progressInfos['recommendation']}/> </div>  }
                                    {touched2.recommendation && errors2.recommendation ? <div  className="alert alert-danger">  { errors2.recommendation} </div> : null} 
                                </div>
                            </div>
                        </div>
                     <div className="form-group">
                             <label  className="form-label" >Proof of Income</label> <label className="form-label required" >*</label> 
                             <div>  
                                <div className='rowC'>                         
                                <Input id="affidavit" name="affidavit" placeholder={affidavit?.fileName}  className="form-control"  onChange={(e) =>{
                                                               //  let _files = files
                                                                // _files["affidavit"].blob =  e.target.files[0]
                                                                 //_files["affidavit"].uploaded =  true
                                                                 setValues2({...values2, affidavit: e.target.files[0] });
                                                                 }} type="file" /> 
                                     <input id="up2" type="button" value="Upload" style={{width: 250, marginLeft: 10}}  className="btn-success form-control" 
                                     onClick={() =>{if (! fileError('affidavit')) {  _uploadFile('affidavit',2)} }} />
                                </div>
                                {progressInfos['affidavit'].percentage > 0 && <div>  <ProgressBar progress={progressInfos['affidavit']}/> </div>  }
                                { touched2.affidavit && errors2.affidavit ? <div  className="alert alert-danger">  { errors2.affidavit} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"  >Motivational Letter</label> <label className="form-label required" >*</label> 
                             <div>   
                             <div className='rowC'>                        
                                <Input id="motivation_letter" name="motivation_letter" placeholder={motivation_letter?.fileName} className="form-control" onChange={(e) =>{
                                                                  //let _files = files
                                                                  //_files["motivation_letter"].blob =  e.target.files[0]
                                                                  //_files["motivation_letter"].uploaded =  true
                                                                  setValues2({...values2, motivation_letter: e.target.files[0] });
                                                  
                                                                 }} type="file" />                               
                                    <input id="up2" type="button" value="Upload" style={{width: 250, marginLeft: 10}}  className="btn-success form-control"
                                     onClick={() =>{ if (! fileError('motivation_letter')) { _uploadFile('motivation_letter',3)} }}/>
                                </div>
                                {progressInfos['motivation_letter'].percentage > 0 && <div>  <ProgressBar progress={progressInfos['motivation_letter']}/> </div>  }
                                { touched2.motivation_letter && errors2.motivation_letter ? <div  className="alert alert-danger">  { errors2.motivation_letter} </div> : null}
                             </div>
                        </div> 
                                     
                  
                     <div style={{ marginTop: 10}}>
                         { <input id="up3" type="button" value="Save and Continue"  className="btn-success form-control" fullWidth  onClick={
                             
                             //() =>  setTabValue(6) 
                             () => { { if (_filesUploaded()){ setTabValue(4)}}}
                                                                                         }   
                            />                                
                         }  </div>
                     <div style={{ marginTop: 10}}>
                         { <input id="up3" type="button" value="Back"  className="btn-success form-control" fullWidth  onClick={
                             
                             () => { setTabValue(2)}
                                                                                         }   
                            />                                
                         }  </div>      
                     <div style={{ marginTop: 10}}> 
                    <div>  {message.length > 0 && ( <div className="alert alert-secondary" role="alert"> <ul>   {message.map((item, i) => { return <li key={i}>{item}</li>;
                                             })}
                              </ul>
                  </div>  )}  </div>
                  </div>  
               </div>
    }

    const IncomeForm = ()=> {         
        const err =  { father_monthly_salary: errors2.father_monthly_salary , mother_monthly_salary: errors2.mother_monthly_salary, spouse_monthly_salary: errors2.spouse_monthly_salary,
                        own_monthly_salary: errors2.own_monthly_salary,  cash_bank_savings: errors2.cash_bank_savings, gold_silver_jewellery: errors2.gold_silver_jewellery,
                        investments_policies: errors2.investments_policies , investments: errors2.investments, other_income: errors2.other_income   }

        return <div>
                     <div  className="alert alert-info" style={{width:350, marginRight:500}}> <label className="form-label required" >*</label> Type ''0'' if not applicable or no income </div>
                     <div className='row'>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label"  >Father Monthly Salary </label>  <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="father_monthly_salary" name="father_monthly_salary" className="income form-control" onBlur={sumIncome}  onChange={handleIncome} value={father_monthly_salary} type="text"   /> 
                                
                                { touched2.father_monthly_salary && errors2.father_monthly_salary ? <div  className="alert alert-denger"> { errors2.father_monthly_salary } </div>: null} 
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Mother Monthly Salary</label>  <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="mother_monthly_salary" name="mother_monthly_salary" className="income form-control" onBlur={sumIncome}   onChange={handleIncome} value={mother_monthly_salary} type="text"   /> 
                                 
                                 { touched2.mother_monthly_salary && errors2.mother_monthly_salary ? <div  className="alert alert-denger"> { errors.mother_monthly_salary } </div>: null} 
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Your Own Monthly Salary</label>  <label className="form-label required" >*</label>
                             <div>                           
                                <input   id="own_monthly_salary" name="own_monthly_salary" className="income form-control"  onBlur={sumIncome}   onChange={handleIncome} value={own_monthly_salary} type="text"   /> 
                                { touched2.own_monthly_salary && errors2.own_monthly_salary ? <div  className="alert alert-denger"> { errors.own_monthly_salary } </div>: null} 
                             </div>
                        </div>  
                        
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Your Spouse Monthly Salary</label>  <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="spouse_monthly_salary" name="spouse_monthly_salary" className="income form-control"   onBlur={sumIncome}  onChange={handleIncome} value={spouse_monthly_salary} type="text" /> 
                                { touched2.spouse_monthly_salary && errors2.spouse_monthly_salary ? <div  className="alert alert-denger"> { errors.spouse_monthly_salary } </div>: null} 
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Investments</label>  <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="investments" name="investments" className=" form-control"   onBlur={sumIncome}   onChange={handleIncome} value={investments} type="text"   /> 
                                { touched2.investments && errors2.investments ? <div  className="alert alert-denger"> { errors.investments } </div>: null} 
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Other Source of Income</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="other_income" name="other_income" className="form-control income"  onBlur={sumIncome}   onChange={handleIncome} value={other_income} type="text"  /> 
                                { touched2.other_income && errors2.other_income ? <div  className="alert alert-denger"> { errors.other_income } </div>: null}
                             </div>
                        </div>  
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label" >Total  Income</label> 
                             <div   >                           
                                <input   id="total_income" name="total_income" className="form-control"    type="text" value={  total_income} disabled  /> 
                                 { errors2.total_income ? errors2.total_income : null}
                             </div>
                         </div> 
                  
                         <div style={{ marginTop: 10}}> 
                        
                         <input id="inc2" type="button" value={forward}  className="btn-success form-control" fullWidth    onClick={() =>{ if (noError(income,err)) {window.scrollTo(0, 0); setValues({...values, loading: 'loading'}); updateApplication(income,5) }}}  />
                            
                         </div>   
                         <div style={{ marginTop: 10}}> 
                          
                          <input id="inc1" type="button" value={backward}  className="btn-success form-control" fullWidth     onClick={() => setTabValue(3)}  />
                            

                        </div> 
               </div>
               </div>
    }
    const handleIncome = (e) =>{
        var value 
        value = e.target.value
        if ( isNaN(value) || ( value == "")){
            //alert('Enter number')  
            e.target.value = ""
            value = 0
           
         }  else {
           value = e.target.value

         } 
        setValues2({...values2, [e.target.name]:  value}) 

    }
   
    const sumIncome = (e) =>{
        var sum = 0
        const income = [mother_monthly_salary, father_monthly_salary, spouse_monthly_salary, own_monthly_salary, investments, other_income ]
        income.map(inc =>{
            if (inc == '' || undefined) {
                inc = 0
            }
            sum = sum + parseFloat(inc)
        })
        setValues2({...values2,  total_income: sum })       
        //sum = mother_monthly_salary, father_monthly_salary, spouse_monthly_salary, own_monthly_salary, investments 
        /* sum = parseFloat(inc[0]) + parseFloat(inc[1]) + parseFloat(inc[2]) + parseFloat(own_monthly_salary) +
              parseFloat( investments) + parseFloat( other_income)
              setValues2({...values2,  total_income: sum })       

        sum = parseFloat(mother_monthly_salary) + parseFloat(father_monthly_salary) + parseFloat(spouse_monthly_salary) + parseFloat(own_monthly_salary) +
              parseFloat( investments) + parseFloat( other_income)
              setValues2({...values2,  total_income: sum })       */

    }


    const ExpenseForm = ()=> {
        const err = {rent_bond_expense: errors2.rent_bond_expense, rates: errors2.rates , water_electricity: errors2.water_electricity, 
            transport_petrol: errors2.transport_petrol, telephone: errors2.telephone, 
            medical_aid: errors2.medical_aid, insurance: errors2.insurance,  grocery_milk_bread: errors2.grocery_milk_bread, other: errors2.other_expense} 

        return <div>
                        <div  className="alert alert-info"  style={{width:250, marginRight:500}}><label className="form-label required" >*</label>Type ''0'' if not applicable  </div>
                        <div className='row'>
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label"> Rent / Bond </label>  <label className="form-label required" >*</label>
                             <div   >
                                <input   id="rent_bond_expense" name="rent_bond_expense" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ rent_bond_expense}  /> 
                                
                                { touched2.rent_bond_expense && errors2.rent_bond_expense ? <div  className="alert alert-denger"> { errors.rent_bond_expense } </div>: null}
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label"> Rates</label>  <label className="form-label required" >*</label>
                             <div   >
                                <input   id="rates" name="rates" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ rates}  /> 
                                { touched2.rates && errors2.rates ? <div  className="alert alert-denger"> { errors.tates } </div>: null}
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label"> Water & Electricity</label>  <label className="form-label required" >*</label>
                             <div   >
                                <input   id="water_electricity" name="water_electricity" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ water_electricity}  /> 
                                { touched2.water_electricity && errors2.water_electricity ? <div  className="alert alert-denger"> { errors.water_electricity } </div>: null}

                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label"> Transport / Petrol </label>  <label className="form-label required" >*</label>
                             <div   >
                                <input   id="transport_petrol" name="transport_petrol" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ transport_petrol}  /> 
                                { touched2.transport_petrol && errors2.transport_petrol ? <div  className="alert alert-denger"> { errors.transport_petrol } </div>: null}
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Expense for Telephone and Internet</label> <label className="form-label required" >*</label>
                             <div   >
                                <input   id="telephone" name="telephone" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ telephone}  /> 
                                { touched2.telephone && errors2.telephone ? <div  className="alert alert-denger"> { errors.telephone } </div>: null}
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Medical Aid</label> <label className="form-label required" >*</label>
                             <div   >
                                <input   id="medical_aid" name="medical_aid" className="form-control" onBlur={sumExpense}  onChange={handleExpense} type="text" value={ medical_aid}  /> 
                                { touched2.medical_aid && errors2.medical_aid ? <div  className="alert alert-denger"> { errors.medical_aid } </div>: null}
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Insurance</label> <label className="form-label required" >*</label>
                             <div   >
                                <input   id="insurance" name="insurance" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ insurance}  /> 
                                { touched2.insurance && errors2.insurance ? <div  className="alert alert-denger"> { errors.insurance } </div>: null}
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Groceries</label>  <label className="form-label required" >*</label> 
                             <div>
                                <input   id="grocery_milk_bread" name="grocery_milk_bread" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ grocery_milk_bread}  /> 
                                { touched2.grocery_milk_bread && errors2.grocery_milk_bread ? <div  className="alert alert-denger"> { errors.grocery_milk_bread } </div>: null}
                             </div>
                        </div> 
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Other Expenses</label><label className="form-label required" >*</label> 
                             <div   >
                                <input   id="other_expense" name="other_expense" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ other_expense}  /> 
                                { touched2.grocery_milk_bread && errors2.grocery_milk_bread ? <div  className="alert alert-denger"> { errors.grocery_milk_bread } </div>: null}
                                
                             </div>
                        </div>  
                       <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                             <label className="form-label">Total Expense</label> 
                             <div>                           
                                <input   id="total_expense" name="total_expense" className="form-control"    type="text" value={  total_expense} disabled  /> 
                                 { errors2.total_expense ? errors2.total_expense : null}
                             </div>
                         </div> 
                  
                         <div style={{ marginTop: 10}}> 
                        
                        <input id="exs1" type="button" value={forward}  className="btn-success form-control" fullWidth    onClick={() =>{ if (noError(expense,err)) {window.scrollTo(0, 0); setValues({...values, loading: 'loading'}); updateApplication(expense,6) }}}  />
                           
                        </div>   
                        <div style={{ marginTop: 10}}> 
                          
                          <input id="inc1" type="button" value={backward}  className="btn-success form-control" fullWidth     onClick={() => setTabValue(4)}  />
                        </div> 
                </div>      
               </div>
    }
    const handleExpense = (e) =>{
        var value 
        value = e.target.value
        if ( isNaN(value) || ( value == "")){  
            value = 0           
        }  else {
             value = e.target.value
        } 
        setValues2({...values2, [e.target.name]:  value})   
    }
    const sumExpense = (e) =>{ 
        var sum = 0
        const exp = [ rent_bond_expense, rates, water_electricity, grocery_milk_bread, transport_petrol, telephone, 
            medical_aid, insurance, other_expense]
        exp.map(ex =>{
            if (ex == '' || undefined) {
                ex = 0
            }
            sum = sum + parseFloat(ex)
     })
        setValues2({...values2,  total_expense: sum })    
        
    }
    const SurveyForm = () => { 
        const err = {  the_course_you_wish: errors2.the_course_you_wish, academic_achievements: errors2.academic_achievements, community_involvement: errors2.community_involvement, competing: errors2.competing,
                        how_did_you_learn: errors2.how_did_you_learn,  assisted_by_sanzaf: errors2.assisted_by_sanzaf, 
                        was_the_application_form_easy: errors2.was_the_application_easy, 
                        opening_date: errors2.opening_date, closing_date: errors2.closing_date, instructions_clear: errors2.instructions_clear,
                      
                        mentor_participation: errors2.mentor_participation,
                        volunteer_participation: errors2.volunteer_participation,
                        seminars_participation: errors2.seminars_participation,
                        tutor_participation: errors2.tutor_participation }                        
        return <div>   

                       {} <div className="form-group">
                             <label className="form-label"> Tell us about the course you wish to study?</label> <label className="form-label required" >*</label> 
                             <div   >
                                <textarea rows="4" cols="50"   id="the_course_you_wish" name="the_course_you_wish" className="form-control" onBlur={handleBlur2} onChange={handleChange2} type="text" value={ the_course_you_wish}  /> 
                                {touched2.the_course_you_wish && errors2.the_course_you_wish ? <div  className="alert alert-danger">  { errors.the_course_you_wish} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label" >Tell us about your best academic achievements?</label> <label className="form-label required" >*</label> 
                             <div   >
                                <textarea rows="4" cols="50"  id="academic_achievements" name="academic_achievements" className="form-control" onBlur={handleBlur2} onChange={handleChange2} type="text" value={ academic_achievements}  /> 
                                {touched2.academic_achievements && errors.academic_achievements ? <div  className="alert alert-info">  { errors.academic_achievements} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> Tell us about your community involvement?</label> <label className="form-label required" >*</label> 
                             <div   >
                                <textarea rows="4" cols="50" id="community_involvement" name="community_involvement" className="form-control" onBlur={handleBlur2} onChange={handleChange2} type="text" value={ community_involvement}  /> 
                                {touched2.community_involvement && errors.community_involvement ? <div  className="alert alert-info">  { errors.community_involvement} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> You are competing for a limited number of bursaries with 100’s of other applicants. Tell us why you should receive a SANZAF Zakah Bursary?<label className="form-label required" >*</label> </label>
                             
                             <div   >
                                <textarea rows="4" cols="50"    id="competing" name="competing" className="form-control"  onChange={handleChange2} type="text" value={ competing}  /> 
                                {touched2.competing && errors.competing ? <div  className="alert alert-info">  { errors.competing} </div> : null}
                             </div> 
                        </div>
                        <div style={{ marginTop: 10}}> 
                                              
                        <div className="form-group">
                             <label className="form-label" >Have any of your parents been assisted by SANZAF?<label className="form-label required" >*</label>  </label>
                             <div>                           
                                <select   id="assisted_by_sanzaf" name="assisted_by_sanzaf" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={assisted_by_sanzaf} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.assisted_by_sanzaf && errors.assisted_by_sanzaf ? <div  className="alert alert-danger">  { errors.assisted_by_sanzaf} </div> : null}
                                   
                             </div>
                        </div>
                       
                        { assisted_by_sanzaf === '1' && <div className="form-group">
                             <label className="form-label">If yes, please enter name and surname of your parent<label className="form-label required" >*</label> </label> 
                             <div   >                           
                                <input id="assisted_by_sanzaf_specify" name="assisted_by_sanzaf_specify" className="form-control"  onChange={handleChange2} value={assisted_by_sanzaf_specify} type="text"/> 
                             </div>
                        </div> } 
                        <div className="form-group">
                             <label className="form-label">Have you previously completed a course through the SANZAF Vocational Training programme?<label className="form-label required" >*</label>  </label>
                             <div>                           
                                <select   id="assisted_by_vocational_programme" name="assisted_by_vocational_programme" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={assisted_by_vocational_programme} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.assisted_by_vocational_programme && errors.assisted_by_vocational_programme ? <div  className="alert alert-danger">  { errors.assisted_by_vocational_programme} </div> : null}
                                   
                             </div>
                             { assisted_by_vocational_programme === '1' && <div className="form-group">
                             <label className="form-label">If yes, state name of course and year completed.<label className="form-label required" >*</label> </label> 
                             <div   >                           
                                <input id="assisted_by_vocational_programme_specify" name="assisted_by_vocational_programme_specify" className="form-control"  onChange={handleChange2} value={assisted_by_vocational_programme_specify} type="text"/> 
                             </div>
                        </div> } 
                        </div>
                        <label className="form-label">Would you like to participate in our Mentoring Youth (MY) programmes and activities?<label className="form-label required" >*</label>  </label>
                             <div>                           
                                <select   id="mentor_participation" name="mentor_participation" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={mentor_participation} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.mentor_participation && errors.mentor_participation ? <div  className="alert alert-danger">  {errors.mentor_participation} </div> : null}
                            </div>
         
                        <div className="form-group">
                             <label className="form-label" >Would you like to volunteer in our Events and Campaigns, Community Outreach programmes, including Fundraisers?<label className="form-label required" >*</label>  </label>
                             <div>                           
                                <select   id="volunteer_participation" name="volunteer_participation" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={volunteer_participation} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.volunteer_participation && errors.volunteer_participation ? <div  className="alert alert-danger">  { errors.volunteer_participation} </div> : null}
                                   
                             </div>
                        </div>
                        <div className="form-group">
                             <label className="form-label" >Would you like to participate in our Seminars and Workshops?<label className="form-label required" >*</label>  </label>
                             <div>                           
                                <select   id="seminars_participation" name="seminars_participation" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={seminars_participation} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.seminars_participation && errors.seminars_participation ? <div  className="alert alert-danger">  { errors.seminars_participation} </div> : null}
                                   
                             </div>
                        </div>
                        <div className="form-group">
                             <label className="form-label">SANZAF requires MY facilitators for mainly English and Mathematics. These classes run on Saturday mornings and more info will be provided to those interested. Would you be interested in becoming a MY tutor?<label className="form-label required" >*</label>  </label>
                             <div>                           
                                <select   id="tutor_participation" name="tutor_participation" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={tutor_participation} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.tutor_participation && errors.tutor_participation ? <div  className="alert alert-danger">  { errors.tutor_participation} </div> : null}
                                   
                             </div>
                        </div>
                        <div className="form-group">
                             <label className="form-label" >How did you learn of the SANZAF Bursary Programme?<label className="form-label required" >*</label></label> 
                             <div>                           
                                <select   id="how_did_you_learn" name="how_did_you_learn" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={how_did_you_learn} >
                                    <option selected hidden>select answer</option>
                                    { dump.list_how_learn_about.map((name, index)  => <option value={name}> {name}</option>) }</select>
                                    {touched2.how_did_you_learn && errors.how_did_you_learn ? <div  className="alert alert-danger">  { errors.how_did_you_learn} </div> : null}
                                </div>
                        </div>
                        { how_did_you_learn === 'Other' && <div className="form-group">
                             <label className="form-label">Please Specify</label> 
                             <div   >                           
                                <input id="how_did_you_learn_specify" name="how_did_you_learn_specify" className="form-control"  onChange={handleChange2} type="text" value={how_did_you_learn_specify}/> 
                               
                             </div>
                        </div> } 
                        
                        <div className="form-group">
                             <label className="form-label" >Was the application form easy to access?</label><label className="form-label required" >*</label> 
                             <div>                           
                                <select   id="was_the_application_form_easy" name="was_the_application_form_easy" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={was_the_application_form_easy} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.was_the_application_form_easy && errors.was_the_application_form_easy ? <div  className="alert alert-danger">  { errors.was_the_application_form_easy} </div> : null}   
                             </div>
                        </div>
                        { was_the_application_form_easy === '0' && <div className="form-group">
                             <label className="form-label">If no, please explain</label> 
                             <div   >                           
                                <input id="was_the_application_easy_specify" name="was_the_application_easy_specify" className="form-control"  onChange={handleChange2} value={ was_the_application_easy_specify} type="text"/> 
                             </div>
                        </div> } 

                        
                        <div className="form-group">
                             <label className="form-label" >Was the opening date suitable?</label> <label className="form-label required" >*</label> 
                             <div>                           
                                <select   id="opening_date" name="opening_date" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={opening_date} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.opening_date && errors.opening_date ? <div  className="alert alert-danger">  { errors.opening_date} </div> : null}      
                             </div>
                        </div>
                        { opening_date === '0' && <div className="form-group">
                             <label className="form-label">If no, please explain</label> 
                             <div   >                           
                                <input id="opening_date_explain" name="opening_date_explain" className="form-control"  onChange={handleChange2} type="text" value={opening_date_explain}/> 
                             </div>
                             
                        </div> } 
                        <div className="form-group">
                             <label className="form-label" >Was the closing date suitable?</label> <label className="form-label required" >*</label> 
                             <div>                           
                                <select   id="closing_date" name="closing_date" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={closing_date} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.closing_date && errors.closing_date ? <div  className="alert alert-danger">  { errors.closing_date} </div> : null}         
                             </div>
                        </div>
                        { closing_date === '0' && <div className="form-group">
                             <label className="form-label">If no, please explain</label> 
                             <div>                           
                               <input id="closing_date_explain" name="closing_date_explain" className="form-control"  onChange={handleChange2} value={ closing_date_explain} type="text"/> 
                            </div>
                        </div> }  
                        <div className="form-group">
                             <label className="form-label" >Were the instructions clear?</label> <label className="form-label required" >*</label> 
                             <div>                           
                                <select   id="instructions_clear" name="instructions_clear" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={instructions_clear} >
                                    <option selected hidden>select answer</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                {touched2.instructions_clear && errors.instructions_clear ? <div  className="alert alert-danger">  { errors.instructions_clear} </div> : null}            
                             </div>
                        </div>
                        { instructions_clear === '0' && <div className="form-group">
                             <label className="form-label">If no, please explain</label> 
                             <div   >                           
                                <input id="instructions_clear_explain" name="instructions_clear_explain" className="form-control"  onChange={handleChange2} type="text" value={instructions_clear_explain}/> 
                            </div>
                        </div> }  
                        </div>
                        <input id="ser" type="button" value={forward}  className="btn-success form-control" fullWidth style={{marginTop: 15}}   onClick={() =>{if (noError(survey ,err)) {window.scrollTo(0, 0); setValues({...values, loading: 'loading'}); updateApplication(survey,7); fechApplicantData() }}}  />
                <div style={{ marginTop: 10}}>
                     <input id="serb" type="button" value={backward}  className="btn-success form-control" fullWidth     onClick={() => setTabValue(5)}  />
                </div>
            </div>     
    }



    const Reviewfrom = () => {
       
        return <div> 
                
                  
                <div> {Pledge()} </div>
                <div> Pledge by Applicant  <label className="form-label required" >*</label> </div>
                <Checkbox key="pl" id="pl" name="pl" onBlur={handleBlur} onChange={handleChange} value='1'   /> 

                 <label id="label_input_38_0" for="input_38_0"> I hereby confirm that I will comply with the rules and regulations pertaining to the SANZAF Bursary Programme. </label>
                <div style={{marginTop: 10}} >   
                        <input id="up2" type="button" value="Submit"  className="btn-success form-control" fullWidth  style={{marginTop: 20}}  onClick={() =>{ if (pl == '1') {window.scrollTo(0, 0);  setValues({ ...values, loading: 'submitting' }); submitApp()}}} />
                        
                     </div>   
                <div style={{marginTop: 10}} >
                        <input id="up2" type="button" value={backward}  className="btn-success form-control" fullWidth  style={{marginTop: 20}} onClick={() =>  setTabValue(6) }  />
                 </div>  
                           
            </div>
    }
    const Pledge = ()=>{
        return (
            <div className="scroll-dv form-label"> 
                  GENERAL DECLARATION  <br></br>
                1) I will devote my time to my studies as long as I am a member of the SANZAF Bursary Programme. <br></br>
                2) I will apply to other bursars for funding. <br></br> 3) I will send my examination results and fees statements to SANZAF every semester. <br></br>
                4) I will participate in the programmes and activities organised by SANZAF, e.g. youth camps, community outreach programmes, seminars, workshops, etc.<br></br>
                5) I will try to assist in the development of my community and country after I qualify. <br></br>
                6) Upon my graduation, I will participate in the SANZAF Graduates Association. <br></br>
                7) My parent(s) / guardian(s) / spouse are not in a financial position to support my education. <br></br>
                8) I understand that I am applying for a Zakah bursary. <br></br>
                9) I understand that the funding allocated is specifically towards my Tertiary fees. <br></br>
               10) I agree in the event that my fees are covered by another bursar, SANZAF will receive a refund. <br></br>
               11) All the information in this Application Form, including attachments and documents are correct. <br></br>
               12) I understand that this is only an application for a bursary and by submitting this application I am not assured and/or guaranteed of a bursary and cannot hold SANZAF and/or its partners/donors accountable for not granting me a bursary.<br></br>
               13) I authorise SANZAF to use my details, photograph and academic records for networking with other organisations and promoting the SANZAF Bursary programme. <br></br>
               14) Be rest assured that SANZAF is compliant with the Protection of Personal Information Act (POPIA). Your personal information as submitted is kept confidential and safe. <br></br>
               15) I have read and understood the POPIA Privacy Policy of SANZAF as found in the Link <a href="https://www.sanzaf.org.za/privacy-policy1/privacy-policy/viewdocument/56.html" target="_blank">https://www.sanzaf.org.za/privacy-policy1/privacy-policy/viewdocument/56.html</a> <br></br>
            </div>
        )
    }


 
    const submitRegesiteration = user => {     
        register({first_name,last_name,email,password}).then(data => {
           if (data.error) {
               setValues({ ...values, error: data.error, success: false, loading: false });
               alert(data.error)
           } else {        
               setValues({...values, first_name: '' ,last_name: '' ,email: '', re_email: '', password:'', re_password:'', error: '', success: true , loading: 'loading' }); 
               alert('Registration successful. Proceed to log in')
               history.push("/signin");                        
           }
       });
   };
   const submitApplication = (application,nextForm) => {
        registerApplication(application).then(data => {
        if (data.error) {
            if (data.error === "application already exist"){                
                localStorage.setItem('application_id', JSON.stringify(data['application_id']))
                localStorage.setItem('status', JSON.stringify(data['status_id'])) 
                setValues({ ...values, loading: false });        
                setTabValue(nextForm) 
            }
            else {
                alert(data.error)
            }
           //setValues({ ...values, error: data.error, success: false });
         
       } else {
         localStorage.setItem('application_id', JSON.stringify(data['application_id']))
         localStorage.setItem('status', JSON.stringify(data['status_id'])) 
         setValues({ ...values, loading: false });        
         setTabValue(nextForm)    
       }
   });
  };
  const submitApp = () => {     
    appsubmit().then(data => {
       if (data.error) {
           //setValues({ ...values, error: data.error, success: false });
           alert(data.error)
       } else { 
            history.push('applicantdashboard')                     
        }                        
    });
   };
   const updateApplicant = (update,nextForm) => {
        updateApplicantData(update).then(data => {
        if (data.error) {
            alert(data.error)
            //setValues({ ...values, error: data.error, success: false });
        } else {      
            //setValues({ ...values, error: false, loading: false, success: false });
            setValues({...values,error: false, loading: false, success: false, race: country && country !== "South Africa" ? "Foreign National": race});
            setTabValue(nextForm)
        }
    });
    }
    const updateApplication = (update,nextForm) => {
        updateApplicantationData(update).then(data => {
        if (data.error) {
            setValues({ ...values, error: data.error, success: false });
        } else {
                setValues({ ...values, loading: false });
                if (update === survey) {
                    setValues({ ...values, error: false, loading: false, success: false });
                    setTabValue(nextForm)
                }
                else {
                    setValues({ ...values, error: false, loading: false, success: false });
                    setTabValue(nextForm)
                }
            }
         });
    }
   const fileError = (file) =>{
        console.log(file)
        if (!values2[file]){
            alert(`select file for ${file}`)
            return true
        } 
        else {
            return false
        }
   }
   const _uploadFile =(file,index) =>{   
        //let _progressInfos = Object.keys(upload).map(key => ({ percentage: 0,fileName: upload[key].name }));
        setProgressInfos({...progressInfos, [file]: {percentage: 0}})
        progressInfosRef.current = {
        val: progressInfos,
        }
        const id =  loadFile(index,file,upload[file])
   }

   const loadFile = (index,fileName, file) => { 
    //let _progressInfos = [...progressInfosRef.current.val];
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    return uploadFiles(application_id,file,fileName, (event) => {
      let percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      //setProgressInfos({ val: _progressInfos });
      setProgressInfos({...progressInfos, [fileName]: {percentage: percentage}})
    })
      .then((res) => {
        setMessage((prevMessage) => ([
          ...prevMessage,
          file.name + "  uploaded successfully. " 
        ]));
        let _files = files 
        _files[fileName].uploaded =  true
        setValues2({...values2, files: _files})
        //setValues({ ...values, loading: false }); 
      })
      .catch(() => {
        ///_progressInfos[index].percentage = 0;
       // setProgressInfos({ val: _progressInfos });
       setProgressInfos({...progressInfos, [fileName]: {percentage: 0}})
        setMessage((prevMessage) => ([
          ...prevMessage,
          file.name + " failed to upload"
        ]));
      });
    }

    const _filesUploaded = () =>{
        let noEmpty = true
        for ( let key of Object.keys(files)) {
            if (!files[key].uploaded){
                alert(`Upload file for ${files[key].lable}`)
                noEmpty = false
                break
            }
        }
        return noEmpty
    }

    const submitFiles = files => {  
        let _progressInfos = Object.keys(files).map(key => ({ percentage: 0,fileName: files[key].name }));
        progressInfosRef.current = {
          val: _progressInfos,
        }
        const id =  sendFile(0,"id_document", files["id_document"].blob)
        //const acceptance =  sendFile(1,"acceptance_letter", upload["acceptance_letter"])
        const proof =  sendFile(1,"affidavit", files["affidavit"].blob)
        const recomm =  sendFile(2,"recommendation", files["recommendation"].blob)
        const motive =  sendFile(3,"motivation_letter", files["motivation_letter"].blob)
    }

    const sendFile = (index,fileName, file) => { 
        let _progressInfos = [...progressInfosRef.current.val];
        const applicant_id =  JSON.parse(localStorage.getItem('log')).user
        return uploadFiles(applicant_id,file,fileName, (event) => {
          _progressInfos[index].percentage = Math.round(
            (100 * event.loaded) / event.total
          );
          setProgressInfos({ val: _progressInfos });
        })
          .then((res) => {
            setMessage((prevMessage) => ([
              ...prevMessage,
              file.name + "  uploaded  successfully. " 
            ]));
            setValues({ ...values, loading: false }); 
          })
          .catch(() => {
            _progressInfos[index].percentage = 0;
            setProgressInfos({ val: _progressInfos });
    
            setMessage((prevMessage) => ([
              ...prevMessage,
              file.name + " failed to upload"
            ]));
          });
      }

    var completed = []
    const showNext = ()=>{
        return uploadCompleted
    }
    
    const ProgressBar = ({progress }) => {        
        if (progress == 100) {
            completed.push(progress.key)
        }
      
        if (completed.length == 4){
            setuploadCompleted(true)         
        }
      
      return  <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: `${progress.percentage}%`}} > {progress.percentage}%</div>
       </div>
    }
    const fechApplicantData = ()=> {
        fechData().then( data => {
             setData(data)
        })
    }

    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>{loading}...</h2>
            </div>
        );
    const showError = () => (
        error &&  <div  className="alert alert-danger">
                      {error}
                    </div> 
             
    )


    const  notEmpty = (obj) => {   
        if (applicantDetails)  {
            if (obj['motivation_letter'] == ""){
                alert(`Select File for motivational letter`)
                return false; 
            } 
            if (obj['affidavit'] == ""){
                alert(`Select File for proof of income`)
                return false;
            }
       
        } else {
            for (var key in obj) {
                if (obj[key] == ""){
                    alert(`Select File for ${key}`)
                    return false;
                }
            }
        }     
        return true;
    }
    const  notEmptyField = (obj) => {        
        for (var key in obj) {
            if (obj[key] == "" &&  !(['uni','cor'].includes(key))){
                alert(`Enter ${key}`)
                return false;
            }
        }
        return true;
    }
    const  notData = (data) => {      
        for (var key in data) {
            if (!data[key] == ""){
                return false;
            }
        }
        return true;
    }
    const  isError = (data, err) => {
        for (var key in err) {
            if (err[key])
                return err[key];
        }
        return false;
    }
    const noError = (data, err )=>{
        if (notData(data)){
            alert('Complete the required fields')
            return false
        }
        let error = isError(data,err)
        if (error) {
            alert(error)
            //setValues({ ...values, error: isError(err) , success: false });
            return  false       
        }
        setValues({ ...values, error: ''});
        //setValues({...values, error: false} );
        return true;
        //setValues({ ...values, error: false  });

    }
    
    const validateForm = (form,err) => {
        if (notData(form)){
            alert('Complete the required fields')
            return false
        }
        let error = isError(data,err)
        if (error) {
            alert(error)
            //setValues({ ...values, error: isError(err) , success: false });
            return  false       
        }
        setValues({ ...values, error: ''});
        //setValues({...values, error: false} );
        return true;
        //setValues({ ...values, error: false  });
    }
 
    const TabPanel =(props)=> {
      const { children, value, index, ...other } = props;
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }
   
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };
   
 const a11yProps =(index) =>{
    return {
        'id': `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const TabsContainer = ()=> {    
    const [tabValue1,setTabValue1] = useState(0) 
      const handleTapChange = (event, newValue) => {
        setTabValue1(newValue)
      };
  
      
    

      return (
        <Box sx={{ width: '100%' }}>
           <Container style={{ width: 300 }}>{ showLoading()}</Container>          
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} handleChange={handleTapChange} aria-label="basic tabs example">
              <Tab  label={`BASIC INFORMATION`} {...a11yProps(0)}/>
              <Tab  label={`SOCIAL INFORMATION`} {...a11yProps(1)}/>
              <Tab  label={`UNIVERSITY`} {...a11yProps(2)}/>
              <Tab  label={`Upload Documents`} {...a11yProps(3)}/>
              <Tab  label={`INCOME`} {...a11yProps(4)}/>
              <Tab  label={`EXPENSES`} {...a11yProps(5)}/>        
              <Tab  label={`SURVEY`} {...a11yProps(6)}/>
              <Tab  label={`SUBMIT`} {...a11yProps(7)}/>
            </Tabs>
          </Box>
          {tabValue == 0 && Basicinfoform()} 
          {tabValue == 1 && Socioinfoform()} 
          {tabValue == 2 && InstitutionForm()} 
          {tabValue == 3 && UploadForm()} 
          {tabValue == 4 && IncomeForm()} 
          {tabValue == 5 && ExpenseForm()} 
          {tabValue == 6 && SurveyForm()} 
          {tabValue == 7 && Reviewfrom()} 
        </Box>
      );
    } 

  
    return (
            <Layout >
                <Container style={{marginTop: 80}}>                 
                  { TabsContainer()}
                </Container>
            </Layout>
  
    );
};

export default Signup;
