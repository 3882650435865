import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Signup from './user/Signup';
import Signin from './user/Signin';
import ApplicantDashboard from './user/ApplicantDashboard'
import Profile from './user/Profile';
import AdminDashboard from './user/AdminDashboard'
import UploadDocs from './user/UploadDocs' 
import UploadReqstedDocuments from './user/UploadRequestedDcocuments'
import UploadResults from './user/UploadResults'   
import UpdateApplicant from './user/UpdateApplicant'   
import Home from './core/Home';
import PasswordChange from './user/PasswordChange';
import PasswordReset from './user/PasswordReset';
import HowToApply from './user/HowToApply';
import PrivateRoute from './util/PrivateRoute'
import AdminRoute from './util/AdminRoute'  
import LateApplicantRoute from './util/LateApplicantRoute' 
import Statistics from './user/Statistics' 
import ApplicationManager from './user/ApplicationManager'
import LateApplication from './user/LateApplication'   
import SignupLate from './user/SignupLate';
import Register from './user/Register';


const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />        
                <Route path="/signin" exact component={Signin} />
                <Route path="/signup" exact component={Signup} />
                <Route path="/register" exact component={Register} />
                {/*<Route path="/latesignup" exact component={SignupLate} /> */}             
                <Route path="/howtoapply" exact component={HowToApply} />
                <Route path="/passwordchange" exact component={PasswordChange} />
                <PrivateRoute path="/applicantdashboard" exact component={ApplicantDashboard} />
                <PrivateRoute path="/profile" exact component={Profile} />
                <AdminRoute path="/admindashboard" exact component={AdminDashboard} />
                <AdminRoute path="/updateApplicant" exact component={UpdateApplicant} />
                <AdminRoute path="/ApplicationManager" exact component={ApplicationManager} />
                <Route path="/passwordreset/:token" exact component={PasswordReset} />
                <Route path="/docsupload/:token" exact component={UploadDocs} />
                <Route path="/uploaddocs/:id" exact component={UploadReqstedDocuments} />
                <Route path="/uploadresults/:token" exact component={UploadResults} />                
                <Route path="/application/:token" exact component={LateApplication} />
                <AdminRoute path="/stat" exact component={Statistics} />
                <LateApplicantRoute path="/bursauryform" exact component={Signup} />
                
                
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;




