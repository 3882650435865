import React, { useState , useEffect, useRef, } from 'react';
import { Link, Redirect, useHistory , useLocation, Redirect as Red} from 'react-router-dom';
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import { getResultsData, applicationreport, downloadFileAdmin, downloadFormChecklist, downloadSupportingDoc,
	downloadfilefolder, sendpledgeletter, sendAcceptDecline, sendDocsCompleted,sendresults, 
	sendComment,confirmResult } from '../util';
import Review from './Review'
import AcceptDecline from './AcceptDecline'
import Payment from './Payment'
import Result from './Result'
import Layout from '../core/Layout';
import Container from '@material-ui/core/Container'
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UpdateApplicant from './UpdateApplicant'
import * as FileSaver from "file-saver";
import Typography from '@material-ui/core/Typography'; 
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {jsPDF} from 'jspdf'

import axios from 'axios' 


const ApplicationManager = () => {
	const application =  JSON.parse(localStorage.getItem('applicationData')); 
	const [progressInfos, setProgressInfos] = useState({ percentage: 0 });
	const [redirect,setRedirect] = useState('home')
	const [files,setFiles] = useState(  {'id_document':{'lable':'ID Document', 'blob':'', 'path_list': application?.id_document_list, 'path': application?.id_document,  'url':'', uploaded:'','name': 'id_document'} , 
		'recommendation':{'lable':'Testimonial Letter', 'blob':'', 'path_list': application?.recommendation_list, 'path': application?.recommendation,'url':'', 'uploaded':'', 'name': 'recommendation'},
		'motivation_letter':{'lable':'Motivational Letter', 'blob':'','path_list': application?.motivation_letter_list, 'path': application?.motivation_letter,'url':'', 'uploaded':'', 'name': 'motivation_letter'},
		'affidavit':{'lable':'Proof of Income', 'blob':'','path_list': application?.affidavit_list, 'path': application?.affidavit, 'url':'','uploaded':'', 'name': 'affidavit'}},
    )
	const [resultsData,setResultsData] = useState()
	const history = useHistory()
	var fileURL
	const validationSchema = Yup.object({
        email: Yup.string().required('Enter your Eamil'),
        comment: Yup.string().required('Password is Required')
    })

	const {handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            comment:'',
            email: '',
            cc:'',
            subject: '',
            body:'',
            loading: false,
            redirectToReferrer: false
        },
        validationSchema
    });
	const { comment, cc, subject, body, loading, error, redirectToReferrer } = values
	useEffect(  () =>{
		let mounted = true
		getResultsData(application.application_id).then(data => {	
			if (mounted){	
				if (data){	
					setResultsData(data)
				}
			}
		})
		return ()=> {mounted = false}       
    },[]);

	Number.prototype.format = function(n, x, s, c) {
		var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
		num = this.toFixed(Math.max(0, ~~n));	
		return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
	};

    
	if (application != undefined){	
		var { 
			"First Name": first_name,"Last Name": last_name, "Email": email,"Address": address, "Suburb": suburb, 
              "City": city, "Province": province, "Area Code": area_code, "Country of Birth": country, 
              "First Language": first_language,"Second Language": second_language, "ID Number": id_number,
              "Date of Birth": date_of_birth,"Cell Phone": cell_phone, "Alternative Phone": alternative_phone,
               "Alternative Email": alternative_email,
               
               "Gender": gender,"Race": race, "Religion": religion, "Employment Status": employment_status, 
              "Marital Status": marital_status, "Number of Siblings": number_of_siblings,
              "Spouse Name": spouse_name, "Spouse Contact Number": spouse_contact, 
              "Spouse Employment Status": spouse_employment,  "Reference Name": reference_name, 
              "Reference Email":reference_email, "Reference Cell Phone": reference_cell, 
              "Relationship with Reference": reference_relationship, 
              
               "Application Type": application_type, "Application Year": application_year, 
                "Student Number": student_number,"University": university,
                "Course": course, "Study Level": unilevel,  "Duration of Study": duration_of_study, "Year of Study": year_of_study, 
                "Completion Year": completion_year,   "Required Amount for Tuition": amount_for_tuition, "Required Amount for Travelling": amount_for_transport,
                "Required Amount for Books": amount_for_books,"Required Amount for Accomodation": amount_for_accommodation,                
                "Father Monthly Salary": father_monthly_salary, "Mother Monthly Salary": mother_monthly_salary,"Spouse Monthly Salary": spouse_monthly_salary,
                "Own Monthly Salary": own_monthly_salary, "Investments": investments, "Other Source of Income": other_income, "Total Income": total_income,
                "Rent and Bond": rent_bond_expense,"Rates": rates, "Water Electricity": water_electricity, "Groceries": grocery_milk_bread, 
                "Transport": transport_petrol, "Telephone": telephone, "Medical Aid": medical_aid, "Insurance":insurance, 
                "Other Expense": other_expense, "Total Expense": total_expense,
                "The Course you Wish to Study": the_course_you_wish, "Academic Achievements": academic_achievements, 
                "Community Involvement": community_involvement, "Tell us why you should receive a SANZAF Zakah Bursary": competing, "How Did You Learn About SANZAF Bursary": how_did_you_learn, 
                "Parents Assisted by SANZAF": ssisted_by_sanzaf, "Was the application form easy to access": was_the_application_form_easy, 
                "Was the opening date suitable": opening_date, "Was the closing date suitable": closing_date,
                "Were the instructions clear": instructions_clear,
                "Would you like to participate  Mentoring Youth (MY) programmes and activities":  mentor_participation,
                "Would you like to volunteer in our Events and Campaigns":  volunteer_participation,
                "Would you like to participate in our Seminars and Workshops": seminars_participation ,
                "SANZAF requires MY facilitators for mainly English and Mathematics": tutor_participation,           
                "Previously completed a course through the SANZAF Vocational": assisted_by_vocational_programme,
                "The name of course and year completed": assisted_by_vocational_programme_specify,
                "Any of parents been assisted by SANZAF": assisted_by_sanzaf,
                "Name and Surname of your parent": assisted_by_sanzaf_specify,
		} = application

		var  basicinfo  = {
			"First Name": first_name,"Last Name": last_name, "Email": email,"Address": address, "Suburb": suburb, 
			"City": city, "Province": province, "Area Code": area_code, "Country of Birth": country, 
			"First Language": first_language,"Second Language": second_language, "ID Number": id_number,
			"Date of Birth": date_of_birth,"Cell Phone": cell_phone, "Alternative Phone": alternative_phone,
			"Alternative Email": alternative_email
		}
		var  socioinfo  = { 
			"Gender": gender,"Race": race, "Religion": religion, "Employment Status": employment_status, 
			"Marital Status": marital_status, "Number of Siblings": number_of_siblings,
			"Spouse Name": spouse_name, "Spouse Contact Number": spouse_contact, 
			"Spouse Employment Status": spouse_employment,  "Reference Name": reference_name, 
			"Reference Email":reference_email, "Reference Cell Phone": reference_cell, 
			"Relationship with Reference": reference_relationship, 
		}
		var inistitution  = {
		    "Application Type": application_type, "Application Year": application_year, 
			"Student Number": student_number,"University": university,
			"Course": course, "Study Level": unilevel, "Duration of Study": duration_of_study, "Year of Study": year_of_study, 
			"Completion Year": completion_year,   "Required Amount for Tuition": amount_for_tuition,
			"Required Amount for Books": amount_for_books,"Required Amount for Accomodation": amount_for_accommodation,
			"Required Amount for Travelling": amount_for_transport,
	    }
		var income  =  { 
			"Father Monthly Salary": father_monthly_salary, "Mother Monthly Salary": mother_monthly_salary,"Spouse Monthly Salary": spouse_monthly_salary,
			"Own Monthly Salary": own_monthly_salary, "Investments": investments, "Other Source of Income": other_income, "Total Income": total_income,
		}

		var expense  =  { 
			"Rent and Bond": rent_bond_expense,"Rates": rates, "Water Electricity": water_electricity, "Groceries": grocery_milk_bread, 
			"Transport": transport_petrol, "Telephone": telephone, "Medical Aid": medical_aid, "Insurance":insurance, 
			"Other Expense": other_expense, "Total Expense": total_expense,
		}

		var survey  =  {
			"The Course you Wish to Study": the_course_you_wish, "Academic Achievements": academic_achievements, 
			"Community Involvement": community_involvement, "Tell us why you should receive a SANZAF Zakah Bursary": competing, "How Did You Learn About SANZAF Bursary": how_did_you_learn, 
			"Parents Assisted by SANZAF": ssisted_by_sanzaf, "Was the application form easy to access": was_the_application_form_easy, 
			"Was the opening date suitable": opening_date, "Was the closing date suitable": closing_date,
			"Were the instructions clear": instructions_clear	
		}
		var survey2 = {
			
			"Would you like to participate  Mentoring Youth (MY) programmes and activities":  mentor_participation,
			"Would you like to volunteer in our Events and Campaigns":  volunteer_participation,
			"Would you like to participate in our Seminars and Workshops": seminars_participation ,
			"SANZAF requires MY facilitators for mainly English and Mathematics": tutor_participation,           
			"Previously completed a course through the SANZAF Vocational": assisted_by_vocational_programme,
			"The name of course and year completed": assisted_by_vocational_programme_specify,
			"Any of parents been assisted by SANZAF": assisted_by_sanzaf,
			"Name and Surname of your parent": assisted_by_sanzaf_specify,
		}
		var upload3  = { 
			"ID Document": 'id_document' , "Proof of Income": 'affidavit' , 
			"Testimonial Letter": 'recommendation' ,"Motivational Letter": 'motivation_letter', "University Acceptance Letter": 'acceptance_letter', "Result": 'result' ,
			"Proof of Registration": 'registration', "Fees Statement": 'fees_statement', "Signed Consent Form": 'consent_form'	} 
		var checkfile = {
			"Approved & Declined Checklist": 'checklist', 'Application Form': 'applicationform'
		}
    }
	
	const  acordionClicked = (documentName) => {
        downloadFileAdmin(application.application_id,documentName).then(res => {
            if ( res.data instanceof Blob){
                //let _files = {...files}
               // _files[documentName].uploaded =  false
                fileURL = window.URL.createObjectURL(res.data)
                let embedId  = `$embed_${documentName}`				
                document.getElementById(embedId).src = fileURL
                //setValues2({...values2, files: _files})		
            }                  
        });		
    }
    
    const  displayDoc = (documentName,documentType) => {
			downloadSupportingDoc(application.application_id,documentName,documentType).then(res => {
				
				
				if ( res.data instanceof Blob){
					const plFile = new Blob([res.data],{type : 'image/png'})
					console.log( res.data)
					

					let fURL = window.URL.createObjectURL(plFile)
					console.log(fURL)
					let embedId  = `embed_${documentName}`	
					document.getElementById(embedId).src = fURL
					//setValues2({...values2, files: _files})		
				}                  
			});		
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div 
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      
       function BasicTabs({path,documentName}) {
	
		
        const [value, setValue] = React.useState(0);
      
        const handleChange = (event, newValue) => {
             setValue(newValue);
        };
      
        return (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					{ path && path.length > 0 && path.map( (item,index) => ( <Tab  onClick={()=>displayDoc(item,documentName)} label={`Doc${index + 1}`} {...a11yProps(index)} />))}                             
				</Tabs>
            </Box>
            { path.map( (item,index) => (<TabPanel value={value} index={index}>
				<iframe src=""  id={`embed_${item}`} width="1200" />
				<Link onClick={()=> { let doc = document.getElementById(`embed_${item}`).contentWindow; doc.focus(); doc.print(); }}>print</Link>
            </TabPanel>)) }
          </Box>
        );
      }
	const DisplayPDF = (documentName)=>{	
        let embedId  = `$embed_${documentName}`
  		return (<embed src=""  id={embedId} width="1000" />)
	}
    const FileReport1 = (lable, documentName) => {
		return ( 
				<Accordion  >		
						<AccordionSummary  on expandIcon={<ExpandMoreIcon onClick={()=>acordionClicked(documentName)}/>}> <label className="form-label">{lable}</label>
						</AccordionSummary>
						<AccordionDetail>                       
                        <div>														
                            {DisplayPDF(documentName)}                               
                        </div>
						</AccordionDetail>	
                        <IconButton onClick={() =>{// let _files = files
                                                   // _files[documentName].path =  ''
                                                    //setValues2({...values2, files: _files})
                                                    }} color="primary" component="span"><EditIcon/><label>Add Document</label></IconButton> 
				</Accordion >
		)     
	}
	
    const FilesReport = ({lable, documentName,path_list}) => {
		return ( 
				<Accordion  >		
						<AccordionSummary  on expandIcon={<ExpandMoreIcon />}> <label className="form-label">{lable}</label>
						</AccordionSummary>
						<AccordionDetail>
                         <BasicTabs path={path_list} documentName={documentName}/>
						 
						</AccordionDetail>	                         		
				</Accordion >
		)     
	}
    const _confirmResult = (resultData)=>{
		confirmResult(resultData).then(data =>
			console.log(data)
		)
	}
	const ResultFileReport = ({lable, documentName,path_list}) => {
		let result = {}
        if (resultsData != undefined ){
			result = resultsData.filter(item => item['period'] === documentName)[0]
		} 
		
		
		return ( 
				<Accordion  >		
						<AccordionSummary  on expandIcon={<ExpandMoreIcon />}> <label className="form-label">{lable}</label>
						</AccordionSummary>
						<AccordionDetail>
						<div>	
                         <BasicTabs path={path_list} documentName={documentName}/>
						 { resultsData != undefined && result != undefined &&
						 <div>
							<table >
							
								<tr>
								{result != undefined && result.subjects.map(subject => <div> <th> { subject['name']} </th>  <td> {subject['mark']}</td>  </div>)} 
								<div> <th> Average</th>  <td> {( parseFloat(result.average)).format(2)} </td>  </div>   
								<div> <th> Pass/Fail</th>  <td> {result.pass_fail} </td>  </div>  
								<div> <th> Confirmed?</th>  <td> {result.confirmed} </td>  </div>                     
								</tr>  
								
							</table>  
							
							   <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4 ">
                        <label className="form-label">Pass/Fail?</label>
                        <div>
                            <select id="pass_fail" name="pass_fail"   type="option" className="form-select"  style={{width:150}}  > 
							<option  selected hidden >select result</option>
							<option value="pass">pass</option>
							<option value="fail">fail</option>
                            </select>
                            <input  type='button' value="Confirm" 
							 style={{marginTop: 10, width:150}} className="btn-success form-control" 
							 onClick={()=> _confirmResult({application_id: application.application_id,
														 period: documentName,	
							 							 pass_fail: document.getElementById('pass_fail').value })}/>     
                        </div>
                    </div>    
						</div>}		
						</div>
						</AccordionDetail>	                         		
				</Accordion >
		)     
	}



	const iframeResizer = (id) => {
		var doc = document.getElementById(id).contentWindow.document;
		var body_ = doc.body;
		var html_ = doc.documentElement;
		var height = Math.max( body_.scrollHeight, body_.offsetHeight, html_.clientHeight,     html_.scrollHeight, html_.offsetHeight );
		var width  = Math.max( body_.scrollWidth, body_.offsetWidth, html_.clientWidth, html_.scrollWidth, html_.offsetWidth );
		document.getElementById(id).height = height;
		document.getElementById(id).width = width;		
	}
	const DisplayImage = ({id})=>{
		return (<iframe src="" id={id}  onLoad={() => iframeResizer(id)} alt="Acceptance Letter" />)	
	}
	const DisplayPDF1 = ({id})=>{	
		return (<iframe src="" id={id} alt="Acceptance Letter" width="1500" height="1000"/>)
	}
    
	const ReviewPage = () => {
		  return (
			  <div> 
				<Container maxWidth="lg">
					<Report lable="Basic Information"  data={basicinfo} stage={{a:0, b: 0 }} />
					<Report lable="Social Information" data= {socioinfo} stage={{a:1, b: 0 }} />
					<Report lable="University"  data={inistitution} stage={{a:2, b: 0 }} /> 
					<Report lable="Income"  data= {income} stage={{a:2, b: 1 }}/>
					<Report lable="Expenses" data= {expense}  stage={{a:2, b: 2 }}/>
					<Report lable="Survey1"  data= {survey} stage={{a:2, b: 3 }}/> 
					<Report lable="Survey2"  data= {survey2} stage={{a:2, b: 3}}/> 
					<ResultFileReport lable={"Final year result"}  documentName= {"finalyear_transcript"} path_list={application.finalyear_transcript_list} 
					result={{ }}/>
					<ResultFileReport lable={"Mid year result"}  documentName= {"midyear_transcript"} path_list={application.midyear_transcript_list} 
					result={{ }}/>
					<FilesReport lable={"ID Document"}  documentName= {"id_document"} path_list={application.id_document_list} />
					<FilesReport lable={"Testimonial Letter"}  documentName= {"recommendation"} path_list={application.recommendation_letter_list}/>
					<FilesReport lable={"Motivational"}  documentName= {"motivation_letter"} path_list={application.motivation_letter_list}/>
					<FilesReport lable={"Proof of Income"}  documentName= {"affidavit"} path_list={application.affidavit_list}/>
					<FilesReport lable={"Proof of registration"}  documentName= {"registration"} path_list={application.registration_list}/>
					<FilesReport lable={"Fees Statement"}  documentName= {"fees_statement"} path_list={application.fees_statement_list}/>
					<FilesReport lable={"Consent Form"}  documentName= {"consent_form"} path_list={application.consent_form_list}/>
					<FilesReport lable={"Acceptance Letter"}  documentName= {"acceptance_letter"} path_list={application.acceptance_letter_list}/>

				</Container>	
				<div style={{marginTop: 40}} >
				    <label className="text-muted">{  }
					</label>  
				 </div>              
			  </div>
		  ) 
	}

	const Report = ({lable, data, stage}) => {
		return ( 
				<div>                                     
					<Accordion>
						<AccordionSummary on expandIcon={<ExpandMoreIcon />}> {lable}
						</AccordionSummary>
						<AccordionDetail>          
						<div>
		              		<table >
                              <tr> 
                              {Object.keys(data).map( key =>                                   
									<th>{key}</th>	)}
								</tr>
                                <tr>
                                {  Object.keys(data).map( key =>                                   
                                  <td>{data[key]}</td>	)}
								</tr>
								</table>
							<IconButton onClick={ ()=> history.push("/updateapplicant",{ applicantData: application, stage: stage }) } color="primary" component="span"><EditIcon /></IconButton>
							</div>	
						</AccordionDetail>
					</Accordion>
				</div>
		   )     
    } 
	
    const ReportSurevy = ({lable, data}) => {
		return ( 
				<div>                                     
					<Accordion>
						<AccordionSummary on expandIcon={<ExpandMoreIcon />}> {lable}
						</AccordionSummary>
						<AccordionDetail>          
						<div>
							<table >
							{ data != undefined &&
								<tr>
								{ Object.keys(data).map(value => <div> <th> {value} </th>  <td> {data[value]}</td>  </div>)}                        
								</tr>  }      
							</table>                
						</div>
						</AccordionDetail>
					</Accordion>
				</div>
		   )     
    } 

    const FileReport = ({lable, data}) => {
		let fileType 
		return ( 
				<Accordion onClick={ ()=>{ downloadFileAdmin(application.application_id,data).then(res => {
					const blobfile = new Blob([res.data])				
					fileType = res.data.type			
					fileURL = window.URL.createObjectURL(res.data);				
					document.getElementById(data).src = fileURL						
					});		
					}} >		
						<AccordionSummary on expandIcon={<ExpandMoreIcon />}> {lable}
						</AccordionSummary>
						<AccordionDetail> 																
							 <DisplayPDF id={data}/> 
						</AccordionDetail>				
				</Accordion >
		   )     
	}

  
    const downloadFormChecklistFile = (file) => {
		downloadFormChecklist(application.application_id,file).then(res => {
			const blobfile = new Blob([res.data])
			FileSaver.saveAs(blobfile, `${application["First Name"]} ${application["Last Name"]}.docx`)	
		});			
	}

	const downloadFilesFolder = () => {
		downloadfilefolder(application.applicant_id).then(res => {
					const blobfile = new Blob([res.data])
					FileSaver.saveAs(blobfile, `${application["First Name"]}_${application["Last Name"]}.zip`)	
							
					});		
					
	}
	const saveComment = (comment, app)=> {
        //setValues({ ...values, error: false, loading: true });
        sendComment( comment, app ).then(data => {
            if (data.error) {
                console.log(data.error)
            } else {
				console.log('comment saved')            

            }
        });
    }
    


	const SendDodcComplete = () => {	
		const [open, setOpen] = React.useState(false);
		const handleClickOpen = () => {
			setOpen(true);
		  };
		
		  const handleClose = () => {
			setOpen(false);
		  }
	  
		return (
		  <div>
			<button  onClick={handleClickOpen}>
			Documents completed
			</button>
			<Dialog
			  open={open}
			  onClose={handleClose}
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description"
			>
			  <DialogTitle id="alert-dialog-title">
				{"SANZAF bursary"}
			  </DialogTitle>
			  <DialogContent>
				<DialogContentText id="alert-dialog-description" className="form-label">
				  <p className="form-label"> {application['First Name']} {application['Last Name']} Documents completed?</p>
				</DialogContentText>
			  </DialogContent>
			  <DialogActions>
				<Button onClick={handleClose}>No</Button>
				<Button onClick={() => { handleClose();   
								sendCompleted( { applicant_id: application.applicant_id, application_id: application.application_id,status: 0 });
								
			     setValues({...values, loading: true });
				
				 }} autoFocus>
				  Yes
				</Button>
			  </DialogActions>
			</Dialog>
		  </div>
		);
	  }


	const SendDecline = () => {
	
		const [open, setOpen] = React.useState(false);
		const handleClickOpen = () => {
			setOpen(true);
		  };
		
		  const handleClose = () => {
			setOpen(false);
		  }
	  
		return (
		  <div>
			<button  onClick={handleClickOpen}>
			Send Decline Letter
			</button>
			<Dialog
			  open={open}
			  onClose={handleClose}
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description"
			>
			  <DialogTitle id="alert-dialog-title">
				{"SANZAF burasry"}
			  </DialogTitle>
			  <DialogContent>
				<DialogContentText id="alert-dialog-description" className="form-label">
				  <p className="form-label">Do you want to send decline letter to {application['First Name']} {application['Last Name']}?</p>
				</DialogContentText>
			  </DialogContent>
			  <DialogActions>
				<Button onClick={handleClose}>No</Button>
				<Button onClick={() => { handleClose(); sendEmailStatus( { applicant_id: application.applicant_id, application_id: application.application_id,status: 0 });
			     setValues({...values, loading: true });
				
				 }} autoFocus>
				  Yes
				</Button>
			  </DialogActions>
			</Dialog>
		  </div>
		);
	  }
		  
	  const SendAcceptance = () => {
		
		const [open, setOpen] = React.useState(false);
		const handleClickOpen = () => {
			setOpen(true);
		  };
		
		  const handleClose = () => {
			setOpen(false);
		  }
	  
		return (
		  <div>
			<button  onClick={handleClickOpen}>
			Send Acceptance Letter
			</button>
			<Dialog
			  open={open}
			  onClose={handleClose}
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description"
			>
			  <DialogTitle id="alert-dialog-title">
				{"SANZAF bursary"}
			  </DialogTitle>
			  <DialogContent>
				<DialogContentText id="alert-dialog-description">
				<p className="form-label"> Do you want to send acceptance letter to {application['First Name']} {application['Last Name']}?</p>
				</DialogContentText>
			  </DialogContent>
			  <DialogActions>
				<Button onClick={handleClose}>No</Button>
				<Button onClick={()=> {handleClose(); sendEmailStatus( { applicant_id: application.applicant_id, application_id: application.application_id, status: 1  })
			              setValues({...values, loading: true });  
						   }} autoFocus>
				  Yes
				</Button>
			  </DialogActions>
			</Dialog>
		  </div>
		);
	  }
	  const SendPledge = () => {
		
		const [open, setOpen] = React.useState(false);
		const handleClickOpen = () => {
			setOpen(true);
		  };
		
		  const handleClose = () => {
			setOpen(false);
		  }
	  
		return (
		  <div>
			<button  onClick={handleClickOpen}>
			Send Pledge Letter
			</button>
			<Dialog
			  open={open}
			  onClose={handleClose}
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description"
			>
			  <DialogTitle id="alert-dialog-title">
				{"SANZAF bursary"}
			  </DialogTitle>
			  <DialogContent>
				<DialogContentText id="alert-dialog-description">
				 <p className="form-label">Do you want to send Pledge letter to {application['First Name']} {application['Last Name']}?</p>
				</DialogContentText>
			  </DialogContent>
			  <DialogActions>
				<Button onClick={handleClose}>No</Button>
				<Button onClick={()=> {handleClose(); sendletter( {application_id: application.application_id,  applicant_id: application.applicant_id});
			                          setValues({...values, loading: true });
									 }} autoFocus>
				  Yes
				</Button>
			  </DialogActions>
			</Dialog>
		  </div>
		);
	  }
	  const Results = () => {	
		const [open, setOpen] = React.useState(false);
		const handleClickOpen = () => {
			setOpen(true);
		  };
		
		  const handleClose = () => {
			setOpen(false);
		  }
	  
		return (
		  <div>
			<button  onClick={handleClickOpen}>
			Results
			</button>
			<Dialog
			  open={open}
			  onClose={handleClose}
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description"
			>
			  <DialogTitle id="alert-dialog-title">
				{"SANZAF bursary"}
			  </DialogTitle>
			  <DialogContent>
				<DialogContentText id="alert-dialog-description" className="form-label">
				  <p className="form-label"> result {application['First Name']} {application['Last Name']} pass or fail?</p>
				</DialogContentText>
			  </DialogContent>
			  <DialogActions>
				<Button onClick={ () => { 
					            handleClose();
								sendpassfail( {  application_id: application.application_id, status: 1 });
								setValues({...values, loading: true });
								}}>Pass</Button>
				<Button onClick={() => {
					            handleClose();   
								sendpassfail( { application_id: application.application_id, status: 0 });								
			                    setValues({...values, loading: true });
				
				 }} >
				  fail
				</Button>
			  </DialogActions>
			</Dialog>
		  </div>
		);
	}

   	const RedirectPage = () => {
		switch(redirect){
			case "home":
				return <HomeManager/>
			case "review":
				return <Review applicant={ application }/>
			case "acceptdecline":
				return <AcceptDecline applicant={ application}/>
			case "payment":
				return <Payment applicant={ application}/>
			case "documents":
				return <Result applicant={ application}/>
			case "update":
				return <UpdateApplicant applicantData={ application}/>
		}
    }  
  	const ToolBar = ()=>{
		return (
			<Container>
			    <button onClick={()=> setRedirect('review')}>Review</button>
				<button onClick={()=> setRedirect('acceptdecline')} >Accept and Decline</button>
				<button  onClick={()=> downloadFormChecklistFile('report')}>Download Application Form</button>
				<button  onClick={()=> downloadFormChecklistFile('checklist')}>Download Checklist</button>
				{  application.accept_decline == "1" && <button  onClick={()=> setRedirect('payment') }>Payment</button> }
				<button  onClick={()=> setRedirect('update') }>Update Applicant Data</button>
				<button  onClick={()=> downloadFilesFolder() }>Download Files</button>
				{/*<button  onClick={()=> setRedirect('documents') }>Request Documents</button>*/}
				{ application.accept_decline == "1" && <SendPledge/>}
				{ application.accept_decline == "1" && <SendAcceptance/>}   
				{ application.accept_decline == "0" && <SendDecline/>} 
				{ application && <SendDodcComplete/>}
				{ application && <Results/>}
				<Container maxWidth="xs">
                        {showLoading()}   
                </Container>
		    </Container>	
		)
	}

	const sendpassfail = (status)=> {
        //setValues({ ...values, error: false, loading: true });
        sendresults( status ).then(data => {
            if (data.error) {
                alert(data.error)
            } 
			else {
				setValues({...values, loading: false });
				alert("Result captured succesfully") 			
            }
        });
	
    }

   
	const sendCompleted = (status)=> {
        //setValues({ ...values, error: false, loading: true });
        sendDocsCompleted( status ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
			setValues({...values, loading: false });			
            
         }
        });	
    }
	
	const sendEmailStatus = (status)=> {
        //setValues({ ...values, error: false, loading: true });
        sendAcceptDecline( status ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
			setValues({...values, loading: false });			
            alert(status.status == '1' ? `Acceptance letter sent to ${application['First Name']} ${application['Last Name']}`: `Decline letter sent to ${application['First Name']} ${application['Last Name']}`)
         }
        });
	}
    const sendletter = (app)=> {
        //setValues({ ...values, error: false, loading: true });
        sendpledgeletter(app).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
                setValues({...values, loading: false });
				alert(`Pledge letter sent to ${application['First Name']} ${application['Last Name']}`)             
            }
        });
    }
    const showLoading = () =>
		loading && (
			<div className="alert alert-info">
				<h2>Sending...</h2>
			</div>
    );
	const HomeManager = ()=>{
		return (
			<div  >
				<ToolBar/>		
				<ReviewPage/>  
			</div>
		)
	}
  	return (
		<Layout>
			<button onClick={ ()=>  history.goBack() }>Back to Dashboard main page</button>
		  	<RedirectPage/>
		</Layout>	
	);
}
export default ApplicationManager;