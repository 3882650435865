import React, { useState ,useRef, useEffect } from 'react';
import { Link, withRouter , useHistory, useLocation} from "react-router-dom";
import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {Input} from 'reactstrap'
import Layout from '../core/Layout';

import { isAuthenticated, register ,registerApplication, updateApplicantData, updateApplicantDataAdmin, 
         updateApplicantationData, updateApplicantationDataAdmin, appsubmit, getApplication, getApplicant,fechData, 
         sendconfirmation, uploadFiles ,downloadFile, applicationreport, getLookUp
        } from '../util';

import * as dump  from '../dump'

import * as Yup from 'yup';

import axios from 'axios';
import { file } from '@babel/types';




const UpdateApplicant = () => {
    const location = useLocation();
    const applicantData = location.state.applicantData
    const init = location.state.stage
    
    

    const FILE_SIZE = 2097152;
    const SUPPORTED_FORMATS = [
      "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/png"
    ];
    var fileURL

    const forward = "SAVE and CONTINUE" 
    const backward = "BACK "
    const  validationSchema = Yup.object({
        tcchecked: Yup.string().required('Check Ts&Cs to continue'),
        tc: Yup.string().required('Check Ts&Cs to continue'),
        first_name: Yup.string().required('Enter first name'),
        last_name: Yup.string().required('Enter last name'),
        email: Yup.string().email('Invalid email').required('Enter email'),
        re_email: Yup.string().required('Confirm your email').oneOf([Yup.ref('email'), null], 'Emails must match'),
        password: Yup.string().required('Enter password').matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!&@#$]{8,}$/, 'Use 8 or more characters with a mix of letters and numbers'),
        re_password: Yup.string().required('Confirm password').oneOf([Yup.ref('password'), null], 'Passwords must match'),
        alternative_email:  Yup.string().email('Invalid email').required('Enter alternative email'),
        cell_phone: Yup.string().required("Enter cell phone").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,'invalid phone number'),
        alternative_phone: Yup.string().required("Enter alternative phone number").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,'invalid phone number'),
        dobyear: Yup.number().required('Select year for date of birth'),
        dobmonth: Yup.number().required('Select month for date of birth'),
        dobday: Yup.number().required('Select day for date of birth'),   
        first_language: Yup.string().required('Select first language'),
        second_language: Yup.string().required('Select second language'),
        id_type: Yup.number().required('Select ID type'),
        id_number: Yup.string().required('Enter ID number') , 
        address: Yup.string().required('Enter physical address'),
        country : Yup.string().required('Enter country of birth'),
        city: Yup.string().required('Enter city'),
        province:Yup.string().required('Select province'),
        suburb: Yup.string().required('Enter area or suburb'),
        area_code: Yup.string().required("Enter area code").matches(/^\d{4}$/ ,'Invalid area code'),
        employment_status: Yup.string().required('Select employment status'),
        gender: Yup.string().required('Select gender'),
        race:Yup.string().required('Select race'),
        religion: Yup.string().required('Select religion'),
        marital_status : Yup.string().required('Select marital status'),
        //number_of_siblings: Yup.string().required("Enter number of sibling").matches(/^\d{2}$/ ,'Invalid number of sibling '),
        spouse: Yup.string().required('Enter spouse name'),
        spouse_contact: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,'Spouse phone number is invalid'),
        //spouse_employment: Yup.string().required('Select employment status for your spuse'),        
        //guardian: Yup.string(),
        //guardian_employment: Yup.number(),
        reference_relationship: Yup.string().required('Select reference type'),
        reference_name: Yup.string().required('Enter reference name'),
        reference_email:Yup.string().email('Reference email invalid'),
        reference_cell: Yup.string().required('Enter reference phone number').matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,'Reference phone number is invalid'),
           
       
    }) 
    const [applicant, setApplicant] = useState()
    const [application, setApplication] = useState()
    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const [message, setMessage] = useState([]);
    const [uploadCompleted,setuploadCompleted] = useState(false)
    const progressInfosRef = useRef(null)
    const [lookUpData, setLookUpData] = useState()
    const [data, setData] = useState()
    const [updated, setUpdated] = useState(false)

    const history = useHistory()
    const {handleSubmit, handleChange, handleBlur, setValues, setFieldValue, values, touched, errors } = useFormik({ 
        initialValues: {
            first_name: applicantData["First Name"],
            last_name: applicantData["Last Name"],
            email: applicantData["Email"],           
            alternative_email: applicantData['Alternative Email'],
            cell_phone: applicantData['Cell Phone'],
            alternative_phone: applicantData['Alternative Phone'],
            date_of_birth: { year:  applicantData["Date of Birth"].substring(0,4), month: applicantData["Date of Birth"].substring(5,7),  day: applicantData["Date of Birth"].substring(8,10)},          
            address: applicantData['Address'],
            suburb: applicantData['Suburb'],
            city: applicantData['City'],
            province: applicantData['Province'],
            country :  applicantData['Country of Birth'],
            area_code: applicantData['Area Code'],
            id_type: applicantData['ID Type'],
            id_number: applicantData['ID Number'],
            gender: applicantData['Gender'],
            race: applicantData['Race'],
            religion: applicantData['Religion'],
            first_language: applicantData['First Language'],
            second_language: applicantData['Second Language'],
            marital_status : applicantData['Marital Status'],
            employment_status: applicantData['Employment Status'],
            number_of_siblings: applicantData['Number of Siblings'],
            //first_generation: applicantData['Area Code'],
            spouse_name: applicantData['Spouse Name'],
            spouse_contact: applicantData['Spouse Contact Number'],
            spouse_employment: applicantData['Spouse Employment Status'],
            //guardian: applicantData['Area Code'],
            //guardian_employment: applicantData['Area Code'],
            reference_name: applicantData['Reference Name'],
            reference_email: applicantData['Reference Email'],
            reference_cell: applicantData['Reference Cell Phone'],
            reference_relationship: applicantData['Relationship with Reference'],
            email_conformed:'',
            email_conformed_on:'',
            created_on:'',
            tcchecked: '',
            tc: '', 
            pl:'',           
            loading: false,
            error: '',
            success:''
        },
        validationSchema,
        validateOnChange: true
    });
    
    const { first_name , last_name , email, re_email, password, re_password, alternative_email, cell_phone , alternative_phone, country, id_type, id_number, date_of_birth ,
            address, suburb,city, province , area_code, gender, race, religion, first_language, second_language, marital_status, employment_status, 
            number_of_siblings, first_generation, spouse_name, spouse_contact, spouse_employment, guardian, guardian_employment, reference_name, reference_email, 
            reference_cell, reference_relationship, email_conformed ,email_conformed_on, created_on,tcchecked ,tc,pl, loading, error, success  
    } = values;

    const {year, month, day} = date_of_birth 

        
    const validationSchema2 =  Yup.object().shape({
        application_type: Yup.string().required('Select application type'),
        university: Yup.string().required('Select university'),
        course:  Yup.string().required('Select course'),
        duration_of_study:  Yup.number().required('Enter course duration'),
        year_of_study: Yup.number().required('Enter year of study'),
        completion_year: Yup.number().required('Enter compeletion year'),
        amount_for_tuition: Yup.number().required('Enter amount for tuition fee '),
        amount_for_books : Yup.number().required(' Enter amount for books'),
        amount__for_Accommodation: Yup.number().required('Enter amount for accommodation'),
        the_course_you_wish: Yup.string().required('Tell us about the course you wish to study'),
        academic_achievements:Yup.string().required('Tell us about your best academic achievements'),
        community_involvement:Yup.string().required('Tell us about your community involvement?'),
        competing:Yup.string().required('You are competing for a limited number of bursaries with 100’s of other applicants.Tell us why you should receive a SANZAF Zakah Bursary'),
        father_monthly_salary:  Yup.number().required('Enter amount for father monthly salary'),
        mother_monthly_salary: Yup.number().required('Enter amount for mother monthly salary'),
        spouse_monthly_salary: Yup.number().required('Enter amount for spouse monthly salary'),
        own_monthly_salary: Yup.number().required('Enter amount for own monthly salary'),
        investments: Yup.number().required('Enter amount for investments'),
        other_income: Yup.number().required('Enter amount for other income'),
        
       /* gold_silver_jewellery:0,
        cash_bank_savings: 0,
        investments: 0,
        investments_policies: 0,
        other_income: 0,
        total_income: 0, */
       
        rent_bond_expense: Yup.number().required('Enter amount for rent '),
        rates: Yup.number().required('Enter amount for rates '),
        water_electricity: Yup.number().required('Enter amount for water and electricity '),
        transport_petrol: Yup.number().required('Enter amount for transport'),
        telephone: Yup.number().required('Enter amount for telephone '),
        medical_aid: Yup.number().required('Enter amount for medical aid'),
        insurance: Yup.number().required('Enter amount for insurance'),        
        grocery_milk_bread: Yup.number().required('Enter amount for groceries '),
        other_expense: Yup.number().required('Enter amount for other expenses'),
        how_did_you_learn: Yup.string().required('Select how did you learn about SANZAF bursary'),
        //how_did_you_learn_specify,
        assisted_by_sanzaf :Yup.string().required('Select have any of your parents assisted by SANZAF'),
        //assisted_by_sanzaf_specify,
        was_the_application_form_easy : Yup.string().required('Select was the application form easy'),
        //was_the_application_easy_specify,
        opening_date : Yup.string().required('Select was the opping day suitable'),
        //opening_date_explain,
        closing_date :Yup.string().required('Select was the closing day suitable'),
        //closing_date_explain,
        instructions_clear :Yup.string().required('Select was the instructions clear'),
        //instructions_clear_explain
        //liabilities: Yup.number().required('Enter amount for own monthly salary'),
        /* other_expense: 0,
        total_expense: 0,
        financial_declaration: '',
        */


        
        id_document: Yup.mixed().test("fileSize",`  ID document file must be at least 2 MB. \n Try to compress the file and upload it again`, 
                                    value => value && value.size <= FILE_SIZE )
                                .test(
                                "fileFormat",
                                "ID Document file format unsupported. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),

        /*acceptance_letter: Yup.mixed().test("fileSize",`  Acceptance letter must be at least 2 MB. \n Try to compress the file and upload it again`, 
                                        value => value && value.size <= FILE_SIZE )
                                    .test(
                                    "fileFormat",
                                    "Acceptence Letter file format unsupported. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),*/
        affidavit:Yup.mixed().test("fileSize",`  Proof of income document file size too large. \n Try to compress the file and upload it again`,     
                                        value => value && value.size <= FILE_SIZE )
                                    .test(
                                    "fileFormat",
                                    "Proof of income file must be at least 2 MB. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),
        recommendation: Yup.mixed().test("fileSize",`  Testimonial letter file size too large. \n Try to compress the file and upload it again`, 
                                        value => value && value.size <= FILE_SIZE )
                                    .test(
                                    "fileFormat",
                                    " Testimonial letter file format unsupported. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),
        motivation_letter: Yup.mixed().test("fileSize",`  Motivational letter file must be at least 2 MB. \n Try to compress the file and upload it again`,
                                        value => value && value.size <= FILE_SIZE )
                                    .test(
                                    "fileFormat",
                                    "Motivational Letter file format unsupported. Upload PDF or IMAGE file",
                                    value => value && SUPPORTED_FORMATS.includes(value.type)),        
    })

          
 
   const {handleChange: handleChange2 , handleSubmit: handleSubmit2, handleBlur: handleBlur2, setValues: setValues2,  
            values: values2, touched: touched2, errors: errors2, validateForm: validateForm2 } = useFormik({
        initialValues: {
            applicant_id: applicantData['applicant_id'],
            application_id: applicantData['application_id'],
            application_date: '',
            application_type: applicantData['Application Type'],   
            student_number: applicantData['Student Number'],
            university: applicantData['University'],
            uni: '',           
            course: applicantData['Course'],
            unilevel: applicantData['Study Level'],
            duration_of_study: applicantData['Duration of Study'],
            year_of_study: applicantData['Year of Study'],
            completion_year: applicantData['Completion Year'],
            terms_and_conditions: '',
            amount_for_tuition: applicantData['Required Amount for Tuition'],
            amount_for_books: applicantData['Required Amount for Books'],
            amount_for_accommodation: applicantData['Required Amount for Accomodation'], 
            father_monthly_salary:  applicantData['Father Monthly Salary'],
            mother_monthly_salary:  applicantData['Mother Monthly Salary'],
            spouse_monthly_salary: applicantData['Spouse Monthly Salary'],
            own_monthly_salary: applicantData['Own Monthly Salary'],
            investments: applicantData['Investments'],      
            other_income: applicantData['Other Source of Income'],
            total_income: 0,
            investments_policies: '',
            gold_silver_jewellery: '',
            cash_bank_savings: '',
      
         
            father_guardian_name: '',
            monthly_rent_income: 0,         
            rent_bond_expense: applicantData['Rent and Bond'],
            rates: applicantData['Rates'],
            water_electricity: applicantData['Water Electricity'],
            grocery_milk_bread: applicantData['Groceries'],
            transport_petrol: applicantData['Transport'],
            telephone: applicantData['Telephone'],
            medical_aid: applicantData['Medical Aid'],
            insurance: applicantData['Insurance'],
            other_expense: applicantData['Other Expense'],
            liabilities: '', 
            
            total_expense: 0,
            financial_declaration: '',
            the_course_you_wish: applicantData['The Course you Wish to Study'],
            academic_achievements: applicantData['Academic Achievements'],
            community_involvement: applicantData['Community Involvement'],
            competing: applicantData['Tell us why you should receive a SANZAF Zakah Bursary'],
            pledge_by_Applicant:'',
            id_document: '',
            //acceptance_letter:'',
            affidavit:'',
            recommendation:'',
            motivation_letter:'',
            /*how_did_you_learn: applicantData['How Did You Learn About SANZAF Bursary'],
            how_did_you_learn_specify: applicantData['Area Code'],
            assisted_by_sanzaf: applicantData['Area Code'],
            assisted_by_sanzaf_specify: applicantData['Area Code'],
            was_the_application_form_easy: applicantData['Area Code'],
            was_the_application_easy_specify: applicantData['Area Code'],
            opening_date: applicantData['Area Code'],
            opening_date_explain: applicantData['Area Code'],
            closing_date: applicantData['Area Code'],
            closing_date_explain: applicantData['Area Code'],
            instructions_clear: applicantData['Area Code'],
            instructions_clear_explain: applicantData['Area Code']*/


            
        }, 
        validationSchema: validationSchema2
    })

    const {
        applicant_id,
        application_id,
        application_date,
        application_type,
        student_number,
        university,
        uni,
        course,
        cor,
        unilevel,
        duration_of_study,
        year_of_study,
        completion_year,
        terms_and_conditions,
        amount_for_tuition,
        amount_for_books,
        amount_for_accommodation, 
        father_monthly_salary,
        mother_monthly_salary,
        spouse_monthly_salary,
        own_monthly_salary,
        cash_bank_savings,
        other_income,
        gold_silver_jewellery,
        investments_policies,
        investments,
        total_income,
        rent_bond_expense,
        rates,
        water_electricity,
        grocery_milk_bread,
        transport_petrol,
        telephone,
        medical_aid,
        insurance,
        other_expense,
        total_expense,
        liabilities,
        financial_declaration,
        the_course_you_wish,
        academic_achievements,
        community_involvement,
        competing,
        pledge_by_Applicant,
        id_document, 
        //acceptance_letter,
        affidavit,
        recommendation,
        motivation_letter,
        how_did_you_learn,
        how_did_you_learn_specify,
        assisted_by_sanzaf,
        assisted_by_sanzaf_specify,
        was_the_application_form_easy,
        was_the_application_easy_specify,
        opening_date,
        opening_date_explain,
        closing_date,
        closing_date_explain,
        instructions_clear,
        instructions_clear_explain
    } = values2
    const stepLable  = [
        'BASIC INFORMATION',
        'SOCIAL INFORMATION',
        'APPLICATION',       
    ];
    const steps = [
            {'id': stepLable[0]},
            {'id': stepLable[1]},
            {'id': stepLable[2]},
         
        ];

    const {step, navigation } = useStep({ steps, initialStep: init['a']});
    
    const steps2 = [
        {'id': 'UNIVERSITY'},
        {'id': 'INCOME'},
        {'id': 'EXPENSES'},
        {'id': 'SURVEY'}
        
     ];

    const {step: step2, navigation: navigation2 } = useStep({steps: steps2, initialStep: init['b']});
    
    const registeration = { re_password, password, re_email, email , last_name, first_name }
    const basic = { email , last_name, first_name, address, suburb, city , province,  area_code,country, second_language, first_language, id_number, 
                         id_type, date_of_birth, cell_phone,alternative_phone, alternative_email
    }
    const basicinfo = {applicant: basic, email}

    const socio = { race, religion, marital_status, employment_status, 
                      number_of_siblings, spouse_name, spouse_contact, spouse_employment,
                      reference_name, reference_email, reference_cell, reference_relationship
    }    
    const socioinfo = {applicant: socio, email }

    const edu =  { application_type, student_number, university, uni,  course,cor,unilevel, duration_of_study, year_of_study, completion_year,
                            amount_for_tuition, amount_for_books, amount_for_accommodation }

    const inistitution = {application: edu,  application_id }
  
    
    const incomedata = { father_monthly_salary, mother_monthly_salary, spouse_monthly_salary, own_monthly_salary, 
                     cash_bank_savings, gold_silver_jewellery, investments_policies, investments, other_income ,total_income}
    
    

    const income = {application: incomedata, application_id }
    
    const expensedata = {rent_bond_expense, rates, water_electricity, grocery_milk_bread, transport_petrol, telephone, 
                    medical_aid, insurance, other_expense, total_expense} 

    const expense = {application: expensedata, application_id }  
    
    
    const surveydata = { the_course_you_wish, academic_achievements, community_involvement, competing}
    
    const survey = {application: surveydata, application_id }  

    const upload =  {"id_document": id_document ,  "affidavit": affidavit,
                     "recommendation": recommendation, "motivation_letter": motivation_letter}
                     


   
    const Basicinfoform = () => {
        const into = { email, last_name, first_name, address, suburb, city , province,  area_code,country, second_language, first_language, id_number, 
            id_type, date_of_birth, cell_phone,alternative_phone, alternative_email
        }
        var submitBasicInfo = { applicant: basicinfo, email: applicantData["Email"], applicant_id: applicantData["applicant_id"]}
        var d = new Date()
        var yearoption = []
        var monthoption = [] 
        var dayoption = []
        var date = 'fffff'
        for (var i=1930; i<=d.getFullYear(); i++){
            yearoption[i] = i
        }
        yearoption.push("year")
        for (var i=1; i<=12; i++){
            monthoption[i] = i
        }
        monthoption.push("month")
        for (var i=1; i<=31; i++){
            dayoption[i] = i
        }
        dayoption.push("day")
        const validatedob = ()=>{
            if (year == 'year') {
                alert("select year for date of birth")
                return false
            }
            if (month == 'month') {
                alert("select month for date of birth")
                return false
            } 
            if (day == 'day') {
                alert("select day for date of birth")
                return false
            } 
            return true 
        }
        const handleday = (event) =>{
             setValues({
                ...values,
                date_of_birth: {
                      ...values.date_of_birth,
                      day: event.target.value,
                },
              });
        } 
        const handlemonth = (event) =>{
            setValues({
                ...values,
                date_of_birth: {
                      ...values.date_of_birth,
                      month: event.target.value,
                },
              });
        }
        const handleyear = (event) =>{
            setValues({
                ...values,
                date_of_birth: {
                      ...values.date_of_birth,
                      year: event.target.value,
                },
              });
        }
        const err = {alternative_email: errors.alternative_email, email: errors.email, first_name: errors.first_name, last_name: errors.last_name, cell_phone: errors.cell_phone,  
                     country: errors.country, id_type: errors.id_type,   id_number: errors.id_number, first_language: errors.first_language, second_language: errors.second_language,  address: errors.address, suburb: errors.suburb, 
                     city: errors.city, province: errors.province, area_code: errors.area_code,  area: errors.area  }
       
        return     <div style={{marginTop: 50}}>

        <div>
                    <label className="form-label" >First Name</label> <label className="form-label required" >*</label>
                        <input  id="first_name" name="first_name" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={first_name}/> 
                        {touched.first_name && errors.first_name ? <div  className="alert alert-danger">  { errors.first_name} </div> : null} 
                     </div>                   
                    <div>
                        <label className="form-label">Last Name</label> <label className="form-label required" >*</label>                                           
                         <input className="form-control" id="last_name" name="last_name"  onBlur={handleBlur} onChange={handleChange} type="text" value={last_name}/> 
                        {touched.last_name && errors.last_name ? <div  className="alert alert-danger"> { errors.last_name} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">Email</label>  <label className="form-label required" >*</label>                   
                        <input className="form-control" id="email" name="email"  onBlur={handleBlur} onChange={handleChange} type="email" value={email}/>  
                        { touched.email && errors.email ? <div  className="alert alert-danger"> { errors.email} </div> : null}              
                    </div>                          
                     <div className="form-group">
                        <label className="form-label">Alternative Email</label>  <label className="form-label required">*</label>
                        <div>
                           <input id="alternative_email" name="alternative_email" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={alternative_email}/>
                           {touched.alternative_email && errors.alternative_email ? <div  className="alert alert-danger"> { errors.alternative_email } </div>: null} 
                           
                        </div>
                     </div>
                     <div className="form-group">
                        <label className="form-label" >Cell Phone Number</label> <label className="form-label required">*</label>
                        <div>   
                            <input id="cell_phone" name="cell_phone" maxLength="10" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={cell_phone}/>
                            <div  className="alert alert-info"> 10 digits, eg. 0831234567  </div>
                               {touched.cell_phone && errors.cell_phone ? <div  className="alert alert-danger"> { errors.cell_phone } </div>: null}  
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label" >Alternative Phone Number</label>  <label className="form-label required">*</label>
                        <div>   
                            <input id="alternative_phone" name="alternative_phone" maxLength="10" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={alternative_phone}/>
                            <div  className="alert alert-info"> 10 digits, eg. 0831234567  </div>
                                
                        </div>
                    </div>
                    <div className="form-group">
                        <label  className="form-label">Date of Birth</label> <label className="form-label required"  style={{ marginRight: 30}} >*</label>
                       
                        <select id="year" name="year" onChange={handleyear}  type="option" className="form-control" value={year} style={{width:60, padding: 10}}>                      
                        { yearoption.map( index => ( <option  value={index} > {index } </option> ))}
                     
                        </select>
                        <select id="month" name="month" onChange={handlemonth}  type="option" className="form-control" value={month} style={{width: 70, padding: 10}}>                      
                        { monthoption.map( index => ( <option  value={index} > {index } </option> ))}  
                        </select>
                        <select id="day" name="day" onChange={handleday}  type="option" className="form-control" value={day} style={{width: 60, padding: 10}}>                      
                        { dayoption.map( index => ( <option   value={index} > {index } </option> ))}  
                        </select>
           
                    </div>
                   
                    <div className="form-group">
                        <label className="form-label">Country of Birth</label><label className="form-label required" >*</label>
                        <div  >
                           <select id="country" name="country"  onBlur={handleBlur}  onChange={handleChange}  type="option" value={country} className="form-select" >
                               
                               { dump.list_of_countries.map((name, index )=>( <option value={name}  > { name } </option>))}
                               </select>
                               { touched.country && errors.country ? <div  className="alert alert-danger"> { errors.country } </div>: null}
                        </div>
                    </div>
                    
                    <div className="form-group">
                         <label className="form-label">ID Type</label><label className="form-label required" >*</label>                    
                     <div className="form-group">
                     <div>
                        <select id="id_type" name="id_type"  onBlur={handleBlur}  onChange={handleChange} value={id_type} className="form-select" >
                            
                            <option value="1" > South African ID</option>
                            <option value="2"> Passport</option>
                            <option value="3"> Refugee/Asylum Permit</option>
                        </select>
                        { touched.id_type && errors.id_type ? <div  className="alert alert-danger"> { errors.id_type } </div>: null}
                     </div>
                    </div> 
                    </div>
                     <div className="form-group">
                        <label className="form-label"> {  id_type === "1" && <div>South African ID Number</div> } { id_type === "2" &&  <div> Passport Number</div> }  { id_type === "3" &&  <div> Refugee Permit Number </div> }  </label> { id_type &&  <label className="form-label required" >*</label>}
                        { id_type && <div> 
                            <input id="id_number" name="id_number"  onBlur={handleBlur}  onChange={handleChange} type="text"  value={id_number}   className="form-control" value={id_number}   />
                            { touched.id_number && errors.id_number ? <div  className="alert alert-danger"> { errors.id_number } </div>: null}
                        </div>  }
                    </div>
        
                    <div className="form-group">
                        <label className="form-label">First Language</label>
                        <div  >
                            <select id="first_language" name="first_language"  onBlur={handleBlur}  onChange={handleChange}  type="option" value={first_language} className="form-select"   > 
                                { dump.list_of_languages.map(  (name, index ) => ( <option value={name}  > { name } </option>))} 
                            </select>
                            
                        </div>
                    </div>    
                    <div className="form-group">
                        <label className="form-label">Second Language </label>
                        <div  >
                            <select id="second_language" name="second_language"  onBlur={handleBlur}  onChange={handleChange} type="option" value={second_language} className="form-select" > 
                            
                            { dump.list_of_languages.map(  (name, index ) => ( <option value={name}  > { name } </option>))} 
                             </select>
                            
                        </div>
                    </div> 
                    <div className="form-group">
                            <label className="form-label">Address</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <textarea  id="address" name="address" className="form-control"  onBlur={handleBlur}  onChange={handleChange} value={address} type="text" value={address}  /> 
                                { touched.address && errors.address ? <div  className="alert alert-danger"> { errors.address } </div>: null}
                            </div>
                    </div>
                    <div className="form-group">
                             <label className="form-label">Area</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <input  id="suburb" name="suburb" className="form-control"  onBlur={handleBlur}  onChange={handleChange} value={suburb} type="text" value={suburb}  /> 
                                { touched.suburb && errors.suburb ? <div  className="alert alert-danger"> { errors.suburb } </div>: null}
                            </div>
                    </div>
                    <div className="form-group">
                        <label className="form-label">City</label> <label className="form-label required" >*</label> 
                        <div   >                           
                            <input   id="city" name="city" className="form-control"  onBlur={handleBlur}  onChange={handleChange} type="text" value={city}  /> 
                            { touched.city && errors.city ? <div  className="alert alert-danger"> { errors.city } </div>: null}
                            
                        </div>
                    </div>
                     <div className="form-group">
                         <label className="form-label">Province</label><label className="form-label required" >*</label> 
                         <div   >                           
                            <select  id="province" name="province"  onBlur={handleBlur}   onChange={handleChange} type="option" value={province} className="form-select"> 
                      
                            { dump.list_of_provinces.map( (name, index) => (<option value={name}  > { name } </option>))}
                            </select> 
                            { touched.province && errors.province ? <div  className="alert alert-danger"> { errors.province } </div>: null}
                         </div>
                    </div> 
                    <div className="form-group">
                             <label className="form-label">Area Code</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <input  id="area_code" name="area_code" maxLength="4" className="form-control" onBlur={handleBlur} onChange={handleChange} value={area_code} type="text" value={area_code}  /> 
                                { touched.area_code && errors.area_code ? <div  className="alert alert-danger"> { errors.area_code } </div>: null}
                            </div>
                    </div>
                                                       
                    <div> 
                        <input id="basicinfobuttin" type='button' value="Submit"  style={{marginTop: 10}} className="btn-success form-control" fullWidth 
                          onClick={() =>{  {window.scrollTo(0, 0); setValues({...values, loading: 'loading'}); updateApplicant(basicinfo) }}} />                  
                         <input id="basicinfobuttin" type='button' value="Next >>"  style={{marginTop: 10}} className="btn-success form-control" fullWidth 
                          onClick={() =>{ navigation.next()}} />
              
                    </div>     
            </div>      
             
    }
     const Socioinfoform = () => {          
            const err = { employment_status: errors.employment_status, gender: errors.gender, race: errors.race, religion: errors.religion, number_of_siblings: errors.number_of_siblings, 
            marital_status: errors.marital_status, spouse_name: errors.spouse_name, spouse_contact: errors.spouse_contact, spouse_employment: errors.spouse_employment,
            reference_relationship: errors.reference_relationship, reference_name: errors.reference_name, reference_email: errors.reference_email, reference_cell: errors.reference_cell}
      
        return <div> 
                    
                    <div className="form-group">
                        <label className="form-label">Employment Status</label> <label className="form-label required" >*</label>
                        <div>                           
                                <select id="employment_status" name="employment_status" onBlur={handleBlur} onChange={handleChange} type="option" value={employment_status}  className="form-select">  
                               
                                { dump.list_of_employments.map((name, index) => <option value={name}  > {name} </option>)}</select> 
                                { touched.employment_status && errors.employment_status ? <div  className="alert alert-danger"> { errors.employment_status } </div>: null} 
                        </div>
                    </div> 
                    
                    <div className="form-group">
                        <label className="form-label">Gender</label><label className="form-label required" >*</label>
                    </div>                   
                     <div className="form-group">
                     <div>
                        <select id="gender" name="gender" onBlur={handleBlur} onChange={handleChange} type="option" className="form-select"> 
                         <option  selected hidden >select gender</option>
                        <option value="1" >Male</option>
                        <option value="2">Female</option>
                        </select>
                        { touched.gender && errors.gender ? <div  className="alert alert-danger"> { errors.gender } </div>: null}
                     </div>
                  
                    </div>
                        <div className="form-group">
                        <label className="form-label">Race</label><label className="form-label required" >*</label> 
                            <div >                           
                                 <select  id="race" name="race" onBlur={handleBlur} onChange={handleChange} type="option"  value={race} className="form-select"> 
                                 
                                 { dump.list_of_races.map((name, index )=> <option value={name} > {name}</option>)} 
                                 </select> 
                                 <div  className="alert alert-info"> This information is used for statistical purposes only </div>
                                 {touched.race && errors.race ? <div  className="alert alert-danger">  { errors.race} </div> : null} 
                                 
                            </div>
                        </div>
                        <div className="form-group">
                        <label className="form-label">Religion</label><label className="form-label required" >*</label>
                            <div>                           
                                 <select id="religion" name="religion" onBlur={handleBlur}   onChange={handleChange} value={religion} className="form-select" type="option"> 
                                 
                                 { dump.list_of_religion.map((name, index)  => <option value={name}  > {name}</option>) }
                                 </select>
                                 <div  className="alert alert-info"> This information is used for statistical purposes only </div>
                                 { touched.religion && errors.religion ? <div  className="alert alert-danger"> { errors.religion } </div>: null}  
                                  
                            </div>
                        </div>
                        
                         <div className="form-group">
                            <label className="form-label" >Number of Siblings</label> 
                            <div   >                           
                                 <input  id="number_of_siblings" name="number_of_siblings" maxLength="2" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={number_of_siblings}/>
                                 { touched.number_of_siblings && errors.number_of_siblings ? <div  className="alert alert-danger"> { errors.number_of_siblings } </div>: null}  
                            </div>
                        </div> 
                                  
                        <div className="form-group">
                        <label className="form-label">Marital Status</label> <label className="form-label required" >*</label>
                        <div   >                           
                                <select id="marital_status" name="marital_status" value={marital_status}  onBlur={handleBlur}  onChange={handleChange}  className="form-select" type="option"> 
                                  
                                    {dump.list_of_maritals.map((name, index) => <option value={name}  > {name} </option> )}
                                   
                                </select> 
                                { touched.spouse_employment && errors.marital_status ? <div  className="alert alert-info"> { errors.marital_status } </div>: null} 
                        </div>
                        </div>
                        { marital_status && marital_status ==  "Married" ?
                        <div>
                            <div className=" form-group">
                                <label className="form-label" >Spouse Name</label> <label className="form-label required" >*</label>
                                <div   >                           
                                    <input id="spouse_name" name="spouse_name" className="form-control"  onBlur={handleBlur}  onChange={handleChange} type="text" value={spouse_name}  />                                     
                                </div>
                            </div> 
                            <div className="form-group">
                                <label className="form-label">Spouse Cell Phone number</label> 
                                <div   >                           
                                    <input id="spouse_contact" name="spouse_contact" maxLength="10" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={spouse_contact}  />
                                    <div  className="alert alert-info"> 10 digits, eg. 0831234567  </div>                                   
                                </div>
                            </div>   
                            <div className=" form-group">
                                <label className="form-label">Spouse Employment Status</label> <label className="form-label required" >*</label>
                                 <div >                           
                                    <select   id="spouse_employment" name="spouse_employment"  onBlur={handleBlur}  onChange={handleChange} value={spouse_employment} className="form-select" type="option" >
                                    
                                    { dump.list_of_employments.map((name, index) => <option value={name}  > {name} </option>)}</select>  
                                    { touched.spouse_employment && errors.spouse_employment ? <div  className="alert alert-info"> { errors.marital_status } </div>: null} 
                                </div>
                            </div>  
                        </div> : null}
                        <div className="form-group">
                             <label className="form-label" >Reference Type</label>  <label className="form-label required" >*</label> 
                             <div>                            
                             <select id="reference_relationship" name="reference_relationship"   onChange={handleChange} value={reference_relationship} className="form-select" type="option">
                             
                                  { dump.list_of_referenceRelationship.map((name, index)  => <option value={name}  > {name}</option>) }</select>
                            
                             </div>
                        </div> 
                   
                        <div className="form-group">
                             <label className="form-label" > Reference Name</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="reference_name" name="reference_name" className="form-control"  onChange={handleChange} type="text" value={reference_name}  /> 
                                { touched.reference_name && errors.reference_name ? <div  className="alert alert-info"> { errors.reference_name } </div>: null} 
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label" >Reference Cell Phone</label> <label className="form-label required" >*</label>
                             <div>                           
                                <input   id="reference_cell" name="reference_cell" maxLength="10" className="form-control"  onChange={handleChange} type="text" value={reference_cell}  /> 
                                <div  className="alert alert-info"> 10 digits, eg. 0831234567  </div>   
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label" >Reference Email</label> 
                             <div >                           
                                <input   id="reference_email" name="reference_email" className="form-control"  onChange={handleChange} type="text" value={reference_email}  /> 
                                 
                             </div>
                        </div> 
                            
                        <div className="form-group">
                       
                         <div style={{ marginTop: 10}}>

                                    <input id="socciinfobutton" type="button" value="Submit"  className="btn-success form-control" fullWidth  onClick={() =>{ if (noError(socioinfo,err)) {
                                         window.scrollTo(0, 0); 
                                         setValues({...values, loading: 'loading'}); 
                                         updateApplicant(socioinfo) }}} />
                            </div> 
                            <div style={{ marginTop: 10}}>     
                                <input id="socciinfobutton1" type="button" value="<< Back" className="btn-success form-control" fullWidth    onClick={() => navigation.previous( )}   style={{marginRight: 0 }}/>
                                
                            </div>
                            <div style={{ marginTop: 10}}>     
                                <input id="socciinfobutton1" type="button" value="Next >>" className="btn-success form-control" fullWidth    onClick={() => navigation.next( )}   style={{marginRight: 0 }}/>
                            </div>

                        </div>   
                
        </div>
           
    
    }

   const ApplicationForm = () => {
        return <div>                 
                  { step2.id === "UNIVERSITY"  && InstitutionForm() }
                  { step2.id ===  "INCOME" && IncomeForm()}
                  { step2.id ===  "EXPENSES" && ExpenseForm()}
                  { step2.id ===  "SURVEY" && SurveyForm() }
                  { step2.id ===  "Upload Documents" && UploadForm() }
                </div>
   }    
      
    const InstitutionForm = ( ) => {
        var list_of_universities 
        var list_of_courses
        if (lookUpData != undefined){
            
             list_of_universities = lookUpData['universities']
             list_of_courses = lookUpData['courses']
        }
        else {
            list_of_universities = dump.list_of_universities
            list_of_courses = dump.list_of_courses
        }
        const err =  {  application_type: errors2.application_type, university: errors2.university, uni: errors2.uni, student_number: errors2.student_number, course: errors2.course, unilevel: errors2.unilevel, duration_of_study: errors2.duration_of_study, 
            year_of_study: errors2.year_of_study, completion_year: errors2.completion_year, amount_for_tuition: errors2.amount_for_tuition, amount_for_books: errors2.amount_for_books, amount_for_accommodation: errors2.amount_for_accommodation}
         
           return <div>                             
           
           
                        <div className="form-group">
                             <label className="form-label">Application Type</label> <label className="form-label required" >*</label>
                             <div>                         
                                 <select   id="application_type" name="application_type" type="option" className="form-select" onBlur={handleBlur2} onChange={handleChange2} value={application_type} >
                                    <option selected>select application type</option>
                                      {dump.list_of_application_types.map((name, index) => <option value={name}  > {name} </option>)}</select>
                                      
                            </div>
                        </div>                       
                        <div className="form-group"> 
                             <label className="form-label">University</label> <label className="form-label required" >*</label>
                             <div>                           
                                <select   id="university" name="university" className="form-select"  type="option" onBlur={handleBlur2} onChange={handleChange2} value={university} >
                                    <option selected >select university</option>
                                    { dump.list_of_universities.map(( name,index )=> <option value={name}  > { name } </option>)} 
                                    
                                    </select>
                                    { touched2.university && errors2.university ? <div  className="alert alert-danger"> { errors2.university } </div>: null}
                             </div>
                        </div>
                        { university === 'Other' && <div className="form-group"> 
                             <label className="form-label">Please Specify</label><label className="form-label required" >*</label>
                             <div>                           
                                <input id="uni" name="uni" className="form-control" onBlur={handleBlur2} onChange={handleChange2} type="text" value={uni}  /> 
                                { touched2.uni && errors2.uni ? <div  className="alert alert-danger"> { errors2.uni } </div>: null}
                                 
                             </div>
                        </div> }
                        <div className="form-group">
                             <label className="form-label" >Course</label> <label className="form-label required" >*</label> 
                             <div>                           
                                <select   id="course" name="course" className="form-select" onBlur={handleBlur2}  type="option"  onChange={handleChange2} value={course} >
                                    <option selected>select course</option>
                                    {dump.list_of_courses.map((name,index)=> <option value={name}> {name} </option>)}</select>
                                    { touched2.course && errors2.course ? <div  className="alert alert-danger"> { errors2.course } </div>: null}
                             </div>
                        </div>
                        { course === 'OTHER' && <div className="form-group">
                             <label className="form-label">Please Specify</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <input id="cor" name="cor" className="form-control"  onChange={handleChange2} type="text" value={cor}  /> 
                                { touched2.cor &&  errors2.cor ? <div  className="alert alert-danger"> { errors2.cor } </div>: null}
                                 
                             </div>
                        </div> }
                        <div className="form-group">
                             <label className="form-label">Study Level</label> <label className="form-label required" >*</label>
                             <div>                         
                                 <select   id="unilevel" name="unilevel" type="option" className="form-select" onBlur={handleBlur2} onChange={handleChange2} value={unilevel}  >
                                    <option selected>select study level</option>
                                      { dump.list_of_study_levels.map((name, index) => <option value={name}> {name} </option>)}</select>
                                     
                            </div>
                        </div>     
                       
                        <div className="form-group">
                             <label className="form-label" >Student Number</label> 
                             <div   >                           
                                <input id="student_number" name="student_number" className="form-control"  onChange={handleChange2} type="text" value={student_number}  /> 
                               
                             </div>
                        </div>
                        <div className="form-group"> 
                             <label className="form-label" >Duration of Study</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <input id="duration_of_study" name="duration_of_study" className="form-control"  onChange={handleChange2} type="text" value={duration_of_study}  /> 
                                { touched2.duration_of_study && errors2.duration_of_study ? <div  className="alert alert-info"> { errors2.duration_of_study } </div>: null}
                             </div>
                        </div>
                        <div className="form-group">
                             <label className="form-label">Year of Study</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <input   id="year_of_study" name="year_of_study" className="form-control"  onChange={handleChange2} type="text" value={year_of_study}  /> 
                                { touched2.year_of_study && errors2.year_of_study ? <div  className="alert alert-info"> { errors2.year_of_study } </div>: null}
                             </div>
                        </div>
                        <div className="form-group">
                             <label className="form-label" >Completion Year</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="completion_year" name="completion_year" className="form-control"  onChange={handleChange2} type="text" value={completion_year}  /> 
                                { touched2.completion_year && errors2.completion_year ? <div  className="alert alert-info"> { errors2.completion_year } </div>: null}
                             </div>
                        </div>
                        <div className="form-group">
                             <label className="form-label">Required Amount for Tuition</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <input   id="amount_for_tuition" name="amount_for_tuition" className="form-control" onBlur={handleBlur2}  onChange={handleChange2} type="text" value={amount_for_tuition}  /> 
                                {touched2.amount_for_tuition && errors2.amount_for_tuition ? <div  className="alert alert-denger">  { errors2.amount_for_tuition} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label">Required Amount for Books</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <input   id="amount_for_books" name="amount_for_books" className="form-control" onBlur={handleBlur2}  onChange={handleChange2} type="text" value={amount_for_books}  /> 
                                {touched2.amount_for_books && errors.amount_for_books ? <div  className="alert alert-danger">  { errors2.amount_for_books} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label">Required Amount for Accommodation</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <input   id="amount_for_accommodation" name="amount_for_accommodation" className="form-control" onBlur={handleBlur2}  onChange={handleChange2} type="text" value={amount_for_accommodation}  /> 
                                {touched2.amount_for_accommodation && errors.amount_for_accommodation ? <div  className="alert alert-danger">  { errors.amount_for_accommodation} </div> : null}
                                
                             </div>
                        </div>  
                       <div style={{ marginTop: 10}}>  
                        <input id="uni3" type="button" value="Submit" className="btn-success form-control" fullWidth    onClick={() =>{ 
                                                                     if (noError(inistitution,err)) {window.scrollTo(0, 0); setValues({...values, loading: 'loading'});
                                                                           
                                                                            updateApplication(inistitution)
                                                                             }}}  />
                           
                        </div>
                        <div style={{ marginTop: 10}}>  
                           <input id="uni2" type="button" value="<< Back"  className="btn-success form-control" fullWidth    onClick={() => navigation.previous( )}  />    
                        </div>                    
                        <div style={{ marginTop: 10}}>  
                           <input id="uni2" type="button" value="Next >>"  className="btn-success form-control" fullWidth    onClick={() => navigation2.next( )}  />    
                        </div> 
                  </div>

       }

    const IncomeForm = ()=> {
         
        const err =  { father_monthly_salary: errors2.father_monthly_salary , mother_monthly_salary: errors2.mother_monthly_salary, spouse_monthly_salary: errors2.spouse_monthly_salary,
            own_monthly_salary: errors2.own_monthly_salary,  cash_bank_savings: errors2.cash_bank_savings, gold_silver_jewellery: errors2.gold_silver_jewellery,
            investments_policies: errors2.investments_policies , investments: errors2.investments, other_income: errors2.other_income   }

        return <div>
                     <div  className="alert alert-info"> <label className="form-label required" >*</label> Type ''0'' if not applicable or no income </div>
                     <div className="form-group">
                             <label className="form-label"  >Father Monthly Salary </label>  <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="father_monthly_salary" name="father_monthly_salary" className="income form-control" onBlur={sumIncome}  onChange={handleIncome} value={father_monthly_salary} type="text"   /> 
                                
                                { touched2.father_monthly_salary && errors2.father_monthly_salary ? <div  className="alert alert-denger"> { errors2.father_monthly_salary } </div>: null} 
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label" >Mother Monthly Salary</label>  <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="mother_monthly_salary" name="mother_monthly_salary" className="income form-control" onBlur={sumIncome}   onChange={handleIncome} value={mother_monthly_salary} type="text"   /> 
                                 
                                 { touched2.mother_monthly_salary && errors2.mother_monthly_salary ? <div  className="alert alert-denger"> { errors.mother_monthly_salary } </div>: null} 
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label" >Your Own Monthly Salary</label>  <label className="form-label required" >*</label>
                             <div>                           
                                <input   id="own_monthly_salary" name="own_monthly_salary" className="income form-control"  onBlur={sumIncome}   onChange={handleIncome} value={own_monthly_salary} type="text"   /> 
                                { touched2.own_monthly_salary && errors2.own_monthly_salary ? <div  className="alert alert-denger"> { errors.own_monthly_salary } </div>: null} 
                             </div>
                        </div>  
                        
                        <div className="form-group">
                             <label className="form-label" >Your Spouse Monthly Salary</label>  <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="spouse_monthly_salary" name="spouse_monthly_salary" className="income form-control"   onBlur={sumIncome}  onChange={handleIncome} value={spouse_monthly_salary} type="text" /> 
                                { touched2.spouse_monthly_salary && errors2.spouse_monthly_salary ? <div  className="alert alert-denger"> { errors.spouse_monthly_salary } </div>: null} 
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label" >Investments</label>  <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="investments" name="investments" className=" form-control"   onBlur={sumIncome}   onChange={handleIncome} value={investments} type="text"   /> 
                                { touched2.investments && errors2.investments ? <div  className="alert alert-denger"> { errors.investments } </div>: null} 
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label" >Other Source of Income</label> <label className="form-label required" >*</label>
                             <div   >                           
                                <input   id="other_income" name="other_income" className="form-control income"  onBlur={sumIncome}   onChange={handleIncome} value={other_income} type="text"  /> 
                                { touched2.other_income && errors2.other_income ? <div  className="alert alert-denger"> { errors.other_income } </div>: null}
                             </div>
                        </div>  
                        <div className="form-group">
                             <label className="form-label" >Total  Income</label> 
                             <div   >                           
                                <input   id="total_income" name="total_income" className="form-control"    type="text" value={  total_income} disabled  /> 
                                 { errors2.total_income ? errors2.total_income : null}
                             </div>
                         </div> 
                  
                         <div style={{ marginTop: 10}}> 
                        
                         <input id="inc2" type="button" value="Submit"  className="btn-success form-control" fullWidth    onClick={() =>{ if (noError(income,err)) {window.scrollTo(0, 0); setValues({...values, loading: 'loading'}); updateApplication(income) }}}  />
                            
                         </div>   
                        <div style={{ marginTop: 10}}>                           
                            <input id="inc1" type="button" value="<< Back"  className="btn-success form-control" fullWidth     onClick={() => navigation2.previous( )}  />                           
                        </div> 
                        <div style={{ marginTop: 10}}>                           
                            <input id="inc1" type="button" value="Next >>"  className="btn-success form-control" fullWidth     onClick={() => navigation2.next( )}  />                           
                        </div> 
               </div>
    }
    const handleIncome = (e) =>{
        var value 
        value = e.target.value
        if ( isNaN(value) || ( value == "")){
            //alert('Enter number')  
            e.target.value = ""
            value = 0
           
         }  else {
           value = e.target.value

         } 
        setValues2({...values2, [e.target.name]:  value}) 

    }
   
    const sumIncome = (e) =>{
        var sum = 0
        const income = [mother_monthly_salary, father_monthly_salary, spouse_monthly_salary, own_monthly_salary, investments, other_income ]
        income.map(inc =>{
            if (inc == '' || undefined) {
                inc = 0
            }
            sum = sum + parseFloat(inc)
        })
        setValues2({...values2,  total_income: sum })       
        //sum = mother_monthly_salary, father_monthly_salary, spouse_monthly_salary, own_monthly_salary, investments 
        /* sum = parseFloat(inc[0]) + parseFloat(inc[1]) + parseFloat(inc[2]) + parseFloat(own_monthly_salary) +
              parseFloat( investments) + parseFloat( other_income)
              setValues2({...values2,  total_income: sum })       

        sum = parseFloat(mother_monthly_salary) + parseFloat(father_monthly_salary) + parseFloat(spouse_monthly_salary) + parseFloat(own_monthly_salary) +
              parseFloat( investments) + parseFloat( other_income)
              setValues2({...values2,  total_income: sum })       */

    }


    const ExpenseForm = ()=> {
        const err = {rent_bond_expense: errors2.rent_bond_expense, rates: errors2.rates , water_electricity: errors2.water_electricity, 
            transport_petrol: errors2.transport_petrol, telephone: errors2.telephone, 
            medical_aid: errors2.medical_aid, insurance: errors2.insurance,  grocery_milk_bread: errors2.grocery_milk_bread, other: errors2.other_expense} 

        return <div>
                        <div  className="alert alert-info">  <label className="form-label required" >*</label>Type ''0'' if not applicable  </div>
                        <div className="form-group">
                             <label className="form-label"> Rent / Bond </label>  <label className="form-label required" >*</label>
                             <div   >
                                <input   id="rent_bond_expense" name="rent_bond_expense" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ rent_bond_expense}  /> 
                                
                                { touched2.rent_bond_expense && errors2.rent_bond_expense ? <div  className="alert alert-denger"> { errors.rent_bond_expense } </div>: null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> Rates</label>  <label className="form-label required" >*</label>
                             <div   >
                                <input   id="rates" name="rates" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ rates}  /> 
                                { touched2.rates && errors2.rates ? <div  className="alert alert-denger"> { errors.tates } </div>: null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> Water & Electricity</label>  <label className="form-label required" >*</label>
                             <div   >
                                <input   id="water_electricity" name="water_electricity" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ water_electricity}  /> 
                                { touched2.water_electricity && errors2.water_electricity ? <div  className="alert alert-denger"> { errors.water_electricity } </div>: null}

                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> Transport / Petrol </label>  <label className="form-label required" >*</label>
                             <div   >
                                <input   id="transport_petrol" name="transport_petrol" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ transport_petrol}  /> 
                                { touched2.transport_petrol && errors2.transport_petrol ? <div  className="alert alert-denger"> { errors.transport_petrol } </div>: null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> Telephone</label> <label className="form-label required" >*</label>
                             <div   >
                                <input   id="telephone" name="telephone" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ telephone}  /> 
                                { touched2.telephone && errors2.telephone ? <div  className="alert alert-denger"> { errors.telephone } </div>: null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> Medical Aid</label> <label className="form-label required" >*</label>
                             <div   >
                                <input   id="medical_aid" name="medical_aid" className="form-control" onBlur={sumExpense}  onChange={handleExpense} type="text" value={ medical_aid}  /> 
                                { touched2.medical_aid && errors2.medical_aid ? <div  className="alert alert-denger"> { errors.medical_aid } </div>: null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> Insurance</label> <label className="form-label required" >*</label>
                             <div   >
                                <input   id="insurance" name="insurance" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ insurance}  /> 
                                { touched2.insurance && errors2.insurance ? <div  className="alert alert-denger"> { errors.insurance } </div>: null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> Groceries</label>  <label className="form-label required" >*</label> 
                             <div   >
                                <input   id="grocery_milk_bread" name="grocery_milk_bread" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ grocery_milk_bread}  /> 
                                { touched2.grocery_milk_bread && errors2.grocery_milk_bread ? <div  className="alert alert-denger"> { errors.grocery_milk_bread } </div>: null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label">Other Expenses</label><label className="form-label required" >*</label> 
                             <div   >
                                <input   id="other_expense" name="other_expense" className="form-control" onBlur={sumExpense} onChange={handleExpense} type="text" value={ other_expense}  /> 
                                { touched2.grocery_milk_bread && errors2.grocery_milk_bread ? <div  className="alert alert-denger"> { errors.grocery_milk_bread } </div>: null}
                                
                             </div>
                        </div>  
                        <div className="form-group">
                             <label className="form-label">Total Expense</label> 
                             <div>                           
                                <input   id="total_expense" name="total_expense" className="form-control"    type="text" value={  total_expense} disabled  /> 
                                 { errors2.total_expense ? errors2.total_expense : null}
                             </div>
                         </div> 
                  
                         <div style={{ marginTop: 10}}> 
                        
                        <input id="exs1" type="button" value="Submit"  className="btn-success form-control" fullWidth    onClick={() =>{ if (noError(expense,err)) {window.scrollTo(0, 0); setValues({...values, loading: 'loading'}); updateApplication(expense) }}}  />
                           
                        </div>   
                        <div style={{ marginTop: 10}}> 
                         
                        <div style={{ marginTop: 10}}>                           
                            <input id="inc1" type="button" value="<< Back"  className="btn-success form-control" fullWidth     onClick={() => navigation2.previous( )}  />                           
                        </div> 
                        <div style={{ marginTop: 10}}>                           
                            <input id="inc1" type="button" value="Next >>"  className="btn-success form-control" fullWidth     onClick={() => navigation2.next( )}  />                           
                        </div> 
                           

                       </div> 
               </div>
    }
    const handleExpense = (e) =>{
        var value 
        value = e.target.value
        if ( isNaN(value) || ( value == "")){  
            value = 0           
        }  else {
             value = e.target.value
        } 
        setValues2({...values2, [e.target.name]:  value})   
    }
    const sumExpense = (e) =>{ 
        var sum = 0
        const exp = [ rent_bond_expense, rates, water_electricity, grocery_milk_bread, transport_petrol, telephone, 
            medical_aid, insurance, other_expense]
        exp.map(ex =>{
            if (ex == '' || undefined) {
                ex = 0
            }
            sum = sum + parseFloat(ex)
     })
        setValues2({...values2,  total_expense: sum })    
        
    }
    const SurveyForm = () => { 
        const err = {  the_course_you_wish: errors2.the_course_you_wish, academic_achievements: errors2.academic_achievements, community_involvement: errors2.community_involvement, competing: errors2.competing,
                       }
                        
        return <div>                                    
                        
                        <div className="form-group">
                             <label className="form-label"> Tell us about the course you wish to study?</label> <label className="form-label required" >*</label> 
                             <div   >
                                <textarea rows="4" cols="50"   id="the_course_you_wish" name="the_course_you_wish" className="form-control" onBlur={handleBlur2} onChange={handleChange2} type="text" value={ the_course_you_wish}  /> 
                                {touched2.the_course_you_wish && errors2.the_course_you_wish ? <div  className="alert alert-danger">  { errors.the_course_you_wish} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label" > Tell us about your best academic achievements?</label> <label className="form-label required" >*</label> 
                             <div   >
                                <textarea rows="4" cols="50"  id="academic_achievements" name="academic_achievements" className="form-control" onBlur={handleBlur2} onChange={handleChange2} type="text" value={ academic_achievements}  /> 
                                {touched2.academic_achievements && errors.academic_achievements ? <div  className="alert alert-info">  { errors.academic_achievements} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> Tell us about your community involvement?</label> <label className="form-label required" >*</label> 
                             <div   >
                                <textarea rows="4" cols="50" id="community_involvement" name="community_involvement" className="form-control" onBlur={handleBlur2} onChange={handleChange2} type="text" value={ community_involvement}  /> 
                                {touched2.community_involvement && errors.community_involvement ? <div  className="alert alert-info">  { errors.community_involvement} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"> You are competing for a limited number of bursaries with 100’s of other applicants. Tell us why you should receive a SANZAF Zakah Bursary?<label className="form-label required" >*</label> </label>
                             
                             <div   >
                                <textarea rows="4" cols="50"    id="competing" name="competing" className="form-control"  onChange={handleChange2} type="text" value={ competing}  /> 
                                {touched2.competing && errors.competing ? <div  className="alert alert-info">  { errors.competing} </div> : null}
                             </div> 
                        </div>
                        <div style={{ marginTop: 10}}> 
                             
     
                                                                                                                                        
                        <input id="ser" type="button" value="Submit"  className="btn-success form-control" fullWidth style={{marginTop: 15}}   onClick={() =>{if (noError(survey ,err)) {window.scrollTo(0, 0); setValues({...values, loading: 'loading'}); updateApplication(survey); fechApplicantData() }}}  />
                           
                        </div>   
                        <div style={{ marginTop: 10}}> 
                         
                         <input id="serb" type="button" value={backward}  className="btn-success form-control" fullWidth     onClick={() => navigation2.previous( )}  />
                           

                       </div> 
                           
               </div>
    
    }
    const UploadForm = () => {              
    
        const err = { id_document: errors2.id_document , affidavit: errors2.affidavit,
             recommendation: errors2.recommendation, motivation_letter: errors2.motivation_letter}  
    
        
        return <div>
                        
                        <div className="form-group">
                             <label className="form-label" >ID Document</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <Input  id="id_document" name="id_document" placeholder={id_document.fileName} className="form-control" onBlur={handleBlur2}  onChange={(e) =>{
                                                                 setValues2({...values2, id_document: e.target.files[0]});
                                                                 progressInfos.val.map((progress, index) => { if (progress['key'] === 'id_document') { progress.percentage = 0} });
                                                                 }} type="file" /> 
                                                                 {id_document.fileName}
                                {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'id_document' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                { touched2.id_document && errors2.id_document ? <div  className="alert alert-danger">  { errors2.id_document} </div> : null}
                               
                             </div>
                        </div> 
                        <div className="form-group">
                             <label  className="form-label" >Proof of Income</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <Input id="affidavit" name="affidavit" placeholder={affidavit.fileName}  className="form-control" onBlur={handleBlur2} onChange={(e) =>{
                                                                 setValues2({...values2, affidavit: e.target.files[0]});
                                                                 progressInfos.val.map((progress) => { if (progress['key'] === 'affidavit') { progress.percentage = 0} });
                                                                 }} type="file" /> 
                                {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'affidavit' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                { touched2.affidavit && errors2.affidavit ? <div  className="alert alert-danger">  { errors2.affidavit} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"  >Testimonial Letter</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <Input id="recommendation" name="recommendation" placeholder={recommendation.fileName}  className="form-control" onBlur={handleBlur2} onChange={(e) =>{
                                                                 setValues2({...values2, recommendation: e.target.files[0]});
                                                                 progressInfos.val.map((progress) => { if (progress['key'] === 'recommendation') { progress.percentage = 0} });
                                                                 }} type="file" /> 
                                {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'recommendation' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                { touched2.recommendation && errors2.recommendation ? <div  className="alert alert-danger">  { errors2.recommendation} </div> : null}
                             </div>
                        </div> 
                        <div className="form-group">
                             <label className="form-label"  >Motivational Letter</label> <label className="form-label required" >*</label> 
                             <div   >                           
                                <Input id="motivation_letter" name="motivation_letter" placeholder={motivation_letter.fileName} className="form-control" onBlur={handleBlur2} onChange={(e) =>{
                                                                 setValues2({...values2, motivation_letter: e.target.files[0]});
                                                                 progressInfos.val.map((progress) => { if (progress['key'] === 'motivation_letter') { progress.percentage = 0} });
                                                                 }} type="file" /> 
                                {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'motivation_letter' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                { touched2.motivation_letter && errors2.motivation_letter ? <div  className="alert alert-danger">  { errors2.motivation_letter} </div> : null}
                             </div>
                        </div>   
                        <div style={{ marginTop: 10}}>
                          <input id="up1" type="button" value="Upload Files" className="btn-success form-control" fullWidth onClick={() =>{ setMessage([]); if (notEmpty(upload) && noError(upload,err)) { submitFiles(upload); }}}  />
                                
                        </div>                   
                       
                     
                     <div style={{ marginTop: 10}}>
                         <input id="up2" type="button" value={backward}  className="btn-success form-control" fullWidth    onClick={() => navigation2.previous( )}  />
                     </div>
                    
                     <div style={{ marginTop: 10}}>
                         {message.length === 4 && uploadCompleted && <input id="up3" type="button" value="Save and Continue"  className="btn-success form-control" fullWidth  onClick={
                             
                             () => { navigation2.next();}
                                                                                         }   
                            />
                                
                         }  </div>   
                     <div style={{ marginTop: 10}}> 
                    <div>  {message.length > 0 && ( <div className="alert alert-secondary" role="alert"> <ul>   {message.map((item, i) => { return <li key={i}>{item}</li>;
                                             })}
                              </ul>
                  </div>  )}  </div>
                  </div>  
               </div>
    
    }


    const submitRegesiteration = user => {     
        register({first_name,last_name,email,password}).then(data => {
           if (data.error) {
               //setValues({ ...values, error: data.error, success: false });
               alert(data.error)
           } else {        
               setValues({...values, first_name: '' ,last_name: '' ,email: '', re_email: '', password:'', re_password:'', error: '', success: true , loading: 'loading' }); 
               alert('Registration successful. Proceed to log in ')
               history.push("/signin");                        
           }
       });
   };
   const submitApplication = application => {
        registerApplication(application).then(data => {
        if (data.error) {
            if (data.error === "application already exist"){                
                localStorage.setItem('application_id', JSON.stringify(data['application_id']))
                localStorage.setItem('status', JSON.stringify(data['status_id'])) 
                setValues({ ...values, loading: false });        
                navigation2.next() 
            }
            else {
                alert(data.error)
            }
           //setValues({ ...values, error: data.error, success: false });
         
       } else {
         localStorage.setItem('application_id', JSON.stringify(data['application_id']))
         localStorage.setItem('status', JSON.stringify(data['status_id'])) 
         setValues({ ...values, loading: false });        
         navigation2.next()     
       }
   });
  };
  
  const submitApp = () => {     
    appsubmit().then(data => {
       if (data.error) {
           //setValues({ ...values, error: data.error, success: false });
           alert(data.error)
       } else { 
            setValues({ ...values, loading: false });                     
            history.push("/applicantdashboard"); 
        }                        
     });
   };

   
   const updateApplicant = update => {         
    updateApplicantDataAdmin(update).then(data => {
    if (data.error) {
        alert(data.error)
        //setValues({ ...values, error: data.error, success: false });
    } else {      
        setValues({ ...values, loading: false });
        setUpdated(true)  
        //setUpdate(true)  
        //navigation.next()
    }
    });
    }


    const updateApplication = update => {
        updateApplicantationDataAdmin(update).then(data => {
        if (data.error) {
            setValues({ ...values, error: data.error, success: false });
        } else {
                setValues({ ...values, loading: false });
                setUpdated(true)     
                
            }
         });
    }
    
    const submitFiles = upload => {  
        let _progressInfos = Object.keys(upload).map(key => ({ percentage: 0, key: key, fileName: upload[key].name }));
        progressInfosRef.current = {
          val: _progressInfos,
        }
        const id =  sendFile(0,"id_document", upload["id_document"])
        //const acceptance =  sendFile(1,"acceptance_letter", upload["acceptance_letter"])
        const proof =  sendFile(1,"affidavit", upload["affidavit"])
        const recomm =  sendFile(2,"recommendation", upload["recommendation"])
        const motive =  sendFile(3,"motivation_letter", upload["motivation_letter"])
    }

    const sendFile = (key,fileName, file) => { 
        let _progressInfos = [...progressInfosRef.current.val];
        const applicant_id =  JSON.parse(localStorage.getItem('log')).user
        return uploadFiles(applicant_id,file,fileName, (event) => {
          _progressInfos[key].percentage = Math.round(
            (100 * event.loaded) / event.total
          );
          setProgressInfos({ val: _progressInfos });
        })
          .then((res) => {
            setMessage((prevMessage) => ([
              ...prevMessage,
              file.name + "  uploaded  successfully. " 
            ]));
            setValues({ ...values, loading: false }); 
          })
          .catch(() => {
            _progressInfos[key].percentage = 0;
            setProgressInfos({ val: _progressInfos });
    
            setMessage((prevMessage) => ([
              ...prevMessage,
              file.name + " failed to upload"
            ]));
          });
      }

    var completed = []
    const showNext = ()=>{
        return uploadCompleted
    }
    
    const ProgressBar = ({progress }) => {
        
        if (progress.percentage == 100) {
            completed.push(progress.key)
        }
      
        if (completed.length == 4){
            setuploadCompleted(true)          
          
        }
      
      return  <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: `${progress.percentage}%`}} > {progress.percentage}%</div>
       </div>
    }
    const fechApplicantData = ()=> {
        fechData().then( data => {
             setData(data)
        })
    }
    const fechApplicant = ()=> {
        getApplicant().then( data => {
             setApplicant(data )
        })
    }
  
    const fechApplication = ()=> {
        getApplication().then( data => {
             setApplication(data )
        })
    }
    
    
    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>{loading}...</h2>
            </div>
        );


    const showError = () => (
        error &&  <div  className="alert alert-danger">
                      {error}
                    </div> 
             
    )

    const showSuccess= () => (
        <div   className="alert alert-info" style={{ display: success ? '' : 'none' }}>
            New account has been created. Please <Link to="/signin">Signin</Link>
        </div>
    );

    const showUpdated = () => (
        <div   className="alert alert-info">
            Applicat Data updated successfully <Link onClick={ ()=> history.push('/admindashboard') }>Back to Admin Dashboard</Link>
        </div>
    );


   
 
    const  notEmpty = (obj) => {
        
        for (var key in obj) {
            if (obj[key] == ""){
                alert(`Select File for ${key}`)
                return false;
            }
        }
        return true;
    }
    const  notData = (data) => {
        
        for (var key in data) {
            if (data[key] != ""){
                return false;
               
            }
        }
        return true;
    }
    const  isError = (data, err) => {
        for (var key in err) {
            if (err[key])
                return err[key];
        }
        return false;
    }
    

    const noError = (data, err )=>{
        if (notData(data)){
            alert('Complete the required fields')
            return false
        }
        let error = isError(data,err)
        if (error) {
            alert(error)
            //setValues({ ...values, error: isError(err) , success: false });
            return  false       
        }
        setValues({ ...values, error: ''});
        //setValues({...values, error: false} );
        return true;
        //setValues({ ...values, error: false  });

    }
  
    const Progress = () => {
        return <div>
                      <Stepper activeStep={steps.indexOf(step)} alternativeLabel >
                           {steps.map((label) => (
                            <Step key={label.id}>
                                <StepLabel>{label.id}</StepLabel>
                                { step.id === "APPLICATION" && label.id === "APPLICATION" && 
                                     
                                    <Stepper activeStep={steps2.indexOf(step2)} alternativeLabel >
                                         {steps2.map((label) => (
                                         <Step key={label.id}>
                                                 <StepLabel>{label.id}</StepLabel>
                                                  
                                        </Step> ))}
                                    </Stepper>
                                
                                }
                           </Step>
                            ))}
                        </Stepper>
               </div>
    }
   
    return (
          <div> 
        
                    <div>
                    <button onClick={()=> {  history.push('/admindashboard') }}> Back to Admin Page </button>
                    <Container maxWidth="xs">
                    
                        {showLoading()}  
                       { updated &&  showUpdated()} 
                    </Container>
                     {Progress()}  
                     {step.id === "REGISTRATION" } 
                    <Container maxWidth="xs">
                        {showSuccess()}
                        {showError()}
                        {/* ShowData() */}         
                        <form autoComplete="off">
                            
                            <div className="page">                                
                                {step.id === "BASIC INFORMATION" &&  <div > { Basicinfoform( )} </div>}
                                {step.id === "SOCIAL INFORMATION" &&  <div> {Socioinfoform()} </div>}
                                {step.id === "APPLICATION" &&  <div> {ApplicationForm()} </div>}
                            </div>                                   
                        </form>
                    </Container>
                    <Container maxWidth="lg">
           
                    </Container> 
                    </div>
  
        </div>
    );
};

export default UpdateApplicant;