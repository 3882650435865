import React, { useState , useEffect} from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";

import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import  Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../core/Layout';
import { sendComment, sendDocumentsRequest } from '../util';
import { TextareaAutosize, TextField } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@material-ui/core/Checkbox';
import { read } from 'xlsx';
import { Tune } from '@material-ui/icons';

const Review = ({applicant}) =>{ 
   
    const [display,setDisplay] = useState('comment')
    const [selectedField, selecField] = useState([]);
    const [selectedEmail, selecEmail] = useState([]);
    const text =  'Dear first_name  last_name\n \n\n \n \n \n Click on the link below or copy and paste it into a browser address bar \n \n url \n \n \n We thank you for your co-operation. \n Was Salaam'
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 

    
    const upload = [ 'ID Document','Proof of Income','Testimonial Letter','Motivational Letter','Acceptance Letter',
                 'Proof of Registration', 'Fees Statement','Consent Form','midyear_transcript', 'finalyear_transcript', ]
    const validationSchema = Yup.object({
        email: Yup.string().required('Enter your Eamil'),
        comment: Yup.string().required('enter comment')
    })
    
    const {handleSubmit, handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            comment:'',
            email:'',
            selectedEmails: [],
            cc:'',
            bcc:'',
            subject: 'Request for outstanding documents 2023',
            field: [],
            body: text,
            acceptanceEmails: '0',
            loading: false,
            redirectToReferrer: false
        },
        validationSchema
    });
    const { comment, cc,selectedEmails, acceptanceEmails, email,re_email ,subject, body,field, loading, error, redirectToReferrer } = values;	


    const  notEmpty = (obj) => {
        
        for (var key in obj) {
            if (obj[key] == ""){
                alert(`Select File for ${key}`)
                return false;
            }
        }
        return true;
    }
    const  isError = obj => {
        for (var key in obj) {
            if (obj[key])
                return obj[key];
        }
        return false;
    }
    

    const noError = (data, err )=>{

     /*  if (notEmpty(data))  {         
            setValues({ ...values, error: `Enter Regisration Data`, success: false });
            return false 
        } */
        if (isError(err)) {
            setValues({ ...values, error: isError(err) , success: false });
            return  false       
        }
        setValues({...values, error: '' });
        return true;
        //setValues({ ...values, error: false  });

    }
    const saveComment = ()=> {
        //setValues({ ...values, error: false, loading: true });
        sendComment( {comment: `${comment} by ${JSON.parse(localStorage.getItem('log')).first_name[0]}${JSON.parse(localStorage.getItem('log')).last_name[0]}`,application_id: applicant['application_id']} ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
            setValues({ ...values, error: false, loading: false });
            alert('Comment Saved')
             

            }
        });
    }
    const Comment = ()=>{
        return (
            <Container >
                <div style={{ color: 'red', fontSize: 20 }} >
                     
                </div>
                <form >
                    <div className="form-group"   >
                        <label className="form-label">Comment</label> 
                        <textarea key="comment" id="comment" name="comment" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={comment}  /> 
                        { touched.comment && errors.comment ? <div  className="alert alert-danger">  { errors.comment} </div> : null} 
                    </div>
                    <div> 
                        <input id="rev" type="button" value="Save Comment" onClick={ () => { setValues({...values, loading: true }); saveComment();} }  className="btn-success form-control btn-next" style={{marginTop: 10}}/>
                        
                    </div>
                </form>
             
             </Container  >
        )
    }
  
   
    
    const AddField = ()=>{
        return (
            <Container >
               
                <div style={{ color: 'red', fontSize: 20 }} >
                    
                </div>
                <div className="form-group">
                    <label className="form-label">Select Document</label>
                </div>
                <div className="form-group">
                    <div>
                        <select id="decument" name="decument" onBlur={handleBlur} onChange={handleAddField} type="option" className="form-select"> 
                        <option  selected hidden >select document</option>
                        <option value="0">ID Document</option> 
                        <option value="1">Proof of Income</option>
                        <option value="2">Testimonial Letter</option>  
                        <option value="3">Motivational Letter</option>
                        <option value="4">Acceptance Letter</option>
                        <option value="5">Proof of Registration</option>
                        <option value="6">Fees Statement</option>
                        <option value="7">Consent Form</option>
                        <option value="8">Mid-year Results</option>                  
                        <option value="9">Final-year Results</option>   
                        </select>
                        { touched.gender && errors.gender ? <div  className="alert alert-danger"> { errors.gender } </div>: null}
                    </div>                  
                </div>
                <div>  { selectedField.length > 0 && ( <div className="alert alert-secondary" role="alert"> <ul>   {selectedField.map((item, i) => { return  <span><p> {item} <button  id={item} name={item} onClick={removeField}/></p></span>;
                                             })}
                                           </ul>
                                              </div>  )}  
                              </div>
             </Container  >
        )
    }


    const handleAddField =(event)=>{
        console.log(upload[parseInt(event.target.value)])
        if (!selectedField.includes(upload[parseInt(event.target.value)])) {   
            selecField((prevMessage) => ([
                ...prevMessage, upload[parseInt(event.target.value)]
                
            ]));
            const newarr = [...field];
            newarr.push(event.target.value);
            setValues({...values, field: newarr});
           
        }
        else{
            alert(`${upload[parseInt(event.target.value)]} is already selected`)
        }

    }

    const removeField = (event) =>{
        event.preventDefault()
        selecField(selectedField.filter(item => item !== event.target.name));
        const newarr = [...field];
        newarr.pop(event.target.value);
        setValues({...values, field: newarr});
    }
    const removeEmail = (event) =>{
        const newarr = [...selectedEmails];
        newarr.pop(event.target.name);
        setValues({...values, selectedEmails: newarr})
    }
    const EmailForm = ()=>{
        return (
            <Container>
                    <div className="form-group">
                        <label className="form-label">to</label>
                         <input className="form-control" id="to" name="to"  onBlur={handleBlur} onChange={handleChange} type="text" value={applicant['Email']} disabled  /> 
                        { touched.cc && errors.cc ? <div  className="alert alert-danger"> { errors.cc} </div> : null}  
                    </div>                 
                    <div className="form-group">
                        <label className="form-label">cc</label>                                            
                         <input className="form-control" id="cc" name="cc"  onBlur={handleBlur} onChange={handleChange} type="text" value={cc}   /> 
                        { touched.cc && errors.cc ? <div  className="alert alert-danger"> { errors.cc} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">subject</label>                                            
                         <input className="form-control" id="subject" name="subject"  onBlur={handleBlur} onChange={handleChange} type="text" value={subject}  /> 
                        { touched.subject && errors.subject ? <div  className="alert alert-danger"> { errors.subject} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">body</label>
                        <textarea id="body" name="body" rows="15" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={body}/>
                        {touched.body && errors.body ? <div  className="alert alert-danger"> { errors.body } </div> : null}                 
                    </div>
                    <div> 
                        <input id="mail" type="button" value="Send" onClick={() => sendEmail() } style={{marginleft: 10}} className="btn-success form-control btn-next"/>                            
                    </div>
            </Container>
        )
    } 
    console.log(typeof(applicant.review))
    const showNotes = () =>{
        return ( <div> 
            {applicant.review != undefined && applicant.review.map(item => (         
                    <div> { (item['Comment Date']).substring(0,10)} { item['Comment']}</div>   
                ))} 
    </div> )
    }
     
    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>sending...</h2>
            </div>
        );

    const DisplayForm = () =>{
        switch(display){
            case 'comment':
                return Comment()
            case 'emailform':
                return EmailForm()
        }
    }
    const sendEmail = ()=> {
        window.scrollTo(0, 0);
        setValues({ ...values, error: false, loading: true });
        let ready = true
        let cc = []
        let to = [applicant['Email']]             
        let text = document.getElementById("cc").value
        if (text !== '') {
            cc = text.replace(/\s/g,"").split(';')
        }       

        cc.map(email =>{           
            if (!re.test(String(email).toLowerCase())) {
                ready = false
                setValues({ ...values, error: false, loading: false });
                alert(`CC ${email} not valid email`)
            }
        })
        var answer = window.confirm("Do you want to send an email for Documents request?");
        if (answer) {
            ready = true
        }
        else {
            setValues({ ...values, error: false, loading: false });
            ready = false
        }
        if (ready) {           
            sendDocumentsRequest( {to,cc,subject,field, applicant_id: applicant['applicant_id'], body} ).then(data => {
                        if (data.error) {
                            setValues({ ...values, error: false, loading: false });
                            alert(data.error)
                        } else {
                        setValues({ ...values, error: false, loading: false });
                        alert(`Documents request sent`)
                        }
            })
        }
         
        }
    return(
        <div>
             <button onClick={ ()=> window.location.reload() }>Back to applicant page</button>
            <Container maxWidth="sm"> {showLoading()} </Container>
            <Container maxWidth="lg">
            
            <h2> { applicant['First Name']} { applicant['Last Name']} </h2>
            {showNotes()}
            {Comment()}
            
            <form>
                {AddField()}
                {EmailForm()}                 
            </form>
            </Container>
        </div>
    )
    
}

export default Review;