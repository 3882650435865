import React, { useState, useRef, useEffect } from "react";
import { browserHistory } from 'react-router'
import { Link, withRouter, useHistory } from "react-router-dom";
import { useForm, useStep } from "react-hooks-helper";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import jwt_decode from 'jwt-decode';
import consent from '../docs/consent_form.pdf';

import { Form, useFormik } from "formik";


import { Button } from "react-bootstrap";
import { Input } from "reactstrap";
import * as Yup from "yup";


import Container from "@mui/material/Container";
import * as dump  from '../dump'
import Layout from '../core/Layout';
import {addResults,uploadResults,uploadFiles} from '../util'
const UploadRequestedDcocuments = ({match:{params:{id}}}) => {
    var data = {} //jwt_decode(token) 

    const history = useHistory();
    const FILE_SIZE = 5097152;
 
    const  validationSchema = Yup.object({                     
        id_document: Yup.mixed().test("fileSize",`ID document file must be at least 2 MB. \n Try to compress the file and upload it again`, 
           value => value && value.size <= FILE_SIZE )
       .test(
       "fileFormat",
       "ID Document file format unsupported. Upload PDF or IMAGE file",
           value => value && SUPPORTED_FORMATS.includes(value.type)),

     acceptance_letter: Yup.mixed().test("fileSize",`Acceptance letter must be at least 2 MB. \n Try to compress the file and upload it again`, 
               value => value && value.size <= FILE_SIZE )
           .test(
           "fileFormat",
           "Acceptence Letter file format unsupported. Upload PDF or IMAGE file",
           value => value && SUPPORTED_FORMATS.includes(value.type)),
     affidavit:Yup.mixed().test("fileSize",`Proof of income document file size too large. \n Try to compress the file and upload it again`,     
               value => value && value.size <= FILE_SIZE )
           .test(
           "fileFormat",
           "Proof of income file must be at least 2 MB. Upload PDF or IMAGE file",
           value => value && SUPPORTED_FORMATS.includes(value.type)),
     recommendation: Yup.mixed().test("fileSize",`  Testimonial letter file size too large. \n Try to compress the file and upload it again`, 
               value => value && value.size <= FILE_SIZE )
           .test(
           "fileFormat",
           " Testimonial letter file format unsupported. Upload PDF or IMAGE file",
           value => value && SUPPORTED_FORMATS.includes(value.type)),
     motivation_letter: Yup.mixed().test("fileSize",`  Motivational letter file must be at least 2 MB. \n Try to compress the file and upload it again`,
               value => value && value.size <= FILE_SIZE )
           .test(
           "fileFormat",
           "Motivational Letter file format unsupported. Upload PDF or IMAGE file",
           value => value && SUPPORTED_FORMATS.includes(value.type)), 
     result: Yup.mixed().test("fileSize",`  Result file must be at least 2 MB. \n Try to compress the file and upload it again`,
           value => value && value.size <= FILE_SIZE )
       .test(
       "fileFormat",
       "result file format unsupported. Upload PDF or IMAGE file",
       value => value && SUPPORTED_FORMATS.includes(value.type)), 
     registration: Yup.mixed().test("fileSize",`Proof of registration file must be at least 2 MB. \n Try to compress the file and upload it again`,
       value => value && value.size <= FILE_SIZE )
         .test(
         "fileFormat",
         "Proof of registration file format unsupported. Upload PDF or IMAGE file",
         value => value && SUPPORTED_FORMATS.includes(value.type)),
     fees_statement: Yup.mixed().test("fileSize",`Fees statement file must be at least 2 MB. \n Try to compress the file and upload it again`,
         value => value && value.size <= FILE_SIZE )
           .test(
           "fileFormat",
           "Fees statement file format unsupported. Upload PDF or IMAGE file",
           value => value && SUPPORTED_FORMATS.includes(value.type)),  
     consent_form: Yup.mixed().test("fileSize",`Consent form file must be at least 2 MB. \n Try to compress the file and upload it again`,
     value => value && value.size <= FILE_SIZE )
       .test(
       "fileFormat",
       "Consent form file format unsupported. Upload PDF or IMAGE file",
       value => value && SUPPORTED_FORMATS.includes(value.type)),
       subjectMark: Yup.number('Enter digits only'),
       reason: Yup.string().required('Enter reason/s for no results'),                  
    midyear_transcript: Yup.mixed().test("fileSize",`Transcript file must be at least 2 MB. \n Try to compress the file and upload it again`, 
          value => value && value.size <= FILE_SIZE )
      .test(
      "fileFormat","Transcript/Progress report file format unsupported. Upload PDF or IMAGE file",
          value => value && SUPPORTED_FORMATS.includes(value.type)),
    finalyear_transcript: Yup.mixed().test("fileSize",`Transcript file must be at least 2 MB. \n Try to compress the file and upload it again`, 
       value => value && value.size <= FILE_SIZE )
      .test(
      "fileFormat",
      "Transcript/Progress report file format unsupported. Upload PDF or IMAGE file",
          value => value && SUPPORTED_FORMATS.includes(value.type)),
           
     })
    const SUPPORTED_FORMATS = [
      "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png"
    ];

    let fields = ['5','6','7','9']

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
        setFieldValue,
        values,
        touched,
        errors,
    } = useFormik({
        initialValues: {
          application_id: id,
          field: fields,
          pledge_by_applicant:'',
          id_document: '',
          acceptance_letter:'',
          affidavit:'',
          recommendation:'',
          motivation_letter:'',
          results: [],
          registration: '',
          fees_statement:'',
          midyear_transcript:'',
          finalyear_transcript:'',
          consent_form:'',
          no_result:'',
          reason:'',
          subjectName:'',
          subjectMark:'',
          loading: false,
          error: '',
          success:''
         },
        validationSchema,
        validateOnChange: true,
    });

    const {
        application_id,
        field,
        pledge_by_applicant,
        id_document,
        acceptance_letter,
        affidavit,
        recommendation,
        motivation_letter,
        results,
        registration,
        fees_statement,
        midyear_transcript,
        finalyear_transcript,
        consent_form,
        no_result,
        reason,
        subjectName,
        subjectMark,
        loading,
        error,
        success
    } = values;

    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const progressInfosRef = useRef(null)
    const [message, setMessage] = useState([]); 
    const [open, setOpen] = useState(false);
    const [startDate,setStartDate] = useState()
    const [endDate,setEndDate] = useState()
    var resultFileToUpload = {}
    var resulFileError = {}
    var subjectError = { error: errors.subjectMark}
    resultFileToUpload = field.includes("8") ? {name: "midyear_transcript", file: midyear_transcript} : {}
    resultFileToUpload = field.includes("9") ? {name: "finalyear_transcript", file: finalyear_transcript}: {}

    resulFileError = field.includes("8") ? { file: 'midyear_transcript', error: errors.midyear_transcript } : {}
    resulFileError = field.includes("9") ? {file: 'finalyear_transcript', error: errors.finalyear_transcript} : {}
    const upload = [ id_document , affidavit, recommendation, motivation_letter, acceptance_letter,   
        registration, fees_statement, consent_form,midyear_transcript,finalyear_transcript ]
   

  const upload1 = [ 'id_document' , 'affidavit', 'recommendation', 'motivation_letter', 'acceptance_letter',   
        'registration', 'fees_statement','consent_form','midyear_transcript','finalyear_transcript']
  var files = {}
  var fil2 = {}
  var err1 = {}

  const downloadConsentForm = ()=>{
        const link = document.createElement('a');
        link.href = `docs/consent_form.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  }


    const handleAddSubject = (event) => {
        let newArr = [...results];  
        newArr.push({'subjectName': subjectName,  'subjectMark': subjectMark })  
        setValues({...values, results: newArr});  

    }
    const _addResults = (results) => {          
        addResults(results).then((data) => {
            if (data.error) {
                alert(data.error);
                //setValues({ ...values, error: data.error, success: false });
            } else {
                setValues({ ...values, loading: false });
                setValues({ ...values, success: true });               
            }
        });
    };

    const  noResultError = () => {
        if (subjectError.error)
                return subjectError.error
      
        return false;
    }
    

    const  noError = (arr) => {
        for (var item in arr) {
            if (arr[item].error){
                alert(` ${arr[item].error}`)
                return false;
            }
        }
        return true;
    }
    
    const  notEmpty = (arr) => { 
        for (var item in arr) {

            if (arr[item].file == "" && arr[item].name != "acceptance_letter"){
                console.log(item)
                alert(`Select File for ${arr[item].name}`)
                return false;
            }
        }
        return true;
    }
    const submitResults = () => {  
        if (notEmpty([resultFileToUpload]) && noError([resulFileError])) {          
            let resultsData = {application_id,results,resultFileToUpload}        
            let _progressInfos = [{ percentage: 0, key: resultFileToUpload['name'], fileName: resultFileToUpload['file'].name }]
            progressInfosRef.current = {
            val: _progressInfos,
            }
            sendResults(resultsData);
        }       
    }

    const sendResults = (resultsData) => { 
        let _progressInfos = [...progressInfosRef.current.val]
        return uploadResults(resultsData, (event) => {
            _progressInfos[0].percentage = Math.round(
               (100 * event.loaded) / event.total );
               setProgressInfos({ val: _progressInfos })
            }).then((res) => {
             setMessage((prevMessage) => ([
                ...prevMessage,
                resultsData.resultFileToUpload['file'].name + "  uploaded  successfully. " 
            ]));
            setValues({ ...values, loading: false }); 
          }).catch(() => {
              _progressInfos[0].percentage = 0;
              setProgressInfos({ val: _progressInfos });    
              setMessage((prevMessage) => ([
              ...prevMessage,
              resultsData.fileToUpload['fileObject'].name  + " failed to upload"
            ]));
          });
    }
    const handleReasonChange = (e)=>{
        let newArr = [];  
        newArr.push({'subjectName': 'no results',  'subjectMark': e.target.value })  
        setValues({...values, results: newArr});  
    }
    const submitFiles = () => {  
        let arr = []
        let validator =[]
        let filles = []
        if (field != undefined){
                   
            files = field.map(item =>( { 
            ...fil2,  
            file: upload[item] , name: upload1[item]
            }) )
            validator = field.map(item =>( { 
                ...err1,  
                file: upload1[item] , error: errors[upload1[item]]
            }) )

        }
        
        if (notEmpty(files) && noError(validator)) { 
            let _progressInfos = files.map(obj => ({ percentage: 0, key: obj['name'], fileName: obj['file'].name }));
            progressInfosRef.current = {
            val: _progressInfos,
            }
            var i = 0
            files.map(obj => {
                sendFile(i,obj['name'], obj['file']);
                i = i + 1;
            })
        }
   
    }
  
    const sendFile = (key,fileName, file) => { 
        let _progressInfos = [...progressInfosRef.current.val];
        
        return uploadFiles(application_id, file,fileName,results, (event) => {
          _progressInfos[key].percentage = Math.round(
            (100 * event.loaded) / event.total
          );
          setProgressInfos({ val: _progressInfos });
        })
          .then((res) => {
            setMessage((prevMessage) => ([
              ...prevMessage,
              file.name + "  uploaded  successfully. " 
            ]));
            setValues({ ...values, loading: false }); 
          })
          .catch(() => {
            _progressInfos[key].percentage = 0;
            setProgressInfos({ val: _progressInfos });    
            setMessage((prevMessage) => ([
              ...prevMessage,
              file.name + " failed to upload"
            ]));
          });
      }

    const showError = () => (
        <div className="alert alert-danger"   style={{ display: error ? "" : "none", width: 600 }} >
              
        </div>
    );
    const ProgressBar = ({progress }) => {  
        return  <div className="progress">
              <div className="progress-bar" role="progressbar" style={{ width: `${progress.percentage}%`}} > {progress.percentage}%</div>
         </div>
    }
    const AddSubject = () => {
        var start, end
        const handleClickOpen = () => {
            setOpen(true);
        };
        
        const handleClose = () => {
            setOpen(false);
        }
        return (
          <div>
          <button  onClick={handleClickOpen}>
              Add subject
          </button>
          <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
            {"SANZAF bursary"}
            </DialogTitle>
            <DialogContent
                        style={{height:'300px'}}>
            <DialogContentText id="alert-dialog-description">
              <p>  <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label">Subject name</label>
                        <input 
                            className="form-control"
                            name="subjectName"
                            id="subjectName"
                            onChange={handleChange}
                            type="text"
                        />     
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label">Mark awarded</label>
                        <input 
                            className="form-control"
                            name="subjectMark"
                            id="subjectMark"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                        />  
                  {touched.subjectMark && errors.subjectMark ? ( <div style={{width: 400}} className="alert alert-danger">  {" "} {errors.subjectMark}{" "}</div>) : null}    
                    </div> </p>
              </DialogContentText>
              
              
              <Button onClick={()=> { if (subjectMark && subjectName){
                                            if (!noResultError(subjectError)){ handleAddSubject(); handleClose()} else {alert('Enter number for Mark awarded')};

                                       } else {alert('Enter Subject and Subject Mark')}  }} autoFocus>
              Ok
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogContent>
          </Dialog>
          </div>
        );

	  }    
    
      return (
        <Layout>
              <Container maxWidth="sm" style={{marginTop: 90}}>
     
            <div className="form-group" >
                                     { message.length > 0 && <div   className="alert alert-info">
                                        {message.length} file/s submitted successfully. 
                                        Thank you
                                       </div>  }

                                       <div>  {message.length > 0 && ( <div className="alert alert-secondary" role="alert"> <ul>   {message.map((item, i) => { return <li key={i}>{item}</li>;
                                  })}
                                </ul>
                                  </div>  )}  
                                 </div>        
                                    { field.includes("0") && <div className="form-group">
                                    <label className="form-label" >ID Document</label> <label className="form-label required" >*</label> 
                                    <div   >                           
                                        <Input  id="id_document" name="id_document" placeholder={id_document.fileName} className="form-control" onBlur={handleBlur}  onChange={(e) =>{
                                                                        setValues({...values, id_document: e.target.files[0]});
                                                                        progressInfos.val.map((progress, index) => { if (progress['key'] === 'id_document') { progress.percentage = 0} });
                                                                        }} type="file" /> 
                                                                        {id_document.fileName}
                                        {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'id_document' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                        { touched.id_document && errors.id_document ? <div  className="alert alert-danger">  { errors.id_document} </div> : null}
                                      
                                    </div>
                                </div> }
                                { field.includes("1") && <div className="form-group">
                                    <label  className="form-label" >Proof of Income</label> <label className="form-label required" >*</label> 
                                    <div   >                           
                                        <Input id="affidavit" name="affidavit" placeholder={affidavit.fileName}  className="form-control" onBlur={handleBlur} onChange={(e) =>{
                                                                        setValues({...values, affidavit: e.target.files[0]});
                                                                        progressInfos.val.map((progress) => { if (progress['key'] === 'affidavit') { progress.percentage = 0} });
                                                                        }} type="file" /> 
                                        {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'affidavit' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                        { touched.affidavit && errors.affidavit ? <div  className="alert alert-danger">  { errors.affidavit} </div> : null}
                                    </div>
                                </div> }
                                { field.includes("2") && <div className="form-group">
                                    <label className="form-label"  >Testimonial Letter</label> <label className="form-label required" >*</label> 
                                    <div   >                           
                                        <Input id="recommendation" name="recommendation" placeholder={recommendation.fileName}  className="form-control" onBlur={handleBlur} onChange={(e) =>{
                                                                        setValues({...values, recommendation: e.target.files[0]});
                                                                        progressInfos.val.map((progress) => { if (progress['key'] === 'recommendation') { progress.percentage = 0} });
                                                                        }} type="file" /> 
                                        {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'recommendation' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                        { touched.recommendation && errors.recommendation ? <div  className="alert alert-danger">  { errors.recommendation} </div> : null}
                                    </div>
                                </div> }
                                { field.includes("3") && <div className="form-group">
                                    <label className="form-label"  >Motivational Letter</label> <label className="form-label required" >*</label> 
                                    <div   >                           
                                        <Input id="motivation_letter" name="motivation_letter" placeholder={motivation_letter.fileName} className="form-control" onBlur={handleBlur} onChange={(e) =>{
                                                                        setValues({...values, motivation_letter: e.target.files[0]});
                                                                        progressInfos.val.map((progress) => { if (progress['key'] === 'motivation_letter') { progress.percentage = 0} });
                                                                        }} type="file" /> 
                                        {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'motivation_letter' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                        { touched.motivation_letter && errors.motivation_letter ? <div  className="alert alert-danger">  { errors.motivation_letter} </div> : null}
                                    </div>
                                </div> } 

                                 { field.includes("4") && <div className="form-group">
                                    <label className="form-label"  >Acceptance Letter (if you are first time at university) </label> <label className="form-label required" >*</label> 
                                    <div   >                           
                                        <Input id="acceptance_letter" name="acceptance_letter" placeholder={acceptance_letter.fileName} className="form-control" onBlur={handleBlur} onChange={(e) =>{
                                                                        setValues({...values, acceptance_letter: e.target.files[0]});
                                                                        progressInfos.val.map((progress) => { if (progress['key'] === 'acceptance_letter') { progress.percentage = 0} });
                                                                        }} type="file" /> 
                                        {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'acceptance_letter' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                        { touched.acceptance_letter && errors.acceptance_letter ? <div  className="alert alert-danger">  { errors.acceptance_letter} </div> : null}
                                    </div>
                                </div> }    
                               
                              { field.includes("5") && <div>                           
                                <label className="form-label"> Proof of registration 2024 </label><label className="form-label required" >*</label> 
                                <Input id="registration" name="registration"  className="form-control" onBlur={handleBlur} onChange={(e) =>{
                                                                 setValues({...values, registration: e.target.files[0]});
                                                                 progressInfos.val.map((progress) => { if (progress['key'] === 'registration') { progress.percentage = 0} });
                                                                 }} type="file" /> 
                                {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'registration' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                { touched.registration && errors.registration ? <div  className="alert alert-danger">  { errors.registration} </div> : null}
                             </div>}
                             { field.includes("6") &&<div>                           
                                <label className="form-label">Official Statement of fees 2024 with banking details </label><label className="form-label required" >*</label> 
                                <Input id="fees_statement" name="fees_statement"  className="form-control" onBlur={handleBlur} onChange={(e) =>{
                                                                 setValues({...values, fees_statement: e.target.files[0]});
                                                                 progressInfos.val.map((progress) => { if (progress['key'] === 'fees_statement') { progress.percentage = 0} });
                                                                 }} type="file" /> 
                                {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'fees_statement' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                { touched.fees_statement && errors.fees_statement ? <div  className="alert alert-danger">  { errors.fees_statement} </div> : null}
                             </div>}
                             { field.includes("7") &&<div>                           
                                <label className="form-label">Consent Form </label><label className="form-label required" >*</label> 
                                <div  className="alert alert-info"> Click  <a href={consent} download="consent_form.pdf"> here </a> to download consent form.  Complete, sign and upload it. </div>
                                <Input id="consent_form" name="consent_form"  className="form-control" onBlur={handleBlur} onChange={(e) =>{
                                                                 setValues({...values, consent_form: e.target.files[0]});
                                                                 progressInfos.val.map((progress) => { if (progress['key'] === 'consent_form') { progress.percentage = 0} });
                                                                 }} type="file" /> 
                                 
                                {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'consent_form' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                                { touched.consent_form && errors.consent_form ? <div  className="alert alert-danger">  { errors.consent_form} </div> : null}
                             </div>}
        { field.includes("8") &&<div> 
         <label className="form-label" >Mid Year Transcript/Progress report</label>
         <div className="row" >
            <div class="form-single-column" role="group" aria-labelledby="label_38" data-component="checkbox">
                    <span class="form-checkbox-item" style={{}}>
                        <Checkbox key="no_result" id="no_result" name="no_result" onBlur={handleBlur} onChange={handleChange} value='result' /> 
                        <label id="label_no_result"> No results available</label>
                    </span>
            </div>
        </div>
        {results.map((value,index) => (
                <div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label"> Subject {value['subjectName']} </label>      
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label"> Mark {value['subjectMark']} </label>       
                    </div>
                </div>               
             ))
        }     
        {no_result == 'result' && <div className="form-group">
                        <label className="form-label"> Specify the reason/s </label>  <label className="form-label required">*</label>
                        <div>   
                            <textarea id="reason" name="reason"  onBlur={handleBlur} onChange={handleReasonChange}  className="form-control"/>     
                        </div>
                    </div> }
         
               <div className="form-group">
                    <div>
                        { no_result != 'result' && <div>{AddSubject()}</div>}
                    </div>                  
                </div>
                 
                <div   >                           
                    <Input  id="midyear_transcript" name="midyear_transcript" placeholder={midyear_transcript.fileName} className="form-control" onBlur={handleBlur}  onChange={(e) =>{
                                                    setValues({...values, midyear_transcript: e.target.files[0]});
                                                    progressInfos.val.map((progress, index) => { if (progress['key'] === 'midyear_transcript') { progress.percentage = 0} });
                                                    }} type="file" /> 
                                                    {midyear_transcript.fileName}
                    {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'midyear_transcript' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                    { touched.midyear_transcript && errors.midyear_transcript ? <div  className="alert alert-danger">  { errors.midyear_transcript} </div> : null}
                    
                </div>
            </div> }
            { field.includes("9") &&<div> 
            <label  className="form-label" >Matric Results 2023 or University Academic results 2023 </label>
         <div className="row" >
            <div class="form-single-column" role="group" aria-labelledby="label_38" data-component="checkbox">
                    <span class="form-checkbox-item" style={{}}>
                        <Checkbox key="no_result" id="no_result" name="no_result" onBlur={handleBlur} onChange={handleChange} value='result' /> 
                        <label id="label_no_result" > No results available</label>
                    </span>
            </div>
        </div>
        {results.map((value,index) => (
                <div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label"> Subject {value['subjectName']} </label>      
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label"> Mark {value['subjectMark']} </label>       
                    </div>
                </div>               
             ))
        }     
        {no_result == 'result' && <div className="form-group">
                        <label className="form-label"> Specify the reason/s </label>  <label className="form-label required">*</label>
                        <div>   
                            <textarea id="reason" name="reason"  onBlur={handleBlur} onChange={handleReasonChange}  className="form-control"/>     
                        </div>
                    </div> }
         
               <div className="form-group">
                    <div>
                        { no_result != 'result' && <div>{AddSubject()}</div>}
                    </div>                  
                </div>
                <div   >                           
                <Input id="finalyear_transcript" name="finalyear_transcript" placeholder={finalyear_transcript.fileName}  className="form-control" onBlur={handleBlur} onChange={(e) =>{
                                                setValues({...values, finalyear_transcript: e.target.files[0]});
                                                progressInfos.val.map((progress) => { if (progress['key'] === 'finalyear_transcript') { progress.percentage = 0} });
                                                }} type="file" /> 
                {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'finalyear_transcript' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                { touched.finalyear_transcript && errors.finalyear_transcript ? <div  className="alert alert-danger">  { errors.finalyear_transcript} </div> : null}
            </div>
            </div> }    
            <div style={{ marginTop: 10}}>
                           <input id="up1" type="button" value="Upload" className="btn-success form-control" fullWidth 
                           onClick={() =>{
                                setMessage([]); 
                                submitFiles(); 
                            }}  />
            </div>   
                                                    
    
                  </div> 
    </Container>
    </Layout>
     
    )
};
export default UploadRequestedDcocuments;